import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { POST } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";

function* SignInSaga({ payload }) {
  const { authData, history } = payload;

  try {
    const signin_response = yield POST("admin/login", authData);
    if (authData.email === "demo@gmail.com") {
      window.sessionStorage.setItem(
        "token",
        signin_response?.data?.result.authToken
      );
      window.sessionStorage.setItem("showSidebar", "true");
      window.location.href = "/all-quest";
      return;
    }

    let responsedata = { ...signin_response?.data };

    if (signin_response?.status === 200) {
      yield put({
        type: types.SignInRequestSuccess,
        payload: responsedata,
      });

      CustomToster({ type: "success", message: responsedata?.result?.message });
      history("/otp");
    } else {
      yield put({ type: types.SignInRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.SignInRequestFailure });
  }
}

// Its for sending email to user that can click and reset password
function* EmailForOTPCreateNewPasswordSaga({ payload }) {
  const { createNewpassEmailSend, history } = payload;

  try {
    const email_for_otp_create_new_password_response = yield POST(
      "admin/forget-password",
      createNewpassEmailSend
    );

    let responsedata = {
      ...email_for_otp_create_new_password_response?.data,
      token: payload,
    };

    if (email_for_otp_create_new_password_response?.status === 200) {
      yield put({
        type: types.EmailForOtpCreateNewPasswordRequestSuccess,
        payload: createNewpassEmailSend.email,
      });

      CustomToster({
        type: "success",
        message: "OTP sent to the email.Please use that to reset the password.",
      });
      history("/create-new-password");
    } else {
      yield put({ type: types.EmailForOtpCreateNewPasswordRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.EmailForOtpCreateNewPasswordRequestFailure });
  }
}
// After clicking link screen open and change password and new password would be send by this
function* CreateNewPasswordSaga({ payload }) {
  const { forgetUserPasowrdData, history } = payload;
  try {
    const email_for_otp_create_new_password_response = yield POST(
      "admin/restore-password",
      forgetUserPasowrdData
    );

    let responsedata = {
      ...email_for_otp_create_new_password_response?.responsedata,
      token: payload,
    };

    if (email_for_otp_create_new_password_response?.status === 200) {
      yield put({
        type: types.EmailForOtpCreateNewPasswordRequestSuccess,
        payload: responsedata,
      });
      CustomToster({ type: "success", message: responsedata?.result?.message });
      history("/");
    } else {
      yield put({ type: types.EmailForOtpCreateNewPasswordRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.EmailForOtpCreateNewPasswordRequestFailure });
  }
}

export default function* AuthSaga() {
  yield takeLatest(types.SignInRequest, SignInSaga);

  yield takeLatest(
    types.EmailForOtpCreateNewPasswordRequest,
    EmailForOTPCreateNewPasswordSaga
  );
  yield takeLatest(types.CreateNewPasswordRequest, CreateNewPasswordSaga);
}
