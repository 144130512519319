import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomTextInput from "../../../SharedComponents/CustomTextInput";
import { Field, Formik } from "formik";
import { types } from "../../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../../Constants";
import CryptoLogo from "../../../../Assets/Logo/logo.png";

const Login = () => {
  const passwordRef = useRef(null);
  const emailRef = useRef(null);
  const dispatch = useDispatch();
  const { SignInloading } = useSelector((state) => ({
    SignInloading: state.AuthReducer.SignInloading,
  }));
  const history = useNavigate();
  useEffect(() => {
    // window.sessionStorage.clear();
    console.log("here in login");
    emailRef.current.focus();
  }, []);

  const setCookie = (name, value, days) => {
    const expires = new Date(
      Date.now() + days * 24 * 60 * 60 * 1000
    ).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  };

  return (
    <div className="d-flex justify-content-center align-items-center h100vh">
      <div className="text-center">
        <div className=" ">
          <img src={CryptoLogo} />
          {/* <p className="login-heading ">Thank you for the information you provided about the connection</p> */}
        </div>
        <div className="d-flex justify-content-center">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values) => {
              let data = {
                authData: {
                  email: values.email,
                  password: values.password,
                },
                history: history,
              };
              dispatch({
                type: types.SignInRequest,
                payload: data,
              });

              setCookie("userEmail", values.email, 30);
            }}
            validationSchema={Constants.LoginSchema}
          >
            {({ handleChange, handleBlur, handleSubmit, values }) => (
              <div>
                <div className="row d-flex justify-content-center mt20">
                  <div className="col-8 col-sm-8 col-md-8 mb-3 ">
                    <Field name="email">
                      {({ field, meta }) => (
                        <CustomTextInput
                          label={"Email"}
                          type="text"
                          error={meta.touched ? meta.error : null}
                          onChange={handleChange("email")}
                          placeHolderText="johndoe@gmail.com"
                          value={values.email}
                          onBlur={handleBlur("email")}
                          showEyeIcon={false}
                          innerRef={(el) => {
                            field.ref = el;
                            emailRef.current = el;
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent form submission
                              // Move focus to the password field
                              passwordRef.current.focus();
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="row d-flex justify-content-center mt10">
                  <div className="col-8 col-sm-8 col-md-8">
                    <Field name="password">
                      {({ field, meta }) => (
                        <CustomTextInput
                          label={"Password"}
                          type="password"
                          error={meta.touched ? meta.error : null}
                          onChange={handleChange("password")}
                          placeHolderText="********************"
                          value={values.password}
                          onBlur={handleBlur("password")}
                          showEyeIcon={true}
                          innerRef={(el) => {
                            field.ref = el;
                            passwordRef.current = el;
                          }} // Add ref attribute
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              console.log("enter pressed");
                              e.preventDefault(); // Prevent form submission
                              // Trigger form submission
                              handleSubmit();
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div>
                    <NavLink
                      className="text-decoration-none"
                      to="/forget-password"
                    >
                      <span className="sub-text fs16 mb-0">
                        Forgot your password?
                      </span>
                    </NavLink>
                  </div>
                  <div className="col-8 col-sm-8 col-md-8 mt16 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="parimarybtn"
                      onClick={() => handleSubmit()}
                    >
                      {SignInloading ? (
                        <div className="whitespinner">
                          <div className="bounce1"></div>
                          <div className="bounce2"></div>
                          <div className="bounce3"></div>
                        </div>
                      ) : (
                        <>
                          Login <ForwardArrow />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;

const ForwardArrow = () => (
  <svg
    width="20"
    height="11"
    viewBox="0 0 20 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2688 3.62314L16.0434 0.364446C15.966 0.28633 15.8738 0.224328 15.7722 0.182016C15.6707 0.139704 15.5617 0.11792 15.4517 0.11792C15.3417 0.11792 15.2327 0.139704 15.1312 0.182016C15.0296 0.224328 14.9374 0.28633 14.86 0.364446C14.7047 0.520598 14.6176 0.731831 14.6176 0.95201C14.6176 1.17219 14.7047 1.38342 14.86 1.53957L17.827 4.53157H0.833425C0.612387 4.53157 0.400402 4.61938 0.244105 4.77568C0.087807 4.93197 0 5.14396 0 5.365H0C0 5.58603 0.087807 5.79802 0.244105 5.95432C0.400402 6.11061 0.612387 6.19842 0.833425 6.19842H17.877L14.86 9.20709C14.7819 9.28456 14.7198 9.37674 14.6775 9.4783C14.6352 9.57986 14.6134 9.68879 14.6134 9.79882C14.6134 9.90884 14.6352 10.0178 14.6775 10.1193C14.7198 10.2209 14.7819 10.3131 14.86 10.3905C14.9374 10.4687 15.0296 10.5307 15.1312 10.573C15.2327 10.6153 15.3417 10.6371 15.4517 10.6371C15.5617 10.6371 15.6707 10.6153 15.7722 10.573C15.8738 10.5307 15.966 10.4687 16.0434 10.3905L19.2688 7.15686C19.737 6.68806 20 6.05257 20 5.39C20 4.72742 19.737 4.09194 19.2688 3.62314Z"
      fill="white"
    />
  </svg>
);
