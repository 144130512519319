import React, { useState, useEffect } from "react";
import Select from "react-select";
import { POST } from "../../Utils/httpServices";
import { GoogleApiWrapper } from "google-maps-react-18-support";

const MAP_API_KEY = "AIzaSyAQPJobaSibKj0ukXQag1aRYn9Kpck9MKw";

const GoogleSelect = ({
  callback,
  resetClearSignal,
  clearSignal,
  specialy = false,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const debounceDelay = 400; // Фіксована затримка 400 мс

  const handleInputChange = (value) => {
    setInputValue(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      if (value.length >= 2) {
        fetchCountries(value);
      }
    }, debounceDelay);

    setDebounceTimeout(timeout);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  useEffect(() => {
    if (clearSignal) {
      setOptions([]);
      setInputValue("");
      setSelectedOption(null);
      resetClearSignal();
    }
  }, [clearSignal, resetClearSignal]);

  const fetchCountries = async (input) => {
    try {
      const response = await POST(`/admin/mapApi`, {
        queryString: `place/autocomplete/json?input=${input}&types=(regions)&key=${MAP_API_KEY}&language=en`,
      });
      const countries = response.data.predictions.map((prediction) => ({
        label: prediction.description,
        value: prediction.place_id,
      }));

      setOptions(countries);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchPlaceDetails = async (placeId) => {
    try {
      const response = await POST(`/admin/mapApi`, {
        queryString: `place/details/json?place_id=${placeId}&key=${MAP_API_KEY}&language=en`,
      });
      const location = response.data.result.geometry.location;
      return location;
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  const handlePlaceSelect = async (option) => {
    setSelectedOption(option);
    const location = await fetchPlaceDetails(option.value);
    callback(location.lat, location.lng);
  };

  return (
    <Select
      options={options}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      value={selectedOption}
      onChange={handlePlaceSelect}
      placeholder={`Search... ${
        specialy ? "( specially for Oleksiy 🖤 )" : ""
      }`}
    />
  );
};

export default GoogleApiWrapper({
  apiKey: MAP_API_KEY,
})(GoogleSelect);
