import { types } from "../action_types";

const INITIAL_STATE = {
  allBoostCollectionData: [],
  uploadBoastimageAllData: [],
  uploadBoastImageloading: false,
  allBoostCollectionDataloading: false,
  uploadNFTMetaDataAllData: [],
  uploadNFTMetaDataloading: false,
  searchText: null

};

const BoostReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    //Boost listing
    case types.BoostListingRequest:
      return { ...state, allBoostCollectionDataloading: true };
    case types.BoostListingRequestSuccess:
      return {
        ...state,
        allBoostCollectionData: payload.responsedata,
        allBoostCollectionDataloading: false,
        searchText: payload.actualSearchText || ''
      };

    case types.BoostListingRequestFailure:
      return { ...state, allBoostCollectionDataloading: false };
    // Boost Image
    case types.UploadCollectionImageRequest:
      return { ...state, uploadBoastImageloading: true };
    case types.UploadBoastImageRequestSuccess:
      return {
        ...state,
        uploadBoastimageAllData: payload,
        uploadBoastImageloading: false,
      };
    case types.UploadBoastImageReset:
      return {
        ...state,
        uploadBoastimageAllData: [],
        uploadBoastImageloading: false,
      };
    case types.UploadCollectionImageRequestFailure:
      return { ...state, uploadBoastImageloading: false };
    // Boost update
    case types.BoostUpdateRequest:
      return { ...state, allBoostCollectionDataloading: true };
    case types.BoostUpdateRequestSuccess:
      return {
        ...state,
        // allBoostCollectionData: payload,
        allBoostCollectionDataloading: false,
      };

    case types.BoostUpdateRequestFailure:
      return { ...state, allBoostCollectionDataloading: false };

    default:
      return state;
  }
};

export default BoostReducer;
