import { types } from '../action_types';

const INITIAL_STATE = {
    // otpdata:null,
    signinData:null,
    emailotpdata:null,
    SignInloading:false,
    resetPasswordloading:false,
    authUserEmail:'',
    authUserPassword:'',
    forgetPasswordUserEmail:'',
    authUserEmailForForgetPAssword:'',
    // Otploading:false,
    emailOtpForCreateNewPassordloading:false,
    createNewPassordloading:false
}

const AuthReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
          //auth

          
        case types.SignInRequest:
            return { ...state, SignInloading: true, authUserEmail: payload.authData.email,authUserPassword:payload.authData.password }
            case types.SignInRequestSuccess:
                return { ...state,signinData:payload, SignInloading: false }
                case types.SignInRequestFailure:
                    return { ...state, SignInloading: false }


// // Otp Request for Login
//                     case types.OtpRequest:
//                         return { ...state, Otploading: true }
//                         case types.OtpRequestSuccess:
//                             return { ...state,otpdata:payload, Otploading: false }
//                             case types.OtpRequestFailure:
//                                 return { ...state, Otploading: false }

                                // Otp Request For Create New Password Via Email
                    case types.EmailForOtpCreateNewPasswordRequest:
                        return { ...state, emailOtpForCreateNewPassordloading: true }
                        case types.EmailForOtpCreateNewPasswordRequestSuccess:
                            return { ...state,forgetPasswordUserEmail:payload, emailOtpForCreateNewPassordloading: false }
                            case types.EmailForOtpCreateNewPasswordRequestFailure:
                                return { ...state, emailOtpForCreateNewPassordloading: false }

                                // Otp Request For Create New Password(Screen)
                    case types.CreateNewPasswordRequest:
                        return { ...state, CreateNewPassordloading: true }
                        case types.CreateNewPasswordRequestSuccess:
                            return { ...state,emailotpdata:payload, CreateNewPassordloading: false }
                            case types.CreateNewPasswordRequestFailure:
                                return { ...state, CreateNewPassordloading: false }
      
        default:
            return state;
    }
}
export default AuthReducer;