import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Input, Row, Col } from "antd";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import { EditOutlined } from "@ant-design/icons";
import { BASEURL } from "../../../Constants";
import { GET, PUT } from "../../../Utils/httpServices";

const ExchangeLink = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState({
    allLinks: { docs: [{ description: "", link: "" }] },
  });
  const updateLink = editData?.result?.allLinks?.docs[0];

  const token = window.sessionStorage.getItem("token");

  useEffect(() => {
    fetchLinkData();
  }, []);

  const fetchLinkData = async () => {
    try {
      const response = await GET(`${BASEURL}admin/link/all`);
      setEditData(response?.data);
    } catch (error) {
      console.error("Error fetching link data:", error);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await PUT(`${BASEURL}admin/link/update/${updateLink?._id}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsModalVisible(false);
      fetchLinkData();
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEditClick = () => {
    showModal();
  };

  return (
    <div className="exchange-container">
      <Row gutter={16}>
        <Col span={12}>
          <Input
            value={updateLink?.description}
            disabled
            // addonAfter={<EditOutlined onClick={handleEditClick} />}
          />
        </Col>
        <Col span={12}>
          <Input
            value={updateLink?.link}
            disabled
            // addonAfter={<EditOutlined onClick={handleEditClick} />}
          />
        </Col>
      </Row>
      <Button
        style={{ background: "#78633a", marginLeft: "20px" }}
        type="primary"
        onClick={handleEditClick}
      >
        Edit
      </Button>
      <Modal
        title="Edit Link"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Formik
          initialValues={updateLink}
          onSubmit={handleSubmit}
          validate={(values) => {
            const errors = {};
            if (!values.description) {
              errors.description = "Please input description!";
            }
            if (!values.link) {
              errors.link = "Please input link!";
            }
            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <Form.Item
                label="Description"
                help={<ErrorMessage name="description" />}
              >
                <Field as={Input} name="description" />
              </Form.Item>

              <Form.Item label="Link" help={<ErrorMessage name="link" />}>
                <Field as={Input} name="link" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  style={{ background: "#78633a" }}
                >
                  Update
                </Button>
              </Form.Item>
            </FormikForm>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ExchangeLink;
