import { call, put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { FORMPOST, GET, POST } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";
import CollectionCreationAbi from "../../../Assets/collectioncreationabi.json";
import { ethers } from "ethers";
import { BASEURL } from "../../Constants";

const token = window.sessionStorage.getItem("token");

let setTranLoading = "";
function* UploadCollectionImageSaga({ payload, formData }) {
  try {
    const uploadCollectionImage_response = yield FORMPOST(
      "admin/upload/nft-image",
      formData
    );

    let uploadCollectionImageresponsedata = {
      ...uploadCollectionImage_response?.data,
    };

    if (uploadCollectionImage_response?.status === 200) {
      yield put({
        type: types.UploadCollectionImageRequestSuccess,
        payload: uploadCollectionImageresponsedata,
      });

      CustomToster({
        type: "success",
        message: "Your Image Uploaded Successfully",
      });
    } else {
      yield put({ type: types.UploadCollectionImageRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.UploadCollectionImageRequestFailure });
  }
}
let hexatoDigitNumber;
function* UploadNFTMetaDataSaga({ payload }) {
  let { setTransactionLoading } = payload;

  setTranLoading = setTransactionLoading;
  let nftMataData = {
    metadata: {
      name: payload?.values?.companyName,
      description: payload?.values?.description,
      image: payload?.collectionImageLink
    },
    fileName: payload?.values?.companyName + ".json",
  };

  try {
    const uploadNFTMetaData_response = yield POST(
      "admin/upload/nft-metadata",
      nftMataData
    );

    let uploadNFTMetaDataresponsedata = {
      ...uploadNFTMetaData_response?.data,
    };

    if (uploadNFTMetaData_response?.status === 200) {
      let data = {
        //  collectionId: payload?.values?.collectionId,
        ipfsHash: uploadNFTMetaDataresponsedata?.result?.ipfsHash,
        metadataLink: uploadNFTMetaDataresponsedata?.result?.metadataLink,
        // contractAddress:payload?.contractAddress,
        payload: payload,
      };

      const response = yield call(
        CollectionCreationNFTBloackchainApiCall,
        data
      );
    } else {
      setTransactionLoading(false);
      yield put({ type: types.UploadNFTMetaDataRequestFailure });
    }
  } catch (e) {
    setTransactionLoading(false);
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.UploadNFTMetaDataRequestFailure });
  }
}

let simpleId;
let addprize = false;
async function CollectionCreationNFTBloackchainApiCall(data) {
  // Meta Mask connectivity
  if (window?.ethereum) {
    window.ethereum.request({ method: "eth_requestAccounts" });

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    let promise = new Promise((resolve, reject) => {
      const cyrpto_nft = new ethers.Contract(
        data?.payload?.contractAddress,
        CollectionCreationAbi,
        signer
      );

      cyrpto_nft
        ?.addPrize(data.ipfsHash)
        .then((result) => {
          result
            ?.wait()
            .then((writingSmartContractResponse) => {
              addprize = true;
            })
            .catch((error) => {
              addprize = false;
              CustomToster({
                type: "error",
                message: "Transaction Failed",
              });
            });
        })
        .catch((err) => {
          addprize = false;
          setTimeout(() => {
            setTranLoading(false);
          }, 1000);
          CustomToster({
            type: "error",
            message: "Please login to metamask to proceed",
          });
        });

      let filter = cyrpto_nft.filters.PrizeAdded();

      // listen to the Added event using the filter
      cyrpto_nft.on(filter, (tokenId, _uri) => {
        // do something with the event parameters
        console.log("A new token was added with _uri:", _uri);

        console.log("A new token was added with ID:", tokenId);
        hexatoDigitNumber = tokenId;

        if (tokenId && addprize) {
          resolve("success");
        }
      });
    });
    promise.then((response) => {
      if (response) {
        console.log("simple:::", simpleId);
        console.log("hexatoDigitNumber:::", hexatoDigitNumber);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token} `);
        var raw = JSON.stringify({
          companyName: data?.payload?.values?.companyName,
          nftCollectionId: Number(hexatoDigitNumber),
          // nftCollectionId: data?.payload?.values?.collectionId,
          metadataLink: data?.metadataLink,
          imageLink: data?.payload?.collectionImageLink,
          status:
            data?.payload?.values?.status === true ? "activate" : "de-activate",
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(`${BASEURL}admin/nft-collection/create`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            CustomToster({
              type: "success",
              message: "Your Collection Created Successfully",
            });
            window.location.replace("/nft-collection-listing");
            setTimeout(() => {
              setTranLoading(false);
            }, 2000);
          })
          .catch((error) => {
            setTimeout(() => {
              setTranLoading(false);
            }, 1000);
            CustomToster({
              type: "error",
              message: "Error while Saving in Database",
            });
          });
      }
    });
  } else {
    setTimeout(() => {
      setTranLoading(false);
    }, 1000);
    CustomToster({
      type: "error",
      message: "PLease install metamask extension!!",
    });
    // alert("install metamask extension!!");
  }
}

function* GetAllNFTCollectionSaga(action) {

  const {payload} = action
  const { page, searchText } = payload;
  const actualSearchText = searchText || '';

  try {
    const all_nft_collection_response = yield GET(
      `nft-collection/all?page=${page}&limit=${
        page?.number ?? 10
      }&name=${actualSearchText}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responsedata = { ...all_nft_collection_response?.data };

    if (all_nft_collection_response?.status === 200) {
      yield put({
        type: types.GetAllNFTCollectionRequestSuccess,
        payload: {responsedata , actualSearchText},
      });

      //   CustomToster({ type: "success", message: responsedata?.result?.message });
    } else {
      yield put({ type: types.GetAllNFTCollectionRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.GetAllNFTCollectionRequestFailure });
  }
}

export default function* CollectionSaga() {
  yield takeLatest(
    types.UploadCollectionImageRequest,
    UploadCollectionImageSaga
  );
  yield takeLatest(types.UploadNFTMetaDataRequest, UploadNFTMetaDataSaga);
  yield takeLatest(types.GetAllNFTCollectionRequest, GetAllNFTCollectionSaga);
}
