import { types } from "../action_types";

const INITIAL_STATE = {
  // otpdata:null,
  allTaskloading: false,
  CreateTaskloading: false,
  allTaskData: null,
  allTaskWithCountryandCityData: [],
  createTaskData: null,
  editTaskModalOpen: false,
  updateTaskloading: false,
  addTaskModalOpen: false,
  searchTextTask: null
  // searchCityText: null,
  // searchCountryText: null,

};

const TaskReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    //get All Task
    case types.GetAllTaskRequest:
      return { ...state, allTaskloading: true };
    case types.GetAllTaskRequestSuccess:
      return { ...state, allTaskData: payload.responsedata, allTaskloading: false, searchCityText: payload.actualSearchText || ''};
    case types.GetAllTaskRequestFailure:
      return { ...state, allTaskloading: false };

    //get All Task with country and city
    case types.GetAllTaskWithCountryandCityRequest:
      return { ...state, allTaskwithCountryandCityloading: true };
    case types.GetAllTaskWithCountryandCityRequestSuccess:
      return {
        ...state,
        allTaskWithCountryandCityData: payload,
        allTaskwithCountryandCityloading: false,
      };
    case types.GetAllTaskWithCountryandCityRequestFailure:
      return { ...state, allTaskwithCountryandCityloading: false };

    // Create Task
    case types.CreateTaskRequest:
      return { ...state, CreateTaskloading: true };
    case types.CreateTaskRequestSuccess:
      return {
        ...state,
        createTaskData: payload,
        CreateTaskloading: false,
        addTaskModalOpen: false,
      };
    case types.CreateTaskRequestFailure:
      return { ...state, CreateTaskloading: false };

    // Delete Task
    case types.TaskDeleteRequest:
      return { ...state, DeleteTaskloading: true };
    case types.TaskDeleteRequestSuccess:
      return {
        ...state,
        taskDeleteData: payload,
        DeleteTaskoading: false,
      };
    case types.TaskDeleteRequestFailure:
      return { ...state, DeleteTaskloading: false };

    // Update Task
    case types.TaskUpdateRequest:
      return { ...state, updateTaskloading: true };
    case types.TaskUpdateRequestSuccess:
      return {
        ...state,
        taskUpdateData: payload,
        updateTaskloading: false,
        editTaskModalOpen: false,
      };
    case types.TaskUpdateRequestFailure:
      return { ...state, updateTaskloading: false, editTaskModalOpen: true };

    // Edit TAsk Open Modal
    case types.EditTaskOpenModalRequest:
      return { ...state, editTaskModalOpen: true };

    // Edit TAsk Close Modal
    case types.EditTaskCloseModalRequest:
      return { ...state, editTaskModalOpen: false };

    // Add TAsk Open Modal
    case types.AddTaskOpenModalRequest:
      return { ...state, addTaskModalOpen: true };

    // Add TAsk Close Modal
    case types.AddTaskCloseModalRequest:
      return { ...state, addTaskModalOpen: false };

    default:
      return state;
  }
};
export default TaskReducer;
