import { types } from "../action_types";

const INITIAL_STATE = {
  createAdminLoading: false,
};

const AdminReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.CreateAdminRequest:
      return { ...state, createAdminLoading: true };
    case types.CreateAdminRequestSuccess:
      return { ...state, createAdminLoading: false };
    case types.CreateAdminRequestFailure:
      return { ...state, createAdminLoading: false };

    default:
      return state;
  }
};

export default AdminReducer;
