import { notification } from "antd";
const key = "updatable";
const CustomToster = (data) => {
 

    if(data?.type === 'success'){
        notification.success({
            key,
            message: `Congratulations`,
            description: data?.message
        });
    }
    else if(data?.type === 'warning'){
        notification.warning({
            key,
            message: `Warning`,
            description: data?.message
        });
    }
    else {
        notification.error({
            key,
            message: `Error`,
            description: data?.message
        });
    }
   
}
export default CustomToster;