import React, { memo, useState } from "react";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { Card } from "antd";
import UserLogo from "../../../../../Assets/Users/user.png";
import { LocationIcon } from "../../../../SharedComponents/icons/icons";
const TaskHints = memo(({}) => {
  let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <div>
      {arr.map(() => {
        return (
          <div className="mt20">
            <Card>
              <div className="row col-md-12 mt16">
                <div className="col-md-1">
                  <img src={UserLogo} />
                </div>
                <div className="col-md-11">
                  <div className="row col-md-12 d-flex align-items-baseline">
                    <div className="col-md-2 gap15 d-flex">
                      <span className="userTitle">Jamie Scott</span>
                      <div className="userStatus">Verified</div>
                    </div>
                    <div className="col-md-8 gap5 d-flex justify-content-center">
                      <div className="rank">Rank #154</div>

                      <div className="task mb-1">XP - Task Done</div>
                      <div className="teamsection mb-1">
                        <div>. Team+1</div>
                        <div>. Magnet</div>
                        <div>. Time Dilation</div>
                      </div>
                      <div className="score mb-1">Score - 5530</div>
                    </div>
                    <div className="col-md-2">
                      <div>
                        Joined: <span className="text-detail">2 days ago</span>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div>
                      <LocationIcon />
                    </div>
                    <div className="location">in Los Angeles, CA</div>
                  </div>
                </div>
              </div>
              <div className="task-grid-wrapper mt20">
                <div className=" listheaing">
                  Friends: <span className="text-detail">10</span>
                </div>

                <div className=" listheaing">
                  Games joined: <span className="text-detail">10</span>
                </div>

                <div className=" listheaing">
                  Family member:<span className="text-detail">Yes</span>
                </div>

                <div className=" listheaing">
                  Rewards:<span className="text-detail">105 $CRH</span>
                </div>

                <div className=" listheaing">
                  Country:<span className="text-detail">UK</span>
                </div>

                <div className=" listheaing">
                  City:<span className="text-detail">London</span>
                </div>
                <div className=" listheaing">
                  Gifts sent:<span className="text-detail">53</span>
                </div>
                <div className=" listheaing">
                  Agreements:<span className="text-detail">3</span>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
});

export default TaskHints;
