import {useSelector} from "react-redux";
import './viewVotes.css';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Pie} from 'react-chartjs-2';

const colors = [
  'rgba(255, 99, 132)',
  'rgba(54, 162, 235)',
  'rgba(255, 206, 86)',
  'rgba(75, 192, 192)',
  'rgba(153, 102, 255)',
]
ChartJS.register(ArcElement, Tooltip, Legend);


const ViewVotes = () => {
  const {voteViewData, voteGraphData} = useSelector((state) => ({
    voteViewData: state.VotesReducer.voteViewData?.result?.pollResults,
    voteGraphData: state.VotesReducer.voteGraphData
  }));
  return (
      <>
        <div>
          <>
            <div className="mt20">
              <div className="view-votes-container">
                <div className="chart-container">
                  <Pie data={
                    {
                      datasets: [
                        {
                          label: 'Votes # ',
                          data: voteGraphData,
                          backgroundColor: colors,
                          borderColor: colors,
                          borderWidth: 1,
                        },
                      ],
                    }
                  } redraw={true}/>
                  <div className="chart-description-container">
                    <div className="chart-description-container-section">
                      <h3 className="chart-description-container-section-heading">{voteViewData?.totalPeopleParticipate}</h3>
                      <p className="chart-description-container-section-desc">Users participated</p>
                    </div>

                    <div className="chart-description-container-section">
                      <h3 className="chart-description-container-section-heading">{voteViewData?.peopleDidNotParticipate}</h3>
                      <p className="chart-description-container-section-desc">Users didn't
                        participated</p>
                    </div>

                    <div className="chart-description-container-section helper-flex-90">
                      <h3 className="chart-description-container-section-heading">{voteViewData?.totalUserCount}</h3>
                      <p className="chart-description-container-section-desc">Number of  Registered Users</p>
                    </div>
                  </div>
                </div>

                <div className="votes-container">
                  {
                    voteViewData.choices.map((each, index) => {
                      return (
                          <div className="votes-item" key={index}>
                            <h3 style={{
                              background: `linear-gradient(to right, ${colors[index]} 2%, transparent, transparent)`,
                              padding: '4px 24px',
                              borderRadius: '8px',
                              marginBottom: 0,
                              fontSize: '18px',
                              fontWeight: '600',
                              lineHeight: '21px',
                            }}>{each?.totalVotes} No of Votes ({each?.percentage}%)</h3>
                            <p className="votes-item-desc">Options: {each?.option}</p>
                          </div>
                      )
                    })
                  }
                  <div className="votes-item" key={5}>
                    <h3 style={{
                      background: `linear-gradient(to right, ${colors[colors.length - 1]} 2%, transparent, transparent)`,
                      padding: '4px 24px',
                      borderRadius: '8px',
                      marginBottom: 0,
                      fontSize: '18px',
                      fontWeight: '600',
                      lineHeight: '21px',
                    }}>{voteViewData?.peopleDidNotParticipate} No of Users</h3>
                    <p className="votes-item-desc">Users didn't participated</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </>
  );
};
export default ViewVotes;
