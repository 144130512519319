import React, { useEffect, useState } from "react";
import FamiliesCardlayout from "./FamiliesCard";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../../store/action_types";
import { Pagination } from "antd";

const FamiliesCard = () => {
  const { allFamilyData } = useSelector((state) => ({
    allFamilyData: state.FamilyDataReducer?.allFamilyData,
  }));

  const [familyData, setFamilyData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: types.GetAllFamilyData,
      payload: 1,
    });
  }, []);

  useEffect(() => {
    if (allFamilyData) {
      setFamilyData(allFamilyData?.result?.agreements?.docs);
    }
  }, [allFamilyData]);

  const onChangeTaskPagination = (page) => {
    setCurrentPage(page);
    dispatch({
      type: types.GetAllFamilyData,
      payload: {
        page,
      },
    });
  };

  return (
    <>
      <div className="searchbox">
        <span className="questheading">Families page</span>
        <div className="ml17">
          {/* <SearchIcon /> */}
        </div>
      {/* <SearchBar /> */}
      </div>
      <div>
        <div>
          <div className="family-grid-wrapper" style={{ height: `auto !important`}}>
            {familyData?.map((item) => (
              <div className=" mb-3" key={item._id}>
                <FamiliesCardlayout data={item} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mt20">
        <Pagination
          current={currentPage}
          pageSize={10}
          total={allFamilyData?.result?.agreements?.totalDocs}
          onChange={(page) => onChangeTaskPagination(page)}
          showSizeChanger={false}
        />
      </div>
    </>
  );
};

export default FamiliesCard;
