export function msToTime(milliseconds) {
  var hours = milliseconds / (1000 * 60 * 60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  var seconds = (minutes - absoluteMinutes) * 60;
  var absoluteSeconds = Math.floor(seconds);
  var s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;

  return h + ":" + m + ":" + s;
}

export const gameModeData = [
  {
    label: "Grand",
    value: "grand",
  },
  {
    label: "Free to play",
    value: "free-to-play",
  },
  {
    label: "Regular",
    value: "regular",
  },
];
export const timeToOpenAfterPreviousArrayData = [
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "20", value: "20" },
  { label: "40", value: "40" },
  { label: "60", value: "60" },
  { label: "80", value: "80" },
  { label: "100", value: "100" },
  { label: "120", value: "120" },
  { label: "140", value: "140" },
  { label: "160", value: "160" },
  { label: "180", value: "180" },
];
export const allowedExtensions = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "bmp",
  "webp",
  "tiff",
  "psd",
  "svg",
];
export const data = [
  {
    key: 1,

    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 2,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 3,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 4,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },

  {
    key: 5,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 6,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 7,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 8,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 9,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 10,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 11,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
  {
    key: 12,
    name: "1941",
    city: "NY",

    country: "USA",
    geolocations: "14.949.03003.03003-18.84883-82882",
    typeofclue: "Open after mint NFT",
    assetimage: "Text in the air",

    status: "Publish",
  },
];
export function getExtension(filename) {
  return filename.split(".").pop();
}
