import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from '@redux-devtools/extension';
import { rootSaga } from './sagas';
import combineReducers from './reducers'
import localStorage from 'redux-persist/es/storage';

const persistConfig = {
  key: 'root',
  storage: localStorage,
  version: 1,
  whitelist: ['GlobalReducer'],
  blacklist: ['AuthReducer', 'AnnouncementSaga']
};

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware);
const persistedReducer = persistReducer(persistConfig, combineReducers)
const store = createStore(persistedReducer, composeWithDevTools(middleware))


sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);
export { store, persistor }