import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Spin, Button, Upload } from "antd";
import CustomToster from "../CustomToaster";
import { allowedExtensions, getExtension } from "../../Utils/constants";
import { useState, useEffect } from "react";
import { types } from "../../store/action_types";

export const UploadImage = ({
  setImageFile,
  ErrorMessage,
  setIsFirstTime,
  isFirstTime,
  formikRef,
  isEdit,
  currentPool = {},
  loading,
}) => {
  const BoastImageLink = useSelector(
    (state) => state?.BoostReducer?.uploadBoastimageAllData?.result?.imgLink
  );
  const [imageLoading, setImageLoading] = useState(false);

  const dispatch = useDispatch();

  const IconButton = (iconName) => {
    return (
      <Button
        icon={
          iconName === "EditOutlined" ? (
            <EditOutlined style={{ verticalAlign: "middle" }} />
          ) : (
            <PlusOutlined style={{ verticalAlign: "middle" }} />
          )
        }
        shape="circle"
        style={{
          backgroundColor:
            iconName === "PlusOutlined" ? "black" : "transparent",
          color: iconName === "PlusOutlined" ? "white" : "black",
        }}
      ></Button>
    );
  };

  const ImageLoading = () => {
    return (
      <div className="boastStyle">
        <Spin style={{ color: "#78633A" }}></Spin>
      </div>
    );
  };

  const imageChange = (e) => {
    if (!e?.fileList[0]) {
      return;
    }
    let formData = new FormData();
    if (e.fileList[0]?.size > 3 * 1024 * 1024) {
      CustomToster({
        type: "error",
        message: "Selected image exceeds the maximum allowed file size of 3MB",
      });
      e.fileList = null;
      return;
    }
    const foundItem = allowedExtensions.find(
      (item) => item === getExtension(e.fileList[0]?.name)
    );
    if (!foundItem) {
      CustomToster({
        type: "error",
        message: "please upload only image file",
      });
      return;
    }
    setImageLoading(true);
    formData.append("file", e.fileList[0]?.originFileObj);
    setImageFile(e?.fileList[0]?.originFileObj);
    let fileReader = new FileReader();
    fileReader?.readAsDataURL(e?.fileList[0]?.originFileObj);

    fileReader.onload = (event) => {
      dispatch({
        type: types.UploadBoastImageRequest,
        payload: {
          uploadCollectionImageData: {
            fileBase64: event.target.result.split(",")[1],
            fileName: e?.fileList[0]?.name,
          },
        },
        setImageLoading,
        formData: formData,
      });
    };
    setIsFirstTime(false);
  };

  useEffect(() => {
    if (BoastImageLink) {
      formikRef.current.setFieldValue("image", BoastImageLink);
    }
  }, [BoastImageLink]);

  return (
    <div className="imageContainer" style={{ paddingTop: 10 }}>
      {imageLoading ? (
        ImageLoading()
      ) : (BoastImageLink && !isFirstTime) || isEdit ? (
        <div className="boastImageLink">
          <img
            src={isEdit ? currentPool.icon : BoastImageLink}
            alt="avatar"
            className="image"
          />
          {!isEdit && (
            <Upload
              style={{ marginTop: "21px" }}
              accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .tiff, .psd, .svg"
              maxCount={1}
              onChange={imageChange}
              showUploadList={false}
              disabled={loading}
            >
              {IconButton("EditOutlined")}
            </Upload>
          )}
        </div>
      ) : (
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .tiff, .psd, .svg"
          maxCount={1}
          onChange={imageChange}
          showUploadList={false}
          disabled={loading}
        >
          {imageLoading ? ImageLoading() : IconButton("PlusOutlined")}
        </Upload>
      )}
      <ErrorMessage name="image">
        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
      </ErrorMessage>
    </div>
  );
};
