import React, { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import { ErrorMessage, Field, Formik } from "formik";
import { Constants } from "../../Constants";
import { types } from "../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import CustomTextInput from "../CustomTextInput";
import { UploadImage } from "../UploadImage";

const AddCompany = ({
  handleCloseModal,
  isFirstTime,
  setIsFirstTime,
  currentCompany,
  isEdit,
  page,
}) => {
  const { createCompanyLoading, editCompanyLoading } = useSelector(
    (state) => state.CompaniesManagementReducer
  );
  const loading = createCompanyLoading || editCompanyLoading;
  const [imageFile, setImageFile] = useState(null);
  const formikRef = useRef();
  const dispatch = useDispatch();

  const handleResetForm = () => {
    setImageFile(null);
    formikRef.current.resetForm({
      values: {
        name: "",
        info: "",
        image: "",
      },
    });
  };

  useEffect(() => {
    if (isEdit) {
      formikRef.current.setFieldValue("name", currentCompany.name);
      formikRef.current.setFieldValue("info", currentCompany.info);
      formikRef.current.setFieldValue("image", currentCompany.logo);
    } else {
      formikRef.current.setFieldValue("name", "");
      formikRef.current.setFieldValue("info", "");
      formikRef.current.setFieldValue("image", "");
    }
  }, [currentCompany]);

  return (
    <div>
      <div className="modaltitle">{`${isEdit ? "Edit" : "Add"}`} Company</div>
      <Formik
        key="my-form"
        initialValues={{
          image: "",
          name: "",
          info: "",
        }}
        enableReinitialize={false}
        innerRef={formikRef}
        validationSchema={Constants.AddCompanySchema}
        onSubmit={(values, { resetForm }) => {
          if (!isEdit) {
            let formData = new FormData();
            formData.append("name", values.name);
            formData.append("info", values.info);
            formData.append("file", imageFile);

            dispatch({
              type: types.CreateCompanyRequest,
              payload: {
                formData,
                handleCloseModal: () => {
                  handleCloseModal();
                  resetForm();
                },
                page,
              },
            });
          } else {
            const updatedBody = {
              companyId: currentCompany._id,
              name: values.name,
              info: values.info,
            };
            dispatch({
              type: types.EditCompanyRequest,
              payload: {
                updatedBody,
                handleCloseModal: () => {
                  handleCloseModal();
                  resetForm();
                },
                page,
              },
            });
          }
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <div className="formContainer">
            <div className="formHeader">
              <UploadImage
                setImageFile={setImageFile}
                ErrorMessage={ErrorMessage}
                setIsFirstTime={setIsFirstTime}
                isFirstTime={isFirstTime}
                formikRef={formikRef}
                isEdit={isEdit}
                currentPool={{ icon: currentCompany?.logo }}
                loading={loading}
              />

              <div className="formFields">
                <Field name="name">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Name"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 25)
                          handleChange("name")(e);
                      }}
                      placeHolderText={"Enter name..."}
                      value={values.name}
                      onBlur={handleBlur("name")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                    />
                  )}
                </Field>
                <Field name="info">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Info"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 75)
                          handleChange("info")(e);
                      }}
                      placeHolderText={"Enter info..."}
                      value={values.info}
                      onBlur={handleBlur("info")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                    />
                  )}
                </Field>
              </div>
            </div>

            <div className="formFooter">
              <button
                type="submit"
                disabled={loading}
                className="addbtn border p1060"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && <Spin className="spin" />}
                {isEdit ? "Save" : "Add"}
              </button>
              <button
                type="submit"
                disabled={loading}
                className="cancelbtn border p1060"
                onClick={() => {
                  if (!isEdit) {
                    handleResetForm();
                  }
                  handleCloseModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddCompany;
