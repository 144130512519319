import { types } from "../action_types";

const INITIAL_STATE = {
  // otpdata:null,
  votesListingAllData: [],
  allVotesloading: false,
//   createAnnouncementloading: false,
  createVotesModalOpen: false,
  votesCreationData:[],
  createVotesloading:false,
  votesUpdateData:[],
  updateVotesloading:false,
  voteDeleteData:[],
  DeleteVoteloading:false,
  viewVoteloading:false,
  voteViewData:[],
  voteGraphData: [],
  viewVotesModalOpen:false,
//   deleteAnnouncementModalOpen: false,
//   editAnnouncementModalOpen: false,
//   announcementCreationData: "",
searchText: null
};

const VotesReducer = (state = INITIAL_STATE, { type, payload }) => {

  switch (type) {
    //Announcement listing
    case types.VotesListingRequest:
      return { ...state, allVotesloading: true };
    case types.VotesListingRequestSuccess:
      return {
        ...state,
        votesListingAllData: payload,
        allVotesloading: false,
        searchText: payload.actualSearchText || ''
      };
    case types.VotesListingRequestFailure:
      return { ...state, allVotesloading: false };

    // Create Votes
    case types.VotesCreationRequest:
      return { ...state, createVotesloading: true };
    case types.VotesCreationRequestSuccess:
      return {
        ...state,
        votesCreationData: payload,
        createVotesloading: false,
        createVotesModalOpen:false
      };
    case types.VotesCreationRequestFailure:
      return { ...state, createVotesloading: false , createVotesModalOpen:true};

      
      // Update Votes
    case types.VotesUpdateRequest:
        return { ...state, updateVotesloading: true };
      case types.VotesUpdateRequestSuccess:
        return {
          ...state,
          votesUpdateData: payload,
          updateVotesloading: false,
          editVotesModalOpen:false,
        };
      case types.VotesUpdateRequestFailure:
        return { ...state, updateVotesloading: false,editVotesModalOpen:true };

    //     Delete Vote
    case types.VoteDeleteRequest:
        return { ...state, deleteVoteloading: true };
      case types.VoteDeleteRequestSuccess:
        return {
          ...state,
          voteDeleteData: payload,
          deleteVoteloading: false,
        };
      case types.VoteDeleteRequestFailure:
        return { ...state, deleteVoteloading: false };

           //     View Vote
    case types.VoteViewRequest:
      return { ...state, viewVoteloading: true };
    case types.VoteViewRequestSuccess:
      return {
        ...state,
        voteViewData: payload,
        viewVoteloading: false,
      };
    case types.VoteViewRequestFailure:
      return { ...state, viewVoteloading: false };

    case types.VoteViewGraphData:
      return {
        ...state,
        voteGraphData: payload,
      };

       //  View Votes Open Modal
    case types.ViewVotesOpenModalRequest:
      return { ...state, viewVotesModalOpen: true };
   
         // View Votes Close Modal
      case types.ViewVotesCloseModalRequest:
        return { ...state, viewVotesModalOpen: false };


        //  Create Votes Open Modal
    case types.CreateVotesOpenModalRequest:
      return { ...state, createVotesModalOpen: true };
   
         // Create Votes Close Modal
      case types.CreateVotesCloseModalRequest:
        return { ...state, createVotesModalOpen: false };
      
            //  Edit Votes Open Modal
    case types.EditVotesOpenModalRequest:
      return { ...state, editVotesModalOpen: true };
   
        //  Edit Votes Close Modal
      case types.EditVotesCloseModalRequest:
        return { ...state, editVotesModalOpen: false };

    default:
      return state;
  }
};
export default VotesReducer;
