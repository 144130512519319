import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Spin,
  Pagination,
  Button,
  Modal,
  Tooltip,
  Input,
  Select,
} from "antd";
import moment from "moment";
import { types } from "../../../store/action_types";

const { Option } = Select;

const Reports = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const reportData = useSelector(
    (state) => state.ReportsReducer.reportsListingAllData
  );

  useEffect(() => {
    dispatch({
      type: types.GetAllReportsRequest,
      payload: currentPage,
    });
  }, [dispatch, currentPage]);

  const blockUser = (userId, currentPage) => {
    dispatch({
      type: types.BlockUserRequest,
      payload: { userId, currentPage },
    });
  };

  const unblockUser = (userId, currentPage) => {
    dispatch({
      type: types.UnblockUserRequest,
      payload: { userId, currentPage },
    });
  };

  const handleBlockUser = (userId) => {
    blockUser(userId, currentPage);
  };

  const handleUnblockUser = (userId) => {
    unblockUser(userId, currentPage);
  };

  const handleSearch = () => {
    dispatch({
      type: types.SEARCH_REPORTS_REQUEST,
      payload: {
        currentPage,
        selectedOption,
        searchText
      },
    });
    setCurrentPage(1);
  };

  const handleCategorySelect = (category) => {

    dispatch({
      type: types.SEARCH_REPORTS_REQUEST,
      payload: {
        currentPage: 1,
        selectedOption: category,
      },
    });
    setCurrentPage(1);
  };


  const handleToggleBlockUser = (record) => {
    const isBlocked = blockedUsers.includes(record.complainantName);

    Modal.confirm({
      title: `${isBlocked ? "Unblock" : "Block"} ${record.complainantName}`,
      content: `Are you sure you want to ${record?.complainant?.isBlocked ? "unblock" : "block"
        } user ${record.complainantName}?`,
      onOk() {
        if (isBlocked) {
          setBlockedUsers((prev) =>
            prev.filter((user) => user !== record.reporter)
          );
        } else {
          setBlockedUsers((prev) => [...prev, record.reporter]);
        }

        if (record?.complainant?.isBlocked) {
          handleUnblockUser(record?.complainant?._id);
        } else {
          handleBlockUser(record?.complainant?._id);
        }
      },
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);

    if (selectedOption === 'name') {
      dispatch({
        type: types.SEARCH_REPORTS_REQUEST,
        payload: {
          currentPage: page,
          selectedOption,
          searchText,
        },
      });
    } else if (selectedOption === 'user-profile' || selectedOption === 'content') {
      dispatch({
        type: types.SEARCH_REPORTS_REQUEST,
        payload: {
          currentPage: page,
          selectedOption,
        },
      });
      setCurrentPage(1);

    } else {
      dispatch({
        type: types.GetAllReportsRequest,
        payload: page,
      });
    }
  };


  const columns = [
    {
      title: "Reporter",
      dataIndex: "reporterName",
      key: "reporterName",
    },
    {
      title: "Complainant",
      dataIndex: "complainantName",
      key: "complainantName",
      // render: (complainant) =>
      //   complainant.isBlocked ? "Blocked" : "Not Blocked",
    },
    {
      title: "Report Type",
      dataIndex: "reportType",
      key: "reportType",
    },
    {
      title: "Content Type",
      dataIndex: "contentType",
      key: "contentType",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (message) => (
        <Tooltip title={message} placement="topLeft">
          <div
            style={{
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {message.length > 10 ? `${message.slice(0, 10)}...` : message}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("MM/DD/YYYY, HH:mm"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
            console.log('report', _, record)
        return <Button
            type="primary"
            style={{ backgroundColor: "#BAA068", borderColor: "#BAA068" }}
            onClick={() => handleToggleBlockUser(record)}
        >
          {record?.complainant?.isBlocked ? "Unblock" : "Block"}
          {/*{blockedUsers.includes(record.reporter) ? "Unblock" : "Block"}*/}
        </Button>
      },
    },
  ];

  return (
    <div>
      <h2>Reports</h2>
      <div style={{ display: "flex", marginBottom: "20px" }}>

        <Select
          style={{ width: "20%", marginRight: "30px" }}
          placeholder="Filter by..."
          value={selectedOption}
          onChange={(value) => {
            setSelectedOption(value);
            if (value === "user profile" || value === "content") {
              handleCategorySelect(value);
            }
          }}
        >
          <Option key="name" value="name">
            Name
          </Option>
          <Option key="user profile" value="user-profile">
            User Profile
          </Option>
          <Option key="content" value="content">
            Content
          </Option>
        </Select>


        <Input.Search
            style={{ width: "20%" }}
            value={searchText}
            placeholder={'Search by name...'}
            onChange={(e) => {
              setSearchText(e.target.value);
              if (e.target.value === "") {
                dispatch({
                  type: types.GetAllReportsRequest,
                  payload: currentPage,
                });
              }
            }}
            onSearch={() => handleSearch()}
            disabled={selectedOption !== "name"}
        />


      </div>
      {reportData ? (
        <>
          <Table
            columns={columns}
            dataSource={reportData?.result?.docs}
            pagination={false}
          />
          <div className="d-flex justify-content-end mt20">
            <Pagination
              current={reportData?.result?.currentPage}
              // pageSize={reportData?.result?.itemsPerPage}  
              total={reportData?.result?.totalDocs}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <Spin tip="Loading..." style={{ color: "#78633A" }} />
      )}
    </div>
  );
};

export default Reports;
