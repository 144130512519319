import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { DELETE, GET, POST, PUT } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";
const token = window.sessionStorage.getItem("token");

function* GetAllQuestListingSaga(action) {

  const { payload } = action
  const page = payload.page || 1; 
  const searchText = payload.searchText || '';
  const actualSearchText = searchText.trim();

  try {
    const questListingAllData_response = yield GET(
      `admin/quest/all?page=${page}&limit=10&name=${actualSearchText}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responsedata = { ...questListingAllData_response?.data };

    if (questListingAllData_response?.status === 200) {
      yield put({
        type: types.QuestListingRequestSuccess,
        payload: { responsedata, actualSearchText },
      });
    } else {
      yield put({ type: types.QuestListingRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.QuestListingRequestFailure });
  }
}

function* GetQuestDetailByIdSaga({ payload }) {
  try {
    const questDetailByIdData_response = yield GET(`admin/quest/${payload}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let responsedata = { ...questDetailByIdData_response?.data?.result?.quest };

    if (questDetailByIdData_response?.status === 200) {
      yield put({
        type: types.QuestDetailByIdRequestSuccess,
        payload: responsedata,
      });

      yield put({
        type: types.EditQuestOpenModalRequest,
      });
    } else {
      yield put({ type: types.QuestDetailByIdRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.QuestDetailByIdRequestFailure });
  }
}

function* CreateQuestSaga(action ) {
  const { createQuestData } = action.payload;

  try {
    const questCreationData_response = yield POST(
      "admin/quest/create",
      createQuestData
    );

    let questCreationresponsedata = {
      ...questCreationData_response?.data,
    };

    if (questCreationData_response?.status === 200) {
      yield put({
        type: types.QuestCreationRequestSuccess,
        payload: questCreationresponsedata,
      });
      if (questCreationresponsedata.mode === 'regular' || questCreationresponsedata.mode === 'grand') {
        yield put({
          type: types.QuestCreationBlockchain,
          payload: true,
        });
      }
      // if (questCreationresponsedata.mode === 'free-to-play') {
      //   yield put({
      //     type: types.QuestCreationBlockchain,
      //     payload: true,
      //   });
      // }
      yield put({
        type: types.QuestListingRequest,
        payload: { page: 1, actualSearchText: "" },
      });
      CustomToster({
        type: "success",
        message: "Your Quest Created Successfully",
      });
      window.location.replace("/all-quest");
    } else {
      yield put({ type: types.QuestCreationRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.QuestCreationRequestFailure });
  }
}

function* UpdateQuestSaga({ payload }) {
  const { updateQuestData, currentPage } = payload;

  try {
    const questUpdateData_response = yield PUT(
      "admin/quest/update",
      updateQuestData
    );

    let questUpdateresponsedata = {
      ...questUpdateData_response?.data,
    };

    if (questUpdateData_response?.status === 200) {
      yield put({
        type: types.QuestUpdateRequestSuccess,
        payload: questUpdateresponsedata,
      });
      yield put({
        type: types.QuestListingRequest,
        payload: currentPage,
      });
      CustomToster({
        type: "success",
        message: "Your Record Updated Successfully",
      });
      window.location.reload();
    } else {
      yield put({ type: types.QuestUpdateRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.QuestUpdateRequestFailure });
  }
}

function* DeleteQuestSaga({ payload }) {
  const { id, currentPage } = payload;

  try {
    const questDeleteData_response = yield DELETE(`admin/quest/${id}`);

    let questDeleteresponsedata = {
      ...questDeleteData_response?.data,
    };

    if (questDeleteData_response?.status === 200) {
      yield put({
        type: types.QuestDeleteRequestSuccess,
        payload: questDeleteresponsedata,
      });
      yield put({
        type: types.QuestListingRequest,
        payload: currentPage,
      });
      CustomToster({
        type: "success",
        message: "Your Record Deleted Successfully",
      });
    } else {
      yield put({ type: types.QuestDeleteRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.QuestDeleteRequestFailure });
  }
}

export default function* QuestSaga() {
  yield takeLatest(types.QuestListingRequest, GetAllQuestListingSaga);

  yield takeLatest(types.QuestCreationRequest, CreateQuestSaga);
  yield takeLatest(types.QuestDetailByIdRequest, GetQuestDetailByIdSaga);
  yield takeLatest(types.QuestUpdateRequest, UpdateQuestSaga);

  yield takeLatest(types.QuestDeleteRequest, DeleteQuestSaga);
}
