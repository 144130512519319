import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const SearchBarPro = ({ onSearch, width = 300, searchText, setSearchText }) => {
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const debounceDelay = 400; // Fixed debounce delay of 400ms

  const handleInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      onSearch(text);
    }, debounceDelay);

    setDebounceTimeout(timeout);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  return (
    <div className="search-bar" style={styles.searchBar}>
      <Input
        placeholder="Search..."
        value={searchText}
        onChange={handleInputChange}
        style={{ ...styles.input, width }}
        prefix={<SearchOutlined />}
      />
    </div>
  );
};

const styles = {
  searchBar: {
    marginRight: "7px",
    marginLeft: "7px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  input: {
    height: "49px",
    borderRadius: "10px",
    fontSize: "17px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
};

export default SearchBarPro;
