import { Field, Formik } from "formik";
import CustomTextInput from "../../../../SharedComponents/CustomTextInput";
import Select from "react-select";
import { useEffect, useState } from "react";
import { types } from "../../../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Switch, Spin, Button, message } from "antd";
import moment from "moment";
import { Constants } from "../../../../Constants";
import { fetchCitiesOptions } from "../../../../Utils/citiesListUpdate";
import EditQuestCard from "../EditQuest/EditQuestCard";
import CH_Coin_ABI from "../../../../../Assets/chCoin.json";
import { gameModeData } from "../../../../Utils/constants";
import { ethers } from "ethers";
import CustomToster from "../../../../SharedComponents/CustomToaster";
import bigInt from "big-integer";
import GoogleMapComponent from "../../Tasks/Map";
import GoogleSelect from "../../../../SharedComponents/GoogleSelect";

let tasksToSend = [];

const CreateNewQuest = () => {
  const dispatch = useDispatch();
  var minimumDate = new Date();

  useEffect(() => {
    dispatch({
      type: types.PrizePoolListingRequest,
      payload: {
        page: 1,
      },
    });
  }, []);

  const { prizePoolListingByGameModeAllData } = useSelector((state) => ({
    prizePoolListingByGameModeAllData:
      state.PrizePoolReducer.prizePoolListingByGameModeAllData?.result
        ?.prizePool,
  }));

  const [cityList, setCityList] = useState([]);
  const [cityQuery, setCityQuery] = useState("");
  const [countryName, setCountryName] = useState("");
  const [questPrizePool, setQuestPrizePool] = useState("");
  const [prizrPoolOptions, setPrizrPoolOptions] = useState(
    prizePoolListingByGameModeAllData
  );
  const [gameMode, setGameMode] = useState("");
  const [coinCount, setCoinCount] = useState(0);
  const [questTotalTime, setquestTotalTime] = useState(0);
  const [disableRegion, setDisableRegion] = useState(false);
  const [questTaskData, setQuestTaskData] = useState("");
  const [searchCityData, setSearchCityData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [taskArray, setTaskArray] = useState([]);
  const [cityAndCountryData, setCityAndCountryData] = useState({
    latitude: null,
    longitude: null,
    country: "",
    countryCode: "",
    city: "",
  });
  const [latitude, setLatitude] = useState(cityAndCountryData.latitude || "");
  const [longitude, setLongitude] = useState(
    cityAndCountryData.longitude || ""
  );
  const [days, setDays] = useState(1);
  const [hours, setHours] = useState(0);

  const handleDaysChange = (e) => {
    setDays(Number(e.target.value));
  };

  const handleHoursChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 0 && value <= 23) {
      setHours(value);
    }
  };

  const getCityCountryVal = (data, setFieldValue) => {
    const { city, country, latitude, longitude } = data;
    setFieldValue("selectedCity", city);
    setFieldValue("selectedCountry", country);
    setCityAndCountryData(data);
  };

  const handleChangeLocationInGoogleSelect = (latitude, longitude) => {
    setCityAndCountryData((prevState) => ({
      ...prevState,
      latitude,
      longitude,
    }));
  };

  const [clearSignal, setClearSignal] = useState(false);

  const handleClear = () => {
    setClearSignal(true);
  };

  useEffect(() => {
    if (cityAndCountryData.city && cityAndCountryData.country) {
      let newData = {
        countryCode: cityAndCountryData.countryCode,
        city: cityAndCountryData.city,
        pageNum: 1,
      };
      dispatch({
        type: types.GetAllTaskWithCountryandCityRequest,
        payload: newData,
      });
    }
  }, [cityAndCountryData]);

  useEffect(() => {
    if (countryName && cityList) {
      const filteredData = cityList.filter(
        (item) => item.countryName == countryName.label
      );
      setCityList(filteredData);
    }
  }, [countryName]);

  useEffect(() => {
    if (prizePoolListingByGameModeAllData) {
      setPrizrPoolOptions(prizePoolListingByGameModeAllData);
    }
  }, [prizePoolListingByGameModeAllData]);

  const setResponseData = (data) => {
    setSearchCityData(data);
  };
  useEffect(() => {
    const fetchCities = setTimeout(() => {
      if (cityQuery) {
        fetchCitiesOptions(
          cityQuery,
          countryName ? countryName.iso2 : "",
          setResponseData
        );
      }
    }, 500);
    return () => clearTimeout(fetchCities);
  }, [cityQuery]);

  useEffect(() => {
    if (searchCityData?.predictions) {
      const newArray = searchCityData?.predictions.map((item) => {
        let cityDetail = item.description.split(", ");
        return {
          label: item.structured_formatting.main_text,
          countryName: cityDetail[cityDetail?.length - 1],
        };
      });

      setCityList(newArray);
    }
  }, [searchCityData]);

  const FetchUpdatedTaskArray = (page) => {
    let newData = {
      countryCode: cityAndCountryData.countryCode,
      city: cityAndCountryData.city,
      pageNum: page || 1,
    };
    dispatch({
      type: types.GetAllTaskWithCountryandCityRequest,
      payload: newData,
    });
  };

  const handleQuestTaskData = (data) => {
    setQuestTaskData(data);
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(remainingMinutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleGameMode = (e, values, setFieldValue) => {
    setGameMode(e);
    setFieldValue("modeofquest", e);

    let data = {
      gameMode: e,
      pageNum: 1,
    };

    dispatch({
      type: types.PrizePoolListingByGameModeRequest,
      payload: data,
    });
  };

  const handleStartDateChange = (date, setFieldValue) => {
    const currentDateTime = new Date();
    const twoMinutesLater = new Date(currentDateTime.getTime() + 2 * 60000);

    if (date && date < twoMinutesLater) {
      message.error("Start time must be at least 2 minutes in the future");
    } else {
      message.destroy();
      setFieldValue("startDate", date);
    }
  };

  useEffect(() => {
    if (questTaskData) {
      if (questTaskData.length) {
        tasksToSend = questTaskData?.filter((item) => item !== null);
      }
    }
    const filteredArray = tasksToSend.filter((element) => {
      return element !== null && typeof element === "object";
    });
    const totalTaskTime = filteredArray.reduce((total, obj) => {
      return total + (obj.taskTime || 0);
    }, 0);

    setquestTotalTime(totalTaskTime / 60000);
  }, [questTaskData]);

  const transferCoin = async () => {
    setIsLoading(true);
    if (window?.ethereum) {
      window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      let promise = new Promise((resolve, reject) => {
        const chCoin = new ethers.Contract(
          process.env.REACT_APP_CH_COIN_CONTRACT_ADDRESS,
          CH_Coin_ABI,
          signer
        );
        chCoin
          ?.transfer(
            process.env.REACT_APP_CONTRACT_ADDRESS,
            bigInt(coinCount * 10 ** 18).toString(10)
          )
          .then((result) => {
            result
              ?.wait()
              .then((res) => {
                resolve({ success: true });
                setIsLoading(false);
              })
              .catch((error) => {
                CustomToster({
                  type: "error",
                  message: "Transaction Failed",
                });
                reject(error);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            CustomToster({
              type: "error",
              message: "Transaction Rejected",
            });
            reject(err);
          });
      });
      return promise;
    } else {
      CustomToster({
        type: "error",
        message: "Please install Metamask extension!!",
      });
      return Promise.reject("Metamask not installed");
    }
  };

  const handleSubmit = async (values) => {
    console.log("valies -> taskTime : ", values?.taskTime);
    setIsLoading(true);
    const filteredArray = tasksToSend.filter((element) => {
      return element !== null && typeof element === "object";
    });
    let newStartDate = new Date(values?.startDate);
    let startdateString = moment(newStartDate).toISOString();

    const userEmail = getCookie("userEmail");

    let data = {
      createQuestData: {
        name: values.nameofquest,
        mode: gameMode,
        status: values?.actionstatus == true ? "activate" : "de-activate",
        countryCode: cityAndCountryData.countryCode,
        city: values.selectedCity,
        startTime: startdateString,
        reward: {},
        taskArray: filteredArray,
        ticketFee: values.ticketFee,
      },
    };

    console.log("data><><><", data);

    if (userEmail !== "demo@gmail.com") {
      if (gameMode === "regular" || gameMode === "grand") {
        setCoinCount(values.chCoin);
        data.createQuestData.reward.chCoin = values.chCoin;
        data.createQuestData.reward.ticketFee = values.ticketFee;
        if (filteredArray.length > 0) {
          await transferCoin();
        }

        setIsLoading(false);
      } else if (gameMode === "free-to-play") {
        data.createQuestData.reward.prizePool = questPrizePool;
        data.createQuestData.totalTime =
          days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000;
        setIsLoading(false);
      }
    } else {
      // userEmail is 'ayaz.mehmood@narsunstudios.com', do something else or nothing
    }

    dispatch({
      type: types.QuestCreationRequest,
      payload: data,
    });

    setIsLoading(false);
  };

  const handleLatitudeChange = (e) => {
    handleClear();
    const { value } = e.target;
    setLatitude(value);
    setCityAndCountryData((prev) => ({
      ...prev,
      latitude: value,
    }));
  };

  const handleLongitudeChange = (e) => {
    handleClear();
    const { value } = e.target;
    setLongitude(value);
    setCityAndCountryData((prev) => ({
      ...prev,
      longitude: value,
    }));
  };

  useEffect(() => {
    if (
      cityAndCountryData.latitude !== undefined &&
      cityAndCountryData.longitude !== undefined
    ) {
      setLatitude(cityAndCountryData.latitude);
      setLongitude(cityAndCountryData.longitude);
    }
  }, [cityAndCountryData]);

  function getCookie(name) {
    const cookies = document.cookie.split("; ");

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }

    return null;
  }

  const userEmail = getCookie("userEmail");
  if (userEmail) {
    // console.log("User email:", userEmail);
  } else {
    // console.log("User email cookie allTaskWithCountryandCityDatanot found.");
  }

  // useEffect(() => {
  //     setLatitude(cityAndCountryData.latitude || "");
  // }, [cityAndCountryData.latitude]);

  // useEffect(() => {
  //     setLongitude(cityAndCountryData.longitude || "");
  // }, [cityAndCountryData.longitude]);

  return (
    <>
      <Spin spinning={isLoading} indicator={<LoadingIndicator />}>
        <div className="row">
          <div className="col-md-6"></div>
        </div>
        <Formik
          initialValues={{
            nameofquest: "",
            actionstatus: true,
            startDate: "",
            chCoin: "",
            selectedCity: "",
            selectedCountry: "",
            modeofquest: "",
            questprizepool: "",
            ticketFee: "",
            taskTime: [],
            prizePool: [],
            editQuestCards: [],
          }}
          onSubmit={handleSubmit}
          validationSchema={Constants.QuestGameModeSchema}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
            values,
            touched,
          }) => (
            <>
              <div className="create-quest-wrapper row">
                <div className="row">
                  <div className="col-md-3">
                    <label className="d-flex">Status*</label>

                    <Field name="actionstatus">
                      {({ meta }) => (
                        <Switch
                          defaultChecked={values.actionstatus}
                          onChange={(val) => setFieldValue("actionstatus", val)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-md-3">
                    <Field name="nameofquest">
                      {({ meta }) => (
                        <CustomTextInput
                          label={"Name of Quest"}
                          type="text"
                          error={meta.touched ? meta.error : null}
                          onChange={(e) => {
                            if (e.target.value.length <= 25)
                              handleChange("nameofquest")(e);
                          }}
                          placeHolderText={"USA 321"}
                          value={values.nameofquest}
                          onBlur={handleBlur("nameofquest")}
                          showEyeIcon={false}
                          required={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div style={{ width: "50%", paddingRight: 25 }}>
                  <GoogleSelect
                    callback={handleChangeLocationInGoogleSelect}
                    clearSignal={clearSignal}
                    resetClearSignal={() => setClearSignal(false)}
                    specialy
                  />
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label className="form-label">City</label>
                    <Field
                      name="selectedCity"
                      type="text"
                      disabled
                      className="form-control"
                      value={values.selectedCity || ""}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="form-label">Country</label>
                    <Field
                      name="selectedCountry"
                      type="text"
                      disabled
                      className="form-control"
                      value={cityAndCountryData.country || ""}
                    />
                  </div>
                  <div className="col-md-5" style={{ marginTop: -100 }}>
                    <GoogleMapComponent
                      getCityCountryVal={(data) =>
                        getCityCountryVal(data, setFieldValue)
                      }
                      updateTask={true}
                      longUpdate={cityAndCountryData.longitude}
                      latUpdate={cityAndCountryData.latitude}
                      width={550}
                      height={500}
                      handleClear={handleClear}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label className="form-label">Latitude</label>
                    {/* <Field
                                            name="latitude"
                                            type="text"
                                            // disabled
                                            className="form-control"
                                            value={cityAndCountryData.latitude || ""}
                                        /> */}
                    {/* <input
                                            type="number"
                                            className="form-control mt10"
                                            defaultValue={cityAndCountryData.latitude || ""}
                                            value={cityAndCountryData.latitude || ""}
                                            onChange={(e) => {
                                                setcityAndCountryData((prev) => {
                                                    return {
                                                        ...prev,
                                                        latitude: e.target.value,
                                                    };
                                                });
                                            }}
                                        /> */}
                    <input
                      type="number"
                      className="form-control"
                      value={
                        cityAndCountryData.latitude !== undefined
                          ? cityAndCountryData.latitude
                          : latitude
                      }
                      onChange={handleLatitudeChange}
                      // readOnly={cityAndCountryData.latitude !== undefined}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="form-label">Longitude</label>
                    {/* <Field
                                            name="longitude"
                                            type="text"
                                            // disabled
                                            className="form-control"
                                            value={cityAndCountryData.longitude || ""}
                                        /> */}
                    <input
                      type="number"
                      className="form-control"
                      value={
                        cityAndCountryData.longitude !== undefined
                          ? cityAndCountryData.longitude
                          : longitude
                      }
                      onChange={handleLongitudeChange}
                      // readOnly={cityAndCountryData.longitude !== undefined}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="row">
                    <div className="col-md-3">
                      <label className="d-flex">Game Mode *</label>
                      <Field name="modeofquest">
                        {({ meta }) => (
                          <>
                            <Select
                              onChange={(e) => {
                                handleGameMode(e?.value, values, setFieldValue);
                                setquestTotalTime(0);
                                setPrizrPoolOptions([]);
                                setQuestPrizePool("");
                              }}
                              placeholder="Select any one"
                              options={gameModeData}
                              value={gameModeData.find(
                                (option) => option.value === values.modeofquest
                              )}
                              onBlur={() =>
                                setFieldValue("modeofquest", values.modeofquest)
                              }
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  "&:hover": { borderColor: "#E5E7EB" },
                                  border: "1px solid #E5E7EB",
                                  borderRadius: "0.5rem ",
                                  boxShadow: "none",
                                }),
                              }}
                            />
                            {errors.modeofquest && touched.modeofquest && (
                              <div style={{ color: "red" }}>
                                {errors.modeofquest}
                              </div>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                    {gameMode && (
                      <div className="col-md-5 d-flex justify-content-start">
                        {gameMode === "free-to-play" ? (
                          <div>
                            <label className="d-flex">Prize Pool *</label>
                            <Field name="questprizepool">
                              {({ meta }) => (
                                <>
                                  {!questPrizePool ? (
                                    <Select
                                      onChange={(e) => {
                                        setQuestPrizePool(e?._id);
                                      }}
                                      value={questPrizePool}
                                      placeholder="Select any one"
                                      options={prizrPoolOptions}
                                      getOptionLabel={(option) => option.title}
                                      getOptionValue={(option) => option._id}
                                      styles={{
                                        control: (base, state) => ({
                                          ...base,
                                          "&:hover": { borderColor: "#E5E7EB" },
                                          border: "1px solid #E5E7EB",
                                          borderRadius: "0.5rem ",
                                          boxShadow: "none",
                                        }),
                                      }}
                                    />
                                  ) : (
                                    <Select
                                      onChange={(e) =>
                                        setQuestPrizePool(e?._id)
                                      }
                                      placeholder="Select prize pool"
                                      options={prizrPoolOptions}
                                      getOptionLabel={(option) => option.title}
                                      getOptionValue={(option) => option._id}
                                      styles={{
                                        control: (base, state) => ({
                                          ...base,
                                          "&:hover": { borderColor: "#E5E7EB" },
                                          border: "1px solid #E5E7EB",
                                          borderRadius: "0.5rem ",
                                          boxShadow: "none",
                                        }),
                                      }}
                                    />
                                  )}
                                  <p className="errorText">{meta.error}</p>
                                </>
                              )}
                            </Field>
                            <div
                              style={{
                                display: "flex",
                                width: 260,
                                justifyContent: "space-between",
                                gap: 20,
                                marginBottom: -50,
                              }}
                            >
                              <label
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                Days:
                                <input
                                  type="number"
                                  className="form-control mt10"
                                  style={{ width: 120 }}
                                  value={days}
                                  onChange={handleDaysChange}
                                  min="0"
                                />
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                Hours:
                                <input
                                  type="number"
                                  style={{ width: 120 }}
                                  className="form-control mt10"
                                  value={hours}
                                  onChange={handleHoursChange}
                                  min="0"
                                  max="23"
                                />
                              </label>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: -60,
                              }}
                            >
                              <Field name="chCoin">
                                {({ meta }) => (
                                  <CustomTextInput
                                    label={"Ch coin amount"}
                                    type="number"
                                    error={meta.touched ? meta.error : null}
                                    onChange={(e) => {
                                      handleChange("chCoin")(e);
                                      setCoinCount(e.target.value);
                                    }}
                                    placeHolderText={"amount"}
                                    value={values.chCoin}
                                    onBlur={handleBlur("chCoin")}
                                    showEyeIcon={false}
                                    required={true}
                                  />
                                )}
                              </Field>
                              <Field name="ticketFee">
                                {({ meta }) => (
                                  <CustomTextInput
                                    label={"Ticket Fee"}
                                    type="number"
                                    error={meta.touched ? meta.error : null}
                                    onChange={(e) => {
                                      handleChange("ticketFee")(e);
                                      setFieldValue(
                                        "ticketFee",
                                        e.target.value
                                      );
                                    }}
                                    placeHolderText={"Fee"}
                                    value={values.ticketFee}
                                    onBlur={handleBlur("ticketFee")}
                                    showEyeIcon={false}
                                    required={true}
                                  />
                                )}
                              </Field>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "3%" }}>
                  <div className="col-md-3">
                    <label className="d-flex">Quest Start Date</label>
                    <Field name="startDate">
                      {({ meta }) => (
                        <>
                          <DatePicker
                            showTime
                            showToday={false}
                            className="w-100"
                            name="startDate"
                            onChange={(date) =>
                              handleStartDateChange(date, setFieldValue)
                            }
                            value={values.startDate}
                            inputReadOnly={true}
                            showNow={false}
                            disabledDate={(current) =>
                              !current || current.isBefore(minimumDate)
                            }
                          />
                          {errors.startDate && touched.startDate && (
                            <p className="errorText">{errors.startDate}</p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="col-md-3 d-flex align-items-end justify-content-end">
                    <Button
                      style={{ color: "white" }}
                      className="addbtn border p1060 bgprimary no-hover"
                      size="medium"
                      onClick={() => handleSubmit()}
                      type="primary"
                      ghost
                    >
                      Publish
                    </Button>
                  </div>
                </div>
              </div>
              {values.nameofquest !== "" &&
              gameMode !== "" &&
              // countryName !== "" &&
              // cityName !== "" &&
              (questPrizePool !== "" || values.chCoin) &&
              values?.startDate !== "" ? (
                <div className="row col-12 quest-wrapper">
                  <div className="col-md-12">
                    {questPrizePool || values.chCoin ? (
                      <EditQuestCard
                        open={true}
                        prizePoolListingAllData={prizrPoolOptions}
                        handleQuestTaskData={handleQuestTaskData}
                        setDisableRegion={setDisableRegion}
                        FetchUpdatedTaskArray={FetchUpdatedTaskArray}
                        setEditFieldValue={setFieldValue}
                        // setTaskArray={setTaskArray}
                        formikValues={values}
                        // editQuestCards={editQuestCards}
                        modeOfQuest={values.modeofquest}
                      />
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </Formik>
      </Spin>
      <div className="total-time-footer">
        <div>
          {" "}
          <span className="total-time-heading">Total Time:</span>{" "}
          <span className="total-time">
            {convertMinutesToHours(questTotalTime)}
          </span>{" "}
        </div>
      </div>
    </>
  );
};

export default CreateNewQuest;

const LoadingIndicator = () => (
  <div
    style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#78633A",
      textAlign: "center",
    }}
  >
    <Spin size="medium" />
    <div style={{ marginTop: "10px" }}>Creating Quest...</div>
  </div>
);
