import { Pagination, Table } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import AddAnnouncement from "./AddAnnouncement";
import EditAnnouncement from "./EditAddnouncement";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { types } from "../../../../store/action_types";
import {
  EditIcon,
  PlusIcon,
  DeleteIcon,
} from "../../../../SharedComponents/icons/icons";

const { confirm } = Modal;
const Announcement = () => {
  const dispatch = useDispatch();

  const [editRecordData, setEditRecordData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch({
      type: types.AnnouncementListingRequest,
      payload: 1,
    });
  }, []);

  const {
    announcementListingAllData,
    createAnnouncementModalOpen,
    editAnnouncementModalOpen,
  } = useSelector((state) => ({
    announcementListingAllData:
      state.AnnouncementReducer.announcementListingAllData?.result
        ?.announcements,
    createAnnouncementModalOpen:
      state.AnnouncementReducer.createAnnouncementModalOpen,
    editAnnouncementModalOpen:
      state.AnnouncementReducer.editAnnouncementModalOpen,
  }));

  const calculateSRNumber = (currentPage, index) => {
    // Calculate the SR# based on the current page and index
    return (currentPage - 1) * 10 + (index + 1);
  };
  const columns = [
    {
      title: "SR#",
      dataIndex: "_id",
      key: "_id",
      width: "20%",

      render: (value, item, index) => (
        <>
          <div className="table-column-sr-no">
            {calculateSRNumber(currentPage, index)}
          </div>
        </>
      ),
    },
    {
      title: "Announcement Name",
      dataIndex: "title",
      key: "title",
      width: "20%",
      render: (item) => <div className="table-column-body-data">{item}</div>,
    },
    {
      title: "Expiry",
      dataIndex: "expiryDate",
      key: "expiryDate",
      width: "20%",
      render: (expirydate) => (
        <div className="carddate">
          {" "}
          {moment(expirydate).format("YYYY-MM-DD")}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "20%",
      render: (value, item, index) => (
        <>
          <p>{item.status === "publish" ? "Publish" : "Un-Publish"}</p>
          {/* <Switch checked={item.status == "publish" ? true : false} disabled /> */}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      width: "20%",
      key: "x",
      render: (item) => (
        <div className="d-flex ">
          <div onClick={() => handlDeleteRecord(item)} className="cursor">
            {" "}
            <DeleteIcon />
          </div>

          <div onClick={() => handleEditRecord(item)} className="ml10 cursor">
            {" "}
            <EditIcon />
          </div>
        </div>
      ),
    },
  ];

  const handleEditRecord = (item) => {
    setEditRecordData(item);
    dispatch({
      type: types.EditAnnouncementOpenModalRequest,
    });
  };
  const handlDeleteRecord = (item) => {
    confirm({
      title: "Delete Announcement",
      content: "Are you sure to delete this Announcement?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        let data = {
          id: item._id,
          currentPage: currentPage,
        };

        dispatch({
          type: types.AnnouncementDeleteRequest,
          payload: data,
          // payload: item._id,
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onChangeAnnouncementPagination = (page) => {
    setCurrentPage(page);
    dispatch({
      type: types.AnnouncementListingRequest,
      payload: page,
    });
  };

  const showAddAnnouncementModal = () => {
    dispatch({
      type: types.CreateAnnouncementOpenModalRequest,
    });
  };
  const handleCloseAddAnnouncementModal = () => {
    console.log("closing called");
    dispatch({
      type: types.CreateAnnouncementCloseModalRequest,
    });
  };

  const handleCloseEditAnnouncementModal = () => {
    setEditRecordData("");
    dispatch({
      type: types.EditAnnouncementCloseModalRequest,
    });
  };

  return (
    <>
      <div className="d-flex  align-items-baseline mb-3">
        <div>
          <div className="addbtn" onClick={showAddAnnouncementModal}>
            {" "}
            Add Announcement <PlusIcon />{" "}
          </div>
        </div>
      </div>

      <Table
        pagination={false}
        columns={columns}
        dataSource={announcementListingAllData?.docs}
      />

      <div
        className="d-flex justify-content-end mt20"
        // style={{ backgroundColor: "red" }}
      >
        <Pagination
          current={currentPage}
          pageSize={10}
          total={announcementListingAllData?.totalDocs}
          onChange={(page) => onChangeAnnouncementPagination(page)}
          showSizeChanger={false}
        />
      </div>

      {/************************   Add Announcement Modal ***************** */}
      <Modal
        title={false}
        footer={false}
        open={createAnnouncementModalOpen}
        onCancel={handleCloseAddAnnouncementModal}
        width={800}
      >
        <AddAnnouncement
          handleCloseAddAnnouncementModal={handleCloseAddAnnouncementModal}
          onChangeAnnouncementPagination={onChangeAnnouncementPagination}
        />
      </Modal>

      {/************************   Edit Announcement Modal ***************** */}
      <Modal
        title={false}
        footer={false}
        open={editAnnouncementModalOpen}
        onCancel={handleCloseEditAnnouncementModal}
        width={800}
      >
        <EditAnnouncement
          handleCloseEditAnnouncementModal={handleCloseEditAnnouncementModal}
          onChangeAnnouncementPagination={onChangeAnnouncementPagination}
          editRecordData={editRecordData}
          currentPage={currentPage}
          key={editRecordData?._id}
        />
      </Modal>
    </>
  );
};
export default Announcement;
