import { NavLink, useNavigate } from "react-router-dom";
import CustomTextInput from "../../../SharedComponents/CustomTextInput";
import { Field, Formik } from "formik";
import { Constants } from "../../../Constants";
import { types } from "../../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import CryptoLogo from "../../../../Assets/Logo/logo.png";

const Otp = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { Otploading, authUserEmail, authUserPassword } = useSelector(
    (state) => ({
      Otploading: state.GlobalReducer.Otploading,
      authUserEmail: state.AuthReducer.authUserEmail,
      authUserPassword: state.AuthReducer.authUserPassword,
    })
  );

  return (
    <>
      <div className="d-flex justify-content-center align-items-center h100vh">
        <div className="text-center">
          <div className=" ">
            <img src={CryptoLogo} />
            {/* <p className="login-heading ">Thank you for the information you provided about the connection</p> */}
          </div>
          <div className="d-flex justify-content-center">
            <Formik
              initialValues={{
                otp: "",
              }}
              onSubmit={(values) => {
                let data = {
                  otpStateData: {
                    nonce: values?.otp,
                    email: authUserEmail,
                    password: authUserPassword,
                  },
                  history: history,
                };
                dispatch({
                  type: types.OtpRequest,
                  payload: data,
                });
              }}
              validationSchema={Constants.OtpSchema}
            >
              {({ handleChange, handleBlur, handleSubmit, values }) => (
                <div>
                  <div className="row mt40">
                    <div className="col-12 col-sm-12 col-md-12">
                      <Field name="otp">
                        {({ meta }) => (
                          <CustomTextInput
                            label={"OTP"}
                            type="text"
                            error={meta.touched ? meta.error : null}
                            onChange={handleChange("otp")}
                            placeHolderText={"Enter Your OTP"}
                            value={values.otp}
                            onBlur={handleBlur("otp")}
                            maxLength={6}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                console.log("enter pressed");
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="col-12 col-sm-12 mt16 col-md-12 d-flex justify-content-center">
                      <button
                        type="submit"
                        className="parimarybtn"
                        onClick={() => handleSubmit()}
                      >
                        {Otploading ? (
                          <div className="whitespinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                          </div>
                        ) : (
                          <>Login </>
                        )}
                      </button>
                    </div>
                  </div>
                  <p className="text-center t-grey-200 fs14  mt16">
                    {" "}
                    Go back to{" "}
                    <NavLink className="text-decoration-none" to="/">
                      {/* <span className="sub-text fs16 ">
                <b> Sign in</b>
              </span> */}
                      <span className="ligh-primary">
                        {" "}
                        <b>Sign in</b>
                      </span>
                    </NavLink>
                    .
                  </p>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
