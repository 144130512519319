import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Login from "../Screens/AuthDeatils/Login";
import ForgetPassword from "../Screens/AuthDeatils/ForgotPassword";
import CreateNewPassword from "../Screens/AuthDeatils/CreateNewPassword";
import Otp from "../Screens/AuthDeatils/OtpScreen";
import Sidebar from "../Screens/LandingScreens/Sidebar";
// import WalletDetailsTable from "../SharedComponents/WalletDetailsTable";
// import WalletDetails from "../Screens/LandingScreens/Wallet/WalletDetails.js";
// import Reports from "../Screens/LandingScreens/Reports/index.js";
// import Wallet from "../Screens/LandingScreens/Wallet";
// import ProtectedRoutes from "./ProtectedRoutes";

const MainComponent = () => {
  // const token = window.sessionStorage?.getItem("token");

  return (
    <>
      <>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/create-new-password" element={<CreateNewPassword />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/*" element={<Sidebar />} />
          {/* <Route path="/wallet" element={<Wallet />} /> */}
          {/* <Route path="/wallet-table" element={<WalletDetailsTable />} /> */}
          {/* <Route path="/wallet-details" element={<WalletDetails />} /> */}
          {/* <Route path="/reports" element={<Reports />} /> */}

          {/* <Route element={<ProtectedRoutes />}>
         
          </Route> */}
        </Routes>

        {/* {token && window.sessionStorage.getItem("showSidebar") === "true" ? (
          <Sidebar />
        )
        :(<Navigate to={"/"}/>)} */}
      </>
    </>
  );
};

export default MainComponent;
