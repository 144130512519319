import React from "react";
import { Card, Row, Col, Statistic } from "antd";
import ShoppingImage from "../../../Assets/icons/ShoppingOutlined.png";
import CreditCardImage from "../../../Assets/icons/CreditCardOutlined.png";
import TeamImage from "../../../Assets/icons/TeamOutlined.png";

export default function StatisticCard({ data }) {

  const cardStyle = {
    width: "100%",
    height: "100%",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
  };

  const imageContainerStyle = {
    width: "48px",
    height: "48px",
    overflow: "hidden",
    marginRight: "16px",
  };

  const statisticStyle = {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

  };

  const titleStyle = {
    fontSize: '0.95rem',
    lineHeight: '2.5rem',
    minHeight: '1.2rem',
  };

  const valueStyle = {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "0.9rem",
    lineHeight: '2.5rem',
    minHeight: '1.2rem',
  };

  if (!data || data.length === 0) {
    return null;
  }

  const formatCurrency = (value, title) => {
    if (typeof value !== 'number') {
      return title === "Total Users" ? value.toString() : '$0.00';
    }
    return title === "Total Users" ? value.toString() : new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  const cards = [
    {
      title: "Total Payments",
      value: formatCurrency(data[0].value, "Total Payments"),
      image: ShoppingImage,
    },
    {
      title: "Total Money For Boost",
      value: formatCurrency(data[1].value, "Total Money For Boost"),
      image: CreditCardImage,
    },
    {
      title: "Total Users",
      value: formatCurrency(data[2].value, "Total Users"),
      image: TeamImage,
    },
  ];


  return (
    <div style={{ padding: "0px" }}>
      <Row gutter={16} style={{ width: "100%" }}>
        {cards.map((card, index) =>
        (
          <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
            <Card style={cardStyle}>
              <div style={imageContainerStyle}>
                <img src={card.image} alt={card.title} style={{ width: "100%" }} />
              </div>
              <div style={statisticStyle}>
                <Statistic
                  title={<div style={titleStyle}>{card.title}</div>}
                  value={card.value}
                  valueStyle={valueStyle}
                />
              </div>
            </Card>
          </Col>
        )
        )}
      </Row>
    </div>
  );
}