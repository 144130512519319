import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { DELETE, GET, POST, PUT } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";
const token = window.sessionStorage.getItem("token");

console.log("token", token);

function* GetAllAnnouncementListingSaga(pageNum) {
  try {
    const announcementListingAllData_response = yield GET(
      `admin/announcement/all?page=${pageNum?.payload}&limit=10`,
      // `admin/announcement/all?page=${pageNum?.payload}&limit=${pageNum?.payload * 10} `,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responsedata = { ...announcementListingAllData_response?.data };

    if (announcementListingAllData_response?.status === 200) {
      yield put({
        type: types.AnnouncementListingRequestSuccess,
        payload: responsedata,
      });
    } else {
      yield put({ type: types.AnnouncementListingRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.AnnouncementListingRequestFailure });
  }
}

function* CreateAnnouncementSaga({ payload }) {
  const {
    createAnnouncementData,
    reset,
    setLoading,
    onChangeAnnouncementPagination,
  } = payload;

  try {
    const announcementCreationData_response = yield POST(
      "admin/announcement/create",
      createAnnouncementData
    );

    let announcementCreationresponsedata = {
      ...announcementCreationData_response?.data,
    };

    if (announcementCreationData_response?.status === 200) {
      yield put({
        type: types.AnnouncementCreationRequestSuccess,
        payload: announcementCreationresponsedata,
      });

      yield put({
        type: types.AnnouncementListingRequest,
      });

      CustomToster({
        type: "success",
        message: announcementCreationresponsedata?.result?.message,
      });
      // window.location.reload();
      reset();
      onChangeAnnouncementPagination(1);
    } else {
      yield put({ type: types.AnnouncementCreationRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.AnnouncementCreationRequestFailure });
  }
  setLoading(false);
}

function* UpdateAnnouncementSaga({ payload }) {
  const { updateAnnouncementData, currentPage, setLoading } = payload;

  try {
    const announcementUpdateData_response = yield PUT(
      "admin/announcement/update",
      updateAnnouncementData
    );

    let announcementUpdateresponsedata = {
      ...announcementUpdateData_response?.data,
    };

    if (announcementUpdateData_response?.status === 200) {
      yield put({
        type: types.AnnouncementUpdateRequestSuccess,
        payload: announcementUpdateresponsedata,
      });
      yield put({
        type: types.AnnouncementListingRequest,
        payload: currentPage,
      });
      CustomToster({
        type: "success",
        message: "Your Record Updated Successfully",
      });
      setLoading(false);
    } else {
      yield put({ type: types.AnnouncementUpdateRequestFailure });
      setLoading(false);
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.AnnouncementUpdateRequestFailure });
    setLoading(false);
  }
}

function* DeleteAnnouncementSaga({ payload }) {
  const { id, currentPage } = payload;

  try {
    const announcementDeleteData_response = yield DELETE(
      `admin/announcement/${id}`
    );

    let announcementDeleteresponsedata = {
      ...announcementDeleteData_response?.data,
    };

    if (announcementDeleteData_response?.status === 200) {
      yield put({
        type: types.AnnouncementDeleteRequestSuccess,
        payload: announcementDeleteresponsedata,
      });
      yield put({
        type: types.AnnouncementListingRequest,
        payload: currentPage,
      });
      CustomToster({
        type: "success",
        message: "Your Record Deleted Successfully",
      });
    } else {
      yield put({ type: types.AnnouncementDeleteRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.AnnouncementDeleteRequestFailure });
  }
}

export default function* AnnouncementSaga() {
  yield takeLatest(
    types.AnnouncementListingRequest,
    GetAllAnnouncementListingSaga
  );
  yield takeLatest(types.AnnouncementCreationRequest, CreateAnnouncementSaga);
  yield takeLatest(types.AnnouncementUpdateRequest, UpdateAnnouncementSaga);
  yield takeLatest(types.AnnouncementDeleteRequest, DeleteAnnouncementSaga);
}
