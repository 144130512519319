import { types } from "../action_types";

const INITIAL_STATE = {
  allWalletsLoading: false,
  allWalletsData: [],
  walletDetailsLoading: false,
  walletDetailsData: [],
  statsLoading: false,
  statsData: null,
  statsError: null,
};  

const GameWalletsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GetAllWalletsRequest: {
      return { ...state, allWalletsLoading: true };
    }
    case types.GetAllWalletsSuccess:
      return { ...state, allWalletsData: payload, allWalletsLoading: false };
    case types.GetAllWalletsFailure:
      return { ...state, allWalletsLoading: false };

    case types.GetWalletDetailsRequest:
      return { ...state, walletDetailsLoading: true };
    case types.GetWalletDetailsSuccess:
      return { ...state, walletDetailsData: payload, walletDetailsLoading: false };
    case types.GetWalletDetailsFailure:
      return { ...state, walletDetailsLoading: false };

    case types.GetStatsRequest:
      return { ...state, statsLoading: true, statsError: null };
    case types.GetStatsSuccess:
      return { ...state, statsData: payload, statsLoading: false, statsError: null };
    case types.GetStatsFailure:
      return { ...state, statsData: null, statsLoading: false, statsError: payload };

    default:
      return state;
  }
};

export default GameWalletsReducer;
