import { types } from '../action_types';

const INITIAL_STATE = {
    loading:false,
    userDetailData:null,
    Otploading:false,
}

const GlobalReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        // Otp Request for Login
        case types.OtpRequest:
            return { ...state, Otploading: true }
            case types.OtpRequestSuccess:
                return { ...state,userDetailData:payload, Otploading: false }
                case types.OtpRequestFailure:
                    return { ...state, Otploading: false }

       
        //other
        case types.LOADER:
            return { ...state, loading: payload }

        default:
            return state;
    }
}
export default GlobalReducer;