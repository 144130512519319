import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { GET } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";

const token = window.sessionStorage.getItem("token");

function* GetAllWalletsSaga(action) {
  const { page, pageSize, fromDate, toDate } = action.payload;
  const actualFromDate = fromDate || "";
  const actualToDate = toDate || "";

  try {
    const allWalletsResponse = yield GET(
      `admin/transaction/all?page=${page}&limit=${pageSize}&from=${actualFromDate}&to=${actualToDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responseData = { ...allWalletsResponse?.data };

    if (allWalletsResponse?.status === 200) {
      yield put({
        type: types.GetAllWalletsSuccess,
        payload: responseData,
      });
    } else {
      yield put({ type: types.GetAllWalletsFailure });
    }
  } catch (e) {
    console.log("Error in Saga", e);
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.GetAllWalletsFailure });
  }
}

function* GetWalletDetailsSaga(action) {
  const pageSize = 10;
  const { page, fromDate, toDate } = action.payload;
  const id = action.payload.userId;
  console.log("id", id);
  const actualFromDate = fromDate || "";
  const actualToDate = toDate || "";
  try {
    const walletDetailsResponse = yield GET(
      `admin/transaction/user/${id}?page=${page}&limit=${pageSize}&from=${actualFromDate}&to=${actualToDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responseData = { ...walletDetailsResponse?.data };

    if (walletDetailsResponse?.status === 200) {
      yield put({
        type: types.GetWalletDetailsSuccess,
        payload: responseData,
      });
    } else {
      yield put({ type: types.GetWalletDetailsFailure });
    }
  } catch (e) {
    console.log("Error in Saga", e);
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.GetWalletDetailsFailure });
  } finally {
    yield put({ type: types.ResetAllWalletsLoading });
  }
}

export default function* GameWalletsSaga() {
  yield takeLatest(types.GetAllWalletsRequest, GetAllWalletsSaga);
  yield takeLatest(types.GetWalletDetailsRequest, GetWalletDetailsSaga);
}
