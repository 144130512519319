import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { DELETE, GET, POST, PUT } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";
const token = window.sessionStorage.getItem("token");

function* GetAllTaskSaga(action) {
  const { payload } = action;
  const { page, searchText } = payload;
  const actualSearchText = searchText || "";

  // const actualCountrySearchText = countrySearchText || '';
  // const actualCitySearchText = citySearchText || '';

  try {
    const all_task_response = yield GET(
      `admin/task/all?page=${page}&limit=10&name=${actualSearchText}`,
      // `admin/task/all?${page?.payload}&limit=${page * 10}`,

      // `admin/announcement/all?page=${pageNum?.payload}&limit=${pageNum?.payload * 10} `,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responsedata = { ...all_task_response?.data };

    if (all_task_response?.status === 200) {
      yield put({
        type: types.GetAllTaskRequestSuccess,
        payload: { responsedata, actualSearchText },
      });

      //   CustomToster({ type: "success", message: responsedata?.result?.message });
    } else {
      yield put({ type: types.GetAllTaskRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.GetAllTaskRequestFailure });
  }
}

function* GetAllTaskWithCountryandCitySaga(data) {
  try {
    const all_task_with_country_and_city_response = yield GET(
      // `admin/task/all?page=${pageNum?.payload}&limit=10`,
      `admin/task/all?page=${data?.payload?.pageNum}&limit=10&countryCode=${data?.payload?.countryCode}&city=${data?.payload?.city}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responsedata = { ...all_task_with_country_and_city_response?.data };

    if (all_task_with_country_and_city_response?.status === 200) {
      yield put({
        type: types.GetAllTaskWithCountryandCityRequestSuccess,
        payload: responsedata,
      });

      //   CustomToster({ type: "success", message: responsedata?.result?.message });
    } else {
      yield put({ type: types.GetAllTaskWithCountryandCityRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.GetAllTaskWithCountryandCityRequestFailure });
  }
}

function* CreateTaskSaga({ payload }) {
  const { createTaskData, history, setErrorSimilarTitle } = payload;

  try {
    const taskCreationData_response = yield POST(
      "admin/task/create",
      createTaskData
    );

    let taskCreationresponsedata = {
      ...taskCreationData_response?.data,
    };

    if (taskCreationData_response?.status === 200) {
      yield put({
        type: types.GetAllTaskRequest,
        payload: 1,
      });

      yield put({
        type: types.CreateTaskRequestSuccess,
        payload: taskCreationresponsedata,
      });
      CustomToster({
        type: "success",
        message: taskCreationresponsedata?.result?.message,
      });
      // history('/tasks-list')
      // window.location.replace("/tasks-list");
      // window.location.reload()
    } else {
      yield put({ type: types.CreateTaskRequestFailure });
    }
  } catch (e) {
    if (
      e?.response?.data?.result?.details?.MESSAGE ===
        "Task name is already in use" &&
      e?.response?.data?.result?.STATUS === 400
    ) {
      setErrorSimilarTitle(true);
    }

    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.CreateTaskRequestFailure });
  }
}

function* DeleteTaskSaga({ payload }) {
  try {
    const taskDeleteData_response = yield DELETE(`admin/task/${payload}`);

    let taskDeleteresponsedata = {
      ...taskDeleteData_response?.data,
    };

    if (taskDeleteData_response?.status === 200) {
      yield put({
        type: types.TaskDeleteRequestSuccess,
        payload: taskDeleteresponsedata,
      });
      yield put({
        type: types.GetAllTaskRequest,
      });
      CustomToster({
        type: "success",
        message: "Your Record Deleted Successfully",
      });
    } else {
      yield put({ type: types.TaskDeleteRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.TaskDeleteRequestFailure });
  }
}

function* UpdateTaskSaga({ payload }) {
  const { updateTaskData, currentPage, setErrorSimilarTitle } = payload;
  try {
    const taskUpdateData_response = yield PUT(
      "admin/task/update",
      updateTaskData
    );

    let taskUpdateresponsedata = {
      ...taskUpdateData_response?.data,
    };

    if (taskUpdateData_response?.status === 200) {
      yield put({
        type: types.TaskUpdateRequestSuccess,
        payload: taskUpdateresponsedata,
      });
      yield put({
        type: types.GetAllTaskRequest,
        payload: currentPage,
      });
      CustomToster({
        type: "success",
        message: "Your Record Updated Successfully",
      });
    } else {
      yield put({ type: types.TaskUpdateRequestFailure });
    }
  } catch (e) {
    if (
      e?.response?.data?.result?.details?.MESSAGE ===
        "Cannot update a task that is part of an Active quest" &&
      e?.response?.data?.result?.STATUS === 400
    ) {
      setErrorSimilarTitle(true);
    }
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.TaskUpdateRequestFailure });
  }
}

export default function* TaskSaga() {
  yield takeLatest(types.GetAllTaskRequest, GetAllTaskSaga);
  yield takeLatest(
    types.GetAllTaskWithCountryandCityRequest,
    GetAllTaskWithCountryandCitySaga
  );
  yield takeLatest(types.CreateTaskRequest, CreateTaskSaga);
  yield takeLatest(types.TaskDeleteRequest, DeleteTaskSaga);
  yield takeLatest(types.TaskUpdateRequest, UpdateTaskSaga);
}
