import * as Yup from "yup";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const otpRegExp = /^[0-9]+$/;
const xpRegExp = /^[0-9]+$/;
const boostPriceRegExp = /^(?!0)\d{1,6}$/;

const characterandnumberRegExp = /^(?!0)[A-Za-z0-9]/;

const Constants = {
  LoginSchema: Yup.object().shape({
    email: Yup.string().email().required("Enter Your Email"),
    password: Yup.string().required("Enter Your Password"),
  }),

  OtpSchema: Yup.object().shape({
    otp: Yup.string()
      .required("Required")
      .matches(otpRegExp, "OTP is not valid"),
  }),
  MobileSchema: Yup.object().shape({
    num: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Phone number is not valid"),
  }),
  ForgotSchema: Yup.object().shape({
    email: Yup.string().email().required("Required"),
  }),
  AddVotesSchema: Yup.object().shape({
    votesname: Yup.string()
      .required("Title is Required")
      .matches(characterandnumberRegExp, "Title is Not Valid"),
    firstOption: Yup.string()
      .required("First Option is Required")
      .matches(characterandnumberRegExp, "First Option is Not Valid"),
    secondOption: Yup.string()
      .required("Second Option Required")
      .matches(characterandnumberRegExp, "Second Option is Not Valid"),
    thirdOption: Yup.string()
      .required("Third Option Required")
      .matches(characterandnumberRegExp, "Third Option is Not Valid"),
    fourthOption: Yup.string()
      .required("Fourth Option Required")
      .matches(characterandnumberRegExp, "Fourth Option is Not Valid"),

    startdate: Yup.string().required("Start Date is Required"),
    enddate: Yup.string().required("End Date is Required"),
  }),
  VotesUpdateSchema: Yup.object().shape({
    votesname: Yup.string()
      .required("Title is Required")
      .matches(characterandnumberRegExp, "Title is Not Valid"),
    firstOption: Yup.string()
      .required("First Option is Required")
      .matches(characterandnumberRegExp, "First Option is Not Valid"),
    secondOption: Yup.string()
      .required("Second Option Required")
      .matches(characterandnumberRegExp, "Second Option is Not Valid"),
    thirdOption: Yup.string()
      .required("Third Option Required")
      .matches(characterandnumberRegExp, "Third Option is Not Valid"),
    fourthOption: Yup.string()
      .required("Fourth Option Required")
      .matches(characterandnumberRegExp, "Fourth Option is Not Valid"),

    startdate: Yup.string().required("Start Date is Required"),
    enddate: Yup.string().required("End Date is Required"),
  }),

  TaskSchema: Yup.object().shape({
    xp: Yup.string()
      .required("XP is Required")
      .matches(xpRegExp, "XP should be only Numbers"),
    hint: Yup.string().required("Hint is Required"),
    secondHintText: Yup.string(),
    title: Yup.string()
      .required("Task Name is Required")
      .matches(characterandnumberRegExp, "Task Name is required"),
    countryCode: Yup.string().required("Country is Required"),
    city: Yup.string().required("City is Required"),
    TaskTypeIndex: Yup.number()
      .required("Task Type is Required")
      .min(0, "Task Type is Required"),
    RepresentatationOfTaskIndex: Yup.number()
      .required("Representation of Task is Required")
      .min(0, "Representation of Task is Required"),
    latitude: Yup.string().required("Latitude is Required"),
    longitude: Yup.string().required("Longitude is Required"),
  }),

  // QuestSchema: Yup.object().shape({
  //   nameofquest: Yup.string()
  //     .required("Quest Name is Required")
  //     .matches(characterandnumberRegExp, "Quest Name Required"),
  //   chCoin: Yup.number()
  //     .required("Coin amount is Required")
  //     .typeError("Coin amount must be a number"),
  //   ticketFee: Yup.number()
  //     .required("Ticket Fee is Required")
  //     .typeError("Ticket Fee must be a number"),
  //   // countryofquest:Yup.string().required("Country is Required"),
  //   // cityofquest:Yup.string().required("City is Required"),

  //   modeofquest: Yup.string().required("Game Mode is Required"),
  //   startDate: Yup.string().required("Quest Start Date is Required"),
  //   // questprizepool:Yup.string().required("Prize Pool is Required"),
  //   selectedCity: Yup.string().required("City is required"),
  //   selectedCountry: Yup.string().required("Country is required"),
  //   startDate: Yup.date()
  //     .required("Quest Start Date is required")
  //     .test("is-future", "Quest Start Date must be at least 5 minutes in the future", function (value) {
  //       const fiveMinutesLater = new Date();
  //       fiveMinutesLater.setMinutes(fiveMinutesLater.getMinutes() + 5);
  //       return new Date(value) > fiveMinutesLater;
  //     }),

  // })
  //   .when('modeofquest', {
  //     is: "free-to-play",
  //     then: Yup.object().shape({
  //       chCoin: Yup.number().notRequired(),
  //       ticketFee: Yup.number().notRequired()
  //     }),
  //     otherwise: Yup.object().shape({
  //       chCoin: Yup.number()
  //         .required("Coin amount is Required")
  //         .typeError("Coin amount must be a number")
  //         .min(1, "Coin amount cannot be 0"),
  //       ticketFee: Yup.number()
  //         .required("Ticket Fee is Required")
  //         .typeError("Ticket Fee must be a number"),
  //     })
  //   }),

  QuestGameModeSchema: Yup.object().shape({
    nameofquest: Yup.string()
      .required("Quest Name is Required")
      .matches(
        /^(?!0)[A-Za-z0-9 ]+$/,
        "Quest Name must start with a non-zero and contain only alphanumeric characters"
      ),
    modeofquest: Yup.string().required("Game Mode is Required"),
    selectedCity: Yup.string().required("City is required"),
    selectedCountry: Yup.string().required("Country is required"),
    startDate: Yup.date()
      .required("Quest Start Date is required")
      .test(
        "is-future-date",
        "Quest Start Date must be at least 2 minutes in the future",
        function (value) {
          const now = new Date();
          const inputDate = new Date(value);
          const twoMinutesLater = new Date(now.getTime() + 2 * 60000);
          return inputDate > twoMinutesLater;
        }
      ),
    chCoin: Yup.number()
      .when("modeofquest", (modeofquest, schema) =>
        modeofquest !== "free-to-play"
          ? schema.min(0, "Coin amount must be greater than 0")
          : schema.notRequired()
      )
      .nullable(true),
    ticketFee: Yup.number()
      .when("modeofquest", (modeofquest, schema) =>
        modeofquest !== "free-to-play"
          ? schema.min(0, "Ticket Fee must be greater than 0")
          : schema.notRequired()
      )
      .nullable(true),
    // taskTime: Yup.number()
    //   .when("modeofquest", (modeofquest, schema) =>
    //     modeofquest !== "free-to-play"
    //       ? schema.min(1, "Task Time must be greater than 0")
    //       : schema.notRequired()
    //   )
    //   .nullable(true),
    prizePool: Yup.string().when("modeofquest", (modeofquest, schema) =>
      modeofquest !== "free-to-play"
        ? schema.required("Prize Pool is required")
        : schema.notRequired()
    ),
  }),

  AddBoostSchema: Yup.object().shape({
    name: Yup.string(),
    // .required("Name is required")
    // .matches(characterandnumberRegExp, "Name should contain A-Z"),
    price: Yup.string()
      .required("Price is required")
      .matches(boostPriceRegExp, "Price should be 1 - 999999"),
    image: Yup.string().required("Image is required"),
    description: Yup.string().required("Description is required"),
  }),

  AddCompanySchema: Yup.object().shape({
    name: Yup.string().required("Name is required"),
    info: Yup.string().required("Info is required"),
    image: Yup.string().required("Image is required"),
  }),

  AddPoolSchema: Yup.object().shape({
    info: Yup.string().required("Info is required"),
    image: Yup.string().required("Image is required"),
    radius: Yup.number()
      .required("Radius is Required")
      .min(0, "Radius is Required")
      .max(100, "Max value is 100"),
    totalProducts: Yup.number()
      .required("Total Products is Required")
      .min(0, "Total Products is Required")
      .max(100, "Max value is 100"),
    rewardQuantity: Yup.number()
      .required("Reward Quantity is Required")
      .min(0, "Reward Quantity is Required"),
  }),

  EditPoolSchema: Yup.object().shape({
    info: Yup.string().required("Info is required"),
    radius: Yup.number()
      .required("Radius is Required")
      .min(0, "Radius is Required")
      .max(100, "Max value is 100"),

    totalProducts: Yup.number()
      .required("Total Products is Required")
      .min(0, "Total Products is Required")
      .max(100, "Max value is 100"),
    rewardQuantity: Yup.number()
      .required("Reward Quantity is Required")
      .min(0, "Reward Quantity is Required"),
  }),

  AddAdminSchema: Yup.object().shape({
    firstName: Yup.string().required("Enter Your First Name"),
    secondName: Yup.string().required("Enter Your Second Name"),
    email: Yup.string().email().required("Enter Your Email"),
    password: Yup.string().required("Enter Your Password"),
  }),

  PrizePoolSchema: Yup.object().shape({
    companyName: Yup.string()
      .required("Company Name is Required")
      .matches(characterandnumberRegExp, "Company Name is Required"),
  }),
  AnnouncementSchema: Yup.object().shape({
    announcement: Yup.string()
      .required("Announcement Title is Required")
      .matches(characterandnumberRegExp, "Announcement Title is Required"),
    expirydate: Yup.string().required("Expiry Date is Required"),
    description: Yup.string().required("Description is Required"),
  }),
};
const BASEURL = process.env.REACT_APP_BASE_URL;
// const BASEURL = 'https://ecommercelive.riolabz.com/api/v1/';
export { Constants, BASEURL };
