import axios from "axios";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react-18-support";
import React, { useState, useEffect } from "react";

const GoogleMapComponent = (props) => {
  const [markers, setMarkers] = useState([
    {
      title: "The marker`s title will appear as a tooltip.",
      name: "SOMA",
      position: { lat: props?.latUpdate, lng: props?.longUpdate },
    },
  ]);

  const [mapCenter, setMapCenter] = useState({
    lat: props?.latUpdate,
    lng: props?.longUpdate,
  });

  useEffect(() => {
    if (props?.latUpdate && props?.longUpdate) {
      getCityAndCountry(props?.latUpdate, props?.longUpdate);
      setMarkers([
        {
          title: "",
          name: "",
          position: { lat: props?.latUpdate, lng: props?.longUpdate },
        },
      ]);
      setMapCenter({
        lat: props?.latUpdate,
        lng: props?.longUpdate,
      });
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setMapCenter({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.error("Error fetching geolocation data:", error);
          }
        );
      }
    }
  }, [props.latUpdate, props.longUpdate]);

  const onClick = (t, map, coord) => {
    props.handleClear();
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    getCityAndCountry(lat, lng);

    setMarkers([
      {
        title: "",
        name: "",
        position: { lat, lng },
      },
    ]);
    setMapCenter({
      lat,
      lng,
    });
  };

  const getCityAndCountry = async (lat, long) => {
    console.log("calling");
    try {
      const {
        data: { results },
      } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyAQPJobaSibKj0ukXQag1aRYn9Kpck9MKw&language=en`
      );

      const { address_components } = results[0];
      console.log("address_components", address_components);

      let countryFilterResult = address_components.find((x) =>
        x.types.includes("country")
      );

      let cityFilterResult = address_components.find(
        (x) =>
          x.types.includes("locality") ||
          x.types.includes("administrative_area_level_2") ||
          x.types.includes("administrative_area_level_1")
      );

      let data = {
        country: countryFilterResult ? countryFilterResult.long_name : "",
        countryCode: countryFilterResult ? countryFilterResult.short_name : "",
        city: cityFilterResult ? cityFilterResult.long_name : "",
        longitude: long,
        latitude: lat,
      };
      props.getCityCountryVal(data);
    } catch (error) {
      console.error("Error fetching geolocation data:", error);
    }
  };

  return (
    <div>
      <Map
        streetViewControl={false}
        google={props.google}
        style={{
          width: props.width ? `${props.width}px` : "100%",
          height: props.height ? `${props.height}px` : "200px",
        }}
        containerStyle={{
          width: "39%",
          height: "29%",
          position: "absolute",
        }}
        zoom={10}
        initialCenter={{
          lat: props?.latUpdate,
          lng: props?.longUpdate,
        }}
        center={mapCenter}
        onClick={onClick}
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            title={marker.title}
            name={marker.name}
            position={marker.position}
          />
        ))}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAQPJobaSibKj0ukXQag1aRYn9Kpck9MKw",
})(GoogleMapComponent);
