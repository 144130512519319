import { types } from "../action_types";

const INITIAL_STATE = {
  companiesLoading: false,
  poolsLoading: false,
  productsLoading: false,
  createCompanyLoading: false,
  editCompanyLoading: false,
  deleteCompanyLoading: false,
  createPoolLoading: false,
  editPoolLoading: false,
  deletePoolLoading: false,
  editProductsLoading: false,
  companies: null,
  pools: null,
  products: null,
  pagination: null,
};

const CompaniesManagementReducer = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case types.GetCompaniesRequest:
      return { ...state, companiesLoading: true };
    case types.GetCompaniesRequestSuccess:
      return {
        ...state,
        companies: payload.companies,
        pagination: payload.pagination,
        companiesLoading: false,
      };
    case types.GetCompaniesRequestFailure:
      return { ...state, companiesLoading: false };

    case types.CreateCompanyRequest:
      return { ...state, createCompanyLoading: true };
    case types.CreateCompanyRequestSuccess:
      return { ...state, createCompanyLoading: false };
    case types.CreateCompanyRequestFailure:
      return { ...state, createCompanyLoading: false };

    case types.EditCompanyRequest:
      return { ...state, editCompanyLoading: true };
    case types.EditCompanyRequestSuccess:
      return { ...state, editCompanyLoading: false };
    case types.EditCompanyRequestFailure:
      return { ...state, editCompanyLoading: false };

    case types.DeleteCompanyRequest:
      return { ...state, deleteCompanyLoading: true };
    case types.DeleteCompanyRequestSuccess:
      return { ...state, deleteCompanyLoading: false };
    case types.DeleteCompanyRequestFailure:
      return { ...state, deleteCompanyLoading: false };

    case types.GetPoolsRequest:
      return { ...state, poolsLoading: true };
    case types.GetPoolsRequestSuccess:
      return {
        ...state,
        pools: payload.pools,
        pagination: payload.pagination,
        poolsLoading: false,
      };
    case types.GetPoolsRequestFailure:
      return { ...state, poolsLoading: false };

    case types.EditPoolRequest:
      return { ...state, editPoolLoading: true };
    case types.EditPoolRequestSuccess:
      return { ...state, editPoolLoading: false };
    case types.EditPoolRequestFailure:
      return { ...state, editPoolLoading: false };

    case types.CreatePoolRequest:
      return { ...state, createPoolLoading: true };
    case types.CreatePoolRequestSuccess:
      return { ...state, createPoolLoading: false };
    case types.CreatePoolRequestFailure:
      return { ...state, createPoolLoading: false };

    case types.DeletePoolRequest:
      return { ...state, deletePoolLoading: true };
    case types.DeletePoolRequestSuccess:
      return { ...state, deletePoolLoading: false };
    case types.DeletePoolRequestFailure:
      return { ...state, deletePoolLoading: false };

    case types.ClearPoolsArray:
      return { ...state, pools: null };

    case types.GetProductsRequest:
      return { ...state, productsLoading: true };
    case types.GetProductsRequestSuccess:
      return {
        ...state,
        products: payload.products,
        productsLoading: false,
      };
    case types.GetProductsRequestFailure:
      return { ...state, productsLoading: false };

    case types.EditProductsRequest:
      return { ...state, editProductsLoading: true };
    case types.EditProductsRequestSuccess:
      return { ...state, editProductsLoading: false };
    case types.EditProductsRequestFailure:
      return { ...state, editProductsLoading: false };

    case types.ClearProductArray:
      return { ...state, products: null };

    default:
      return state;
  }
};
export default CompaniesManagementReducer;
