import { combineReducers } from "redux";
import { types } from "../action_types";
import GlobalReducer from "./GlobalReducer";
import AuthReducer from "./AuthReducer";
import AnnouncementReducer from "./AnnouncementReducer";
import TaskReducer from "./TaskReducer";
import QuestReducer from "./QuestReducer";
import CollectionReducer from "./CollectionReducer";
import PrizePoolReducer from "./PrizePoolReducer";
import VotesReducer from "./VotesReducer";
import BoostReducer from "./BoostReducer";
import FamilyDataReducer from "./FamilyDataReducer";
import UserReducer from "./UserReducer";
import GameWalletsReducer from "./GameWalletsReducer";
import ReportsReducer from "./ReportsReducer";
import CompaniesManagementReducer from "./CompaniesManagementReducer";
import AdminReducer from "./AdminReducer";

const appReducer = combineReducers({
  GlobalReducer,
  AuthReducer,
  UserReducer,
  AnnouncementReducer,
  TaskReducer,
  QuestReducer,
  CollectionReducer,
  PrizePoolReducer,
  BoostReducer,
  FamilyDataReducer,
  VotesReducer,
  GameWalletsReducer,
  ReportsReducer,
  CompaniesManagementReducer,
  AdminReducer,
});

const rootReducer = (state, action) => {
  if (action.type === types.DESTROY_SESSION) {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
