import React from "react";
import { DatePicker, Switch } from "antd";
import { ErrorMessage, Field, Formik } from "formik";
import moment from "moment";
import CustomTextInput from "../CustomTextInput";
import { Constants } from "../../Constants";
import { types } from "../../store/action_types";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

const AnnouncementCard = ({
  handleCloseModal,
  onChangeAnnouncementPagination,
  setLoading,
  loading,
  editRecordData,
  currentPage,
}) => {
  const minimumDate = dayjs();

  const disabledDate = (current) => {
    return current && current < minimumDate.startOf("day");
  };

  const dateFormat = "YYYY/MM/DD";
  const dispatch = useDispatch();
  return (
    <div>
      <Formik
        initialValues={{
          announcement: editRecordData?.title ?? "",
          actionstatus: editRecordData?.status ?? "",
          description: editRecordData?.description ?? "",
          expirydate: editRecordData?.expiryDate ?? "",
        }}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          setLoading(true);
          let newDate = new Date(values?.expirydate);
          let dateString = moment(newDate).format("YYYY-MM-DD");
          let data;
          if (editRecordData) {
            data = {
              updateAnnouncementData: {
                announcementId: editRecordData?._id,
                title: values.announcement,
                description: values.description,
                expiryDate: dateString,
                status:
                  values?.actionstatus === true ||
                  values?.actionstatus === "publish"
                    ? "publish"
                    : "un-publish",
              },
              currentPage: currentPage,
              setLoading: setLoading,
            };
            dispatch({
              type: types.AnnouncementUpdateRequest,
              payload: data,
            });
          } else {
            data = {
              createAnnouncementData: {
                title: values.announcement,
                description: values.description,
                status:
                  values?.actionstatus === true ? "publish" : "un-publish",
                expiryDate: dateString,
              },
              onChangeAnnouncementPagination: onChangeAnnouncementPagination,
              reset: resetForm,
              setLoading: setLoading,
            };
            dispatch({
              type: types.AnnouncementCreationRequest,
              payload: data,
            });
          }

          // resetForm();
        }}
        validationSchema={Constants.AnnouncementSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          resetForm,
        }) => (
          <div className="add-announcement-modal-wrapper mt40">
            <div className=" ">
              <div className=" mb-3 ">
                <Field name="announcement">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Title"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 60) {
                          handleChange("announcement")(e);
                        }
                      }}
                      placeHolderText={"Announcement"}
                      value={values.announcement}
                      onBlur={handleBlur("announcement")}
                      showEyeIcon={false}
                      disable={loading}
                    />
                  )}
                </Field>
              </div>

              <div className="col-12 col-sm-12 col-md-12 mb-3 ">
                <label className="d-flex">Description</label>
                <textarea
                  disabled={loading}
                  class="form-control w-100 textarea h134"
                  rows="7"
                  onChange={handleChange("description")}
                  id="description"
                  name="description"
                  value={values?.description}
                ></textarea>
                <ErrorMessage name="description">
                  {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
            <div>
              <div className=" mb-3 ">
                <label className="d-flex">Expiry Date</label>
                <Field name="expirydate">
                  {({ meta }) => (
                    <>
                      <DatePicker
                        className="w-100"
                        name="expirydate"
                        showNow={false}
                        showToday={false}
                        onChange={(dateString) => {
                          if (dateString) {
                            setFieldValue("expirydate", dateString);
                          }
                        }}
                        disabledDate={disabledDate}
                        format={dateFormat}
                        value={
                          editRecordData
                            ? dayjs(values.expirydate)
                            : values.expirydate
                        }
                      />
                      <p className="errorText">
                        {" "}
                        {meta.error ? meta.error : null}
                      </p>
                    </>
                  )}
                </Field>
              </div>

              <div className="col-12 col-sm-12 col-md-12  d-flex justify-content-between">
                <div>
                  <label className="d-flex">Actions</label>
                  <div className="action">Publish/UnPublish</div>
                </div>

                <div>
                  <Field name="actionstatus">
                    {({ meta }) => (
                      <Switch
                        disabled={loading}
                        defaultChecked={
                          values.actionstatus === "publish" ? true : false
                        }
                        onChange={(val) => setFieldValue("actionstatus", val)}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>

            <div className="d-flex ">
              <div>
                <button
                  type="submit"
                  className="addbtn border p1060 bgprimary"
                  onClick={() => handleSubmit()}
                >
                  Save
                </button>
              </div>
              <div className="ml10">
                <button
                  type="submit"
                  className="cancelbtn border p1060 "
                  onClick={() => {
                    handleCloseModal();
                    resetForm();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AnnouncementCard;
