import React, { useEffect, useState } from "react";
import { DatePicker, Button, Row, Col, Spin, Table, Typography, Pagination } from "antd";
import { useDispatch } from "react-redux";
import { types } from "../../../../store/action_types";
import { LeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Wallets from '../index'
import PaymentCard from '../../../../SharedComponents/PaymentCrad'
import { GET } from "../../../../Utils/httpServices";
import { DownloadOutlined } from "@ant-design/icons";
import moment from 'moment';
import { message } from 'antd';

const { Title } = Typography;
const token = window.sessionStorage.getItem("token");
const dateFormat = 'YYYY/MM/DD';

const WalletDetails = ({ userId, onBackClick }) => {
    const dispatch = useDispatch();

    const { walletDetailsData, loading } = useSelector((state) => ({
        walletDetailsData: state.GameWalletsReducer.walletDetailsData,
        loading: state.GameWalletsReducer.walletDetailsLoading,
    }));

    const [showWalletsTable, setShowWalletsTable] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [paymentCardData, setPaymentCardData] = useState(null);
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchPaymentCardData = async () => {
            try {
                const response = await GET(`admin/user-stat/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const responseData = response.data.result.userStat[0];
                    setPaymentCardData(responseData);
                    dispatch({
                        type: types.SetWalletDetailsLoading,
                        payload: false,
                    });
                } else {
                    console.error("Failed to fetch payment card data from the API");
                    dispatch({
                        type: types.SetWalletDetailsLoading,
                        payload: false,
                    });
                }
            } catch (error) {
                console.error("Error in fetching payment card data from the API:", error);
                dispatch({
                    type: types.SetWalletDetailsLoading,
                    payload: false,
                });
            }
        };

        fetchPaymentCardData();
    }, [userId]);

    useEffect(() => {
        dispatch({
            type: types.SetWalletDetailsLoading,
            payload: true,
        });
        const payload = {
            userId, page: currentPage, fromDate, toDate
        }
        dispatch({ type: types.GetWalletDetailsRequest, payload });
        dispatch({
            type: types.SetWalletDetailsLoading,
            payload: false,
        });
    }, [dispatch, userId, currentPage]);

    const handleFromDateChange = async (date) => {
        setFromDate(date);
        const modifiedFromDate = new Date(date.startOf('day').toDate())
        const modifiedToDate = toDate ? new Date(toDate.endOf('day').toDate()) : null;

        if (modifiedToDate && modifiedFromDate > modifiedToDate) {
            message.error("Start date must be less than or equal to end date");
            return;
        }
        else {
            dispatch({
                type: types.GetWalletDetailsRequest,
                payload: {
                    userId,
                    page: currentPage,
                    pageSize,
                    fromDate: modifiedFromDate,
                    toDate: modifiedToDate,
                },
            });
        }
    };

    const handleToDateChange = async (date) => {
        setToDate(date);
        const modifiedToDate = new Date(date.endOf('day').toDate())
        const modifiedFromDate = fromDate ? new Date(fromDate.startOf('day').toDate()) : null;
        if (modifiedFromDate && modifiedFromDate > modifiedToDate) {
            message.error("End date must be greater than or equal to start date");
            return;
        } else {
            dispatch({
                type: types.GetWalletDetailsRequest,
                payload: {
                    userId,
                    page: currentPage,
                    pageSize,
                    fromDate: modifiedFromDate,
                    toDate: modifiedToDate,
                },
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBackToWallets = () => {
        setShowWalletsTable(true);
        onBackClick();
    };

    const columns = [
        {
            title: "Transaction Data",
            dataIndex: "transactionDate",
            key: "transactionDate",
            render: (text) => {
                const formattedDate = text ? new Date(text).toLocaleString() : "";
                return <span>{formattedDate}</span>;
            },
        },
        {
            title: "Type",
            dataIndex: "itemType",
            key: "itemType",
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (text, record) => (
                <span>{record.totalAmount ? `$${record.totalAmount}` : '1'}</span>
            ),
        },
        {
            title: "From",
            dataIndex: "from",
            key: "from",
            render: (text) => (
                <span title={text ? (text.length > 10 ? text : undefined) : undefined}>
                    {text ? (text.length > 10 ? text.slice(0, 10) + "..." : text) : "N/A"}
                </span>
            ),
        },
        {
            title: "To",
            dataIndex: "to",
            key: "to",
            render: (text) => (
                <span title={text ? (text.length > 10 ? text : undefined) : undefined}>
                    {text ? (text.length > 10 ? text.slice(0, 10) + "..." : text) : "N/A"}
                </span>
            ),
        },
    ];

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);


        const modifiedFromDate = fromDate ? new Date(fromDate.startOf('day').toDate()) : null;
        const modifiedToDate = toDate ? new Date(toDate.endOf('day').toDate()) : null;

        const payload = {
            userId,
            page,
            pageSize,
            fromDate: modifiedFromDate,
            toDate: modifiedToDate,
        };

        dispatch({ type: types.GetWalletDetailsRequest, payload });

    };

    if (loading) {
        return (
            <div className="loader">
                <Spin size="medium" style={{ marginTop: "0vh" }} indicator={<LoadingIndicator />}></Spin>
            </div>
        );
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf("day");
    };

    const downloadCSV = () => {
        if (currentPage <= 0) {
            message.error("Invalid page number");
            return;
        }

        const dataToDownload = walletDetailsData?.result?.docs || [];

        if (dataToDownload.length === 0) {
            message.info("No data available to download");
            return;
        }

        const formattedData = dataToDownload.map((item) => ({
            "Transaction Date": item.transactionDate,
            "User": item.user && item.user.nickName ? item.user.nickName : "N/A",
            "Type": item.itemType,
            "Total Amount": item.totalAmount ? `$${item.totalAmount}` : '1',
            "From": item.from ? item.from : "N/A",
            "To": item.to ? item.to : "N/A"
        }));

        const csvContent = [
            Object.keys(formattedData[0]).join(","),
            ...formattedData.map((row) => Object.values(row).join(",")),
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "wallet_transactions.csv";
        link.click();
    };

    return (
        <>
            {showWalletsTable && (
                <div key={userId._id}>
                    <Wallets />
                </div>
            )}
            <div>
                {!showWalletsTable && (
                    <>
                        <Row gutter={16}>
                            {paymentCardData && paymentCardData.totalPayment !== undefined && paymentCardData.totalMoneyForBoost !== undefined && (
                                <>
                                    <Col span={6}>
                                        <PaymentCard
                                            data1={{
                                                title: "Total Payments",
                                                totalPayment: paymentCardData.totalPayment,
                                            }}
                                            data2={{
                                                title: "Total Money For Boost",
                                                totalMoneyForBoost: paymentCardData.totalMoneyForBoost,
                                            }}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "20px",
                            }}
                        >
                            <Col span={6}>
                                <Row>
                                    <Title level={4} style={{ marginBottom: 8 }}>
                                        From
                                    </Title>
                                    <DatePicker
                                        placeholder="Start Date..."
                                        className="w-100"
                                        name="startDate"
                                        disabledDate={disabledDate}
                                        onChange={handleFromDateChange}
                                        value={fromDate}
                                    />
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Row>
                                    <Title level={4} style={{ marginBottom: 8 }}>
                                        To
                                    </Title>
                                    <DatePicker
                                        placeholder="End Date..."
                                        className="w-100"
                                        name="endDate"
                                        format={dateFormat}
                                        onChange={handleToDateChange}
                                        value={toDate}
                                        disabledDate={disabledDate}
                                    />
                                </Row>
                            </Col>
                            <Col span={8} style={{ textAlign: "right" }}>
                                <Button
                                    onClick={downloadCSV}
                                    type="primary"
                                    style={{
                                        backgroundColor: "#BAA068",
                                        color: "white",
                                        width: "159px",
                                        height: "48px",
                                    }}
                                >
                                    Download <DownloadOutlined style={{ marginLeft: "8px" }} />
                                </Button>
                            </Col>
                        </Row>
                        <Col>
                            <Button
                                type="text"
                                onClick={handleBackToWallets}
                                icon={<LeftOutlined style={{ verticalAlign: "middle", marginRight: "5px" }} />}
                                style={{
                                    paddingLeft: "7px",
                                    paddingRight: '14px',
                                    margin: "10px 0 10px 0",
                                    backgroundColor: "#BAA068",
                                    color: "white",
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                    transition: 'background-color 0.3s',
                                }}
                                className="my-custom-button"
                            >
                                Wallet
                            </Button>
                        </Col>
                        {walletDetailsData && walletDetailsData.result && walletDetailsData.result.docs ? (
                            <>
                                <Title level={3}>Payment History</Title>
                                <Table
                                    dataSource={walletDetailsData?.result?.docs}
                                    columns={columns}
                                    pagination={false}
                                />
                                <div className="d-flex justify-content-end mt20">
                                    <Pagination
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={walletDetailsData?.result?.totalDocs}
                                        onChange={handlePageChange}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="loader">
                                <Spin size="medium" style={{ marginTop: "-95vh" }}></Spin>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default WalletDetails;

const LoadingIndicator = () => (
    <div
        style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#78633A",
            textAlign: "center",
        }}
    >
        <Spin size="medium" />
        <div style={{ marginTop: "10px" }}>Loading Transaction Details...</div>
    </div>
);

