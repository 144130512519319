import { types } from "../action_types";

const INITIAL_STATE = {
  // otpdata:null,
  questListingAllData: [],
  questCreationData: [],
  allQuestloading: false,
  createQuestloading: false,
  editQuestModalOpen: false,
  questDetailByIdData: [],
  questDeleteData: [],
  DeleteQuestloading: false,
  blockchainCall: false,
  searchText: null,
};

const QuestReducer = (state = INITIAL_STATE, { type, payload }) => {

  switch (type) {
    //Quest listing
    case types.QuestListingRequest:
      return { ...state, allQuestloading: true };
    case types.QuestListingRequestSuccess:
      return {
        ...state,
        questListingAllData: payload.responsedata,
        allQuestloading: false,
        searchText: payload.actualSearchText || ''
      };
    case types.QuestListingRequestFailure:
      return { ...state, allQuestloading: false };


    // Update QUest
    case types.QuestUpdateRequest:
      return { ...state, updateQuestloading: true };
    case types.QuestUpdateRequestSuccess:
      return {
        ...state,
        questUpdateData: payload,
        updateQuestloading: false,
        editQuestModalOpen: false,
      };
    case types.QuestCreationBlockchain:
      return {
        ...state,
        blockchainCall: payload,
      };
    case types.QuestUpdateRequestFailure:
      return { ...state, updateQuestloading: false, editQuestModalOpen: true };

    //Get Quest Detail by iD
    case types.QuestDetailByIdRequest:
      return { ...state, getQuestDetilByIDloading: true };
    case types.QuestDetailByIdRequestSuccess:
      return {
        ...state,
        questDetailByIdData: payload,
        getQuestDetilByIDloading: false,
      };
    case types.QuestDetailByIdRequestFailure:
      return { ...state, getQuestDetilByIDloading: false };



    // Create Quest
    case types.QuestCreationRequest:
      return { ...state, createQuestloading: true };
    case types.QuestCreationRequestSuccess:
      return {
        ...state,
        questCreationData: payload,
        createQuestloading: false,

      };
    case types.QuestCreationRequestFailure:
      return { ...state, createQuestloading: false };


    // Delete Quest
    case types.QuestDeleteRequest:
      return { ...state, DeleteQuestloading: true };
    case types.QuestDeleteRequestSuccess:
      return {
        ...state,
        questDeleteData: payload,
        DeleteQuestloading: false,
      };
    case types.QuestDeleteRequestFailure:
      return { ...state, DeleteQuestloading: false };


    // Edit Quest Open Modal
    case types.EditQuestOpenModalRequest:
      return { ...state, editQuestModalOpen: true };

    // Edit Quest Close Modal
    case types.EditQuestCloseModalRequest:
      return { ...state, editQuestModalOpen: false };



    default:
      return state;
  }
};
export default QuestReducer;
