import { Pagination, Switch, Table } from "antd";
import {
  DeleteIcon,
  EditIcon,
  PlusIcon,
} from "../../../../SharedComponents/icons/icons";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../../store/action_types";
import TaskForm from "../../../../SharedComponents/TaskFrom";
import SearchBar from "../../../../SharedComponents/SearchBar";

const { confirm } = Modal;
let addkey = Math.random();
const AllTaskListing = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: types.GetAllTaskRequest,
      payload: {
        page: 1,
      },
    });
  }, []);

  const [data, setData] = useState();
  const [editTaskData, setEditTaskData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [openAddTaskForm, setOpenAddTaskForm] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [countrySearchText, setCountrySearchText] = useState("");
  const [citySearchText, setCitySearchText] = useState("");

  const { allTaskData, editTaskModalOpen, addTaskModalOpen } = useSelector(
    (state) => ({
      allTaskData: state.TaskReducer.allTaskData,
      editTaskModalOpen: state.TaskReducer.editTaskModalOpen,
      addTaskModalOpen: state.TaskReducer.addTaskModalOpen,
    })
  );

  // const { searchCityTextTasks, searchCountryTextTasks } = useSelector(
  //   (state) => ({
  //     searchCityTextTasks: state.QuestReducer.searchCityText,
  //     searchCountryTextTasks: state.QuestReducer.setCountrySearch
  //   })
  // );

  const { searchTextTasks } = useSelector((state) => ({
    searchTextTasks: state.QuestReducer.searchText,
  }));

  useEffect(() => {
    setData(allTaskData);
  }, [allTaskData]);

  const showAddTaskModal = () => {
    dispatch({
      type: types.AddTaskOpenModalRequest,
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",

      render: (item) => (
        <div key={item._id}>
          {/* <img src={TaskLogo} className="taskavatarimage" />*/} {item}
        </div>
      ),
    },
    {
      title: "Country",
      dataIndex: "location",
      key: "location",

      render: (item) => <div key={item._id}>{item?.countryCode}</div>,
    },
    {
      title: "City ",
      dataIndex: "location",
      key: "location",

      render: (item) => (
        <>
          <div key={item._id}>{item?.city}</div>
        </>
      ),
    },

    {
      // title: "Type of Clue",
      title: "Representation Of Task",
      dataIndex: "representationOfTask",
      key: "representationOfTask",

      render: (item) => (
        // <div  >{item}</div>
        <div className="typeofclue" key={item._id}>
          {" "}
          {item}
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (item) => (
        <div>
          <Switch checked={item === "de-activate" ? false : true} disabled />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",

      key: "x",
      render: (item) => (
        <div className="d-flex ">
          <div className="cursor" onClick={() => handlDeleteRecord(item)}>
            {" "}
            <DeleteIcon />
          </div>

          <div className="ml10 cursor" onClick={() => handleUpdateTask(item)}>
            {" "}
            <EditIcon />
          </div>

          {/* <div className="ml10 cursor-nodrop">
            {" "}
            <EditIcon />
          </div> */}
        </div>
      ),
    },
  ];

  const handleUpdateTask = (item) => {
    setEditTaskData(item);
    // setEditTaskModalOpen(true)
    dispatch({
      type: types.EditTaskOpenModalRequest,
    });
  };
  // const hanldeCountriesList = (e) => {
  //   setCityName("");
  //   setCountryName(e);
  //   setCityList(e.cities);
  // };
  // const hanldeCityList = (e) => {
  //   setCityName(e);
  // };

  const handleCloseEditTaskModal = () => {
    dispatch({
      type: types.EditTaskCloseModalRequest,
    });
  };

  const handleCloseAddTaskModal = () => {
    addkey = Math.random();
    dispatch({
      type: types.AddTaskCloseModalRequest,
    });
    // setOpenAddTaskForm(false);
  };

  const handlDeleteRecord = (item) => {
    confirm({
      title: "Delete Task",
      // icon: <ExclamationCircleFilled />,
      content: "Are you sure to delete this Task?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch({
          type: types.TaskDeleteRequest,
          payload: item?._id,
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onChangeTaskPagination = (page) => {
    setCurrentPage(page);
    dispatch({
      type: types.GetAllTaskRequest,
      payload: {
        searchText: searchText ? searchText : searchTextTasks,
        // citySearchText: citySearchText ? citySearchText : searchCityTextTasks,
        // countrySearchText: countrySearchText ? countrySearchText : searchCountryTextTasks,
        page,
      },
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    dispatch({
      type: types.GetAllTaskRequest,
      payload: {
        searchText: searchText,
        page: currentPage,
      },
    });
  };

  // const handleCountrySearch = (searchText) => {
  //   setCountrySearchText(searchText);
  //   dispatch({
  //     type: types.GetAllTaskRequest,
  //     payload: {
  //       countrySearchText: countrySearchText,
  //       page: currentPage,
  //     },
  //   });
  // };

  // const handleCitySearch = (searchText) => {
  //   setCitySearchText(searchText);
  //   dispatch({
  //     type: types.GetAllTaskRequest,
  //     payload: {
  //       citySearchText: citySearchText,
  //       page: currentPage,
  //     },
  //   });
  // };

  return (
    <>
      <div>
        <div className="d-flex  align-items-baseline mb-3">
          <SearchBar onSearch={handleSearch} placeholder="Search by Country" />
          {/* <SearchBar onSearch={handleCitySearch} placeholder="Search by City" /> */}
          <div>
            <div className="addbtn" onClick={showAddTaskModal}>
              {" "}
              Add Task <PlusIcon />{" "}
            </div>
          </div>
        </div>
        <Table
          pagination={false}
          columns={columns}
          dataSource={data?.result?.tasks?.docs}
        />

        <div className="d-flex justify-content-end mt20">
          <Pagination
            current={allTaskData?.result?.tasks?.currentPage}
            pageSize={10}
            total={allTaskData?.result?.tasks?.totalDocs}
            onChange={(page) => onChangeTaskPagination(page)}
            showSizeChanger={false}
          />
        </div>
        <Modal
          title={false}
          footer={false}
          open={editTaskModalOpen}
          onCancel={handleCloseEditTaskModal}
          width={1000}
        >
          {editTaskModalOpen ? (
             <TaskForm
                editTaskData={editTaskData}
                currentPage={currentPage}
             />
          ) : null}
        </Modal>

        <Modal
          title={false}
          footer={false}
          open={addTaskModalOpen}
          onCancel={handleCloseAddTaskModal}
          width={1000}
        >
          {addTaskModalOpen ? (
            <TaskForm />
          ) : null}
        </Modal>
      </div>
    </>
  );
};
export default AllTaskListing;
