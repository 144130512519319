import React, { useState, useEffect } from "react";
import { Button, Input, Spin, Row, Col } from "antd";
import { ethers } from "ethers";
import CH_Coin_ABI from "../../../../Assets/chCoin.json";
import bigInt from "big-integer";
import CustomToster from "../../../SharedComponents/CustomToaster";
import GameSwapABI from "../../../../Assets/gameSwap.json";

const GameSwap = () => {
  const [coinCount, setCoinCount] = useState("");
  const [gameSwapBalanceValue, setGameSwapBalanceValue] = useState("");
  const [transferLoading, setTransferLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const gameSwapContractAddress =
    process.env.REACT_APP_GAME_SWAP_CONTRACT_ADDRESS;

  useEffect(() => {
    fetchGameSwapBalance()
      .then((balance) => setGameSwapBalanceValue(balance))
      .catch((error) =>
        console.error("Error fetching game swap balance:", error)
      );
  }, []);

  const handleChange = (value) => {
    if (/^\d+$/.test(value) || value === "") {
      setCoinCount(value);
    }
  };

  const transferCoin = async () => {
    try {
      if (!coinCount) return;
      setTransferLoading(true);

      if (window?.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const chCoin = new ethers.Contract(
          process.env.REACT_APP_CH_COIN_CONTRACT_ADDRESS,
          CH_Coin_ABI,
          signer
        );

        const transaction = await chCoin.transfer(
          gameSwapContractAddress,
          bigInt(coinCount * 10 ** 18).toString(10)
        );

        await transaction.wait();

        setCoinCount("");
        const balance = await fetchGameSwapBalance();
        setGameSwapBalanceValue(balance);

        CustomToster({
          type: "success",
          message: "Transaction Successful",
        });
      } else {
        CustomToster({
          type: "error",
          message: "Please install Metamask extension!!",
        });
      }
    } catch (error) {
      console.error("Error transferring coins:", error);
      CustomToster({
        type: "error",
        message: "Transaction Failed",
      });
    } finally {
      setTransferLoading(false);
    }
  };

  const fetchGameSwapBalance = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const chCoin = new ethers.Contract(
          process.env.REACT_APP_CH_COIN_CONTRACT_ADDRESS,
          CH_Coin_ABI,
          signer
        );

        const balance = await chCoin.balanceOf(gameSwapContractAddress);
        const formattedBalance = ethers.utils.formatUnits(balance, 18);

        return formattedBalance;
      } else {
        CustomToster({
          type: "error",
          message: "Please install Metamask extension!!",
        });
        throw new Error("Metamask not installed");
      }
    } catch (error) {
      console.error("Error fetching game swap balance:", error);
      throw error;
    }
  };

  const withdrawCoin = async () => {
    try {
      if (gameSwapBalanceValue === "0") {
        CustomToster({
          type: "error",
          message: "Balance is already zero",
        });
        return;
      }

      if (gameSwapBalanceValue === "0") return;

      setWithdrawLoading(true);

      if (window?.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const gameSwapContract = new ethers.Contract(
          gameSwapContractAddress,
          GameSwapABI,
          signer
        );
        const transaction = await gameSwapContract.withdrawERC20(
          process.env.REACT_APP_CH_COIN_CONTRACT_ADDRESS
        );
        await transaction.wait();

        const balance = await fetchGameSwapBalance();
        setGameSwapBalanceValue(balance);

        CustomToster({
          type: "success",
          message: "Withdrawal Successful",
        });
      } else {
        CustomToster({
          type: "error",
          message: "Please install Metamask extension!!",
        });
      }
    } catch (error) {
      console.error("Error withdrawing coins:", error);
      CustomToster({
        type: "error",
        message: "Withdrawal Failed",
      });
    } finally {
      setWithdrawLoading(false);
    }
  };

  return (
    <Row justify="space-between" gutter={[16, 16]}>
      <Col>
        <Input
          type="number"
          value={coinCount}
          onChange={(e) => handleChange(e.target.value)}
          placeholder="Enter coins..."
          style={{
            width: "200px",
            marginRight: "5px",
          }}
        />
        <Button
          type="primary"
          onClick={transferCoin}
          loading={transferLoading}
          style={{
            background: "#78633a",
            border: "1.2px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          Transfer
        </Button>
      </Col>
      <Col>
        <div>Game Swap Balance: {gameSwapBalanceValue} CRH</div>
        <Button
          type="primary"
          onClick={withdrawCoin}
          // disabled={withdrawLoading || gameSwapBalanceValue === "0"}
          loading={withdrawLoading}
          style={{
            background: "#78633a",
            border: "1.2px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            marginLeft: "20px",
            marginTop: "10px",
          }}
        >
          Withdraw All
        </Button>
      </Col>
    </Row>
  );
};

export default GameSwap;
