import { Spin, Switch } from "antd";
import { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../../store/action_types";
import CustomTextInput from "../../../../SharedComponents/CustomTextInput";
import { Constants } from "../../../../Constants";
import CustomToster from "../../../../SharedComponents/CustomToaster";
import { allowedExtensions } from "../../../../Utils/constants";

const CreateCollection = () => {
  const dispatch = useDispatch();

  const { collectionImageLink } = useSelector((state) => ({
    collectionImageLink:
      state?.CollectionReducer?.uploadcollectionimageAllData?.result?.imgLink,
  }));

  const [fileList, setFileList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(false);

  useEffect(() => {
    if (collectionImageLink && imageloading) {
      setImageLoading(false);
    }
  }, [collectionImageLink]);
  function getExtension(filename) {
    return filename.split(".").pop();
  }
  const handleImageChange = (e) => {
    let files = e.target.files;
    if (!files[0]) {
      return;
    }
    console.log("file here create collection", files[0]);
    let formData = new FormData();
    if (files[0].size > 3 * 1024 * 1024) {
      CustomToster({
        type: "error",
        message: "Selected image exceeds the maximum allowed file size of 3MB",
      });
      e.target.value = null;
      return;
    }
    const foundItem = allowedExtensions.find(
      (item) => item === getExtension(e?.target.files[0].name)
    );
    if (!foundItem) {
      CustomToster({
        type: "error",
        message: "please upload only image file",
      });
      return;
    }
    setImageLoading(true);
    formData.append("file", files[0]);
    let fileReader = new FileReader();
    fileReader?.readAsDataURL(files[0]);

    fileReader.onload = (event) => {
      dispatch({
        type: types.UploadCollectionImageRequest,
        payload: {
          uploadCollectionImageData: {
            fileBase64: event.target.result.split(",")[1],
            fileName: e?.target.files[0].name,
          },
        },
        formData: formData,
      });
      setFileList(event.target.result);
    };
  };

  if (transactionLoading) {
    return (
      <div className="loader">
        <Spin tip="Creating Collection..." style={{ color: "#78633A" }}></Spin>
      </div>
    );
  }
  return (
    <>
      <div>
        <Formik
          initialValues={{
            companyName: companyName,
            status: status,
            description: description,
          }}
          enableReinitialize
          onSubmit={(values) => {
            if (!collectionImageLink) {
              CustomToster({
                type: "error",
                message: "Please upload asset to create collection",
              });
              return;
            }
            let contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
            setTransactionLoading(true);
            dispatch({
              type: types.UploadNFTMetaDataRequest,
              payload: {
                values,
                collectionImageLink,
                contractAddress,
                setTransactionLoading,
              },
            });
          }}
          validationSchema={Constants.PrizePoolSchema}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
          }) => (
            <div className="nft-grid-wrapper">
              <div>
                <div className="row col-12">
                  <div className="col-md-4 d-flex align-items-center ">
                    <span>Asset Image</span>
                  </div>
                  <div className="col-md-8">
                    <div className="mt10">
                      <div>
                        <input
                          type="file"
                          name="assetimage"
                          onChange={handleImageChange}
                          className="custom-file-input front col-12 col-sm-6 col-md-12"
                          accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .tiff, .psd, .svg"
                          required={true}
                        />
                        {fileList.length === 0 ? <p>Max size 3MB</p> : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-12">
                  <div className="col-md-4 d-flex align-items-center">
                    <span>Company Name</span>
                  </div>
                  <div className="col-md-8">
                    <Field name="companyName">
                      {({ meta }) => (
                        <CustomTextInput
                          label={""}
                          required={false}
                          type="text"
                          error={meta.touched ? meta.error : null}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                            handleChange("companyName")(e);
                          }}
                          placeHolderText={"Enter Your Company Name"}
                          value={values.companyName}
                          onBlur={handleBlur("companyName")}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row col-12 mt20">
                  <div className="col-md-4 d-flex align-items-center">
                    <span>Description</span>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      class="form-control w-100 textarea mt10"
                      rows="7"
                      onChange={(e) => {
                        if (e.target.value.length < 1000) {
                          setDescription(e.target.value);
                          handleChange("description")(e);
                        }
                      }}
                      placeholder="Optional"
                      id="description"
                      name="description"
                      value={values.description}
                    ></textarea>
                  </div>
                </div>

                <div className="row col-12 mt10">
                  <div className="col-md-4 d-flex align-items-center">
                    <span>Status</span>
                  </div>
                  <div className="col-md-8">
                    <Switch
                      defaultChecked={values.status}
                      onChange={(val) => {
                        setStatus(val);
                        setFieldValue("status", val);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <span>Preview *</span>
                <div
                  className="collection-image-preview mt10"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {imageloading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spin style={{ color: "#78633A" }}></Spin>
                    </div>
                  ) : collectionImageLink ? (
                    <img
                      src={collectionImageLink}
                      className="collection-image-preview"
                    />
                  ) : (
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        padding: "0px 15px",
                        justifyContent: "center",
                        placeItems: "center",
                        display: "flex",
                      }}
                    >
                      Upload file and choose collection to preview your brand
                      new NFT
                    </p>
                  )}
                </div>
                <div className="mt30">
                  <span>MetaData</span>
                </div>

                <div
                  className="meta-data-wrapper"
                  style={{
                    maxWidth: "750px",
                  }}
                >
                  <div>
                    <b>"matadata":{`{`} </b>
                  </div>
                  <div className="ml30">
                    {" "}
                    <b>"company Name" :</b> {`"${values?.companyName}"`}
                  </div>
                  <div className="ml30">
                    <b>"description":</b>
                    {`"${values?.description}"`}
                  </div>
                  <div className="ml30">
                    {" "}
                    <b>"image":</b>
                    {`"${collectionImageLink || ""}"`}
                  </div>

                  <p>
                    <b>{`}`}</b>
                  </p>
                </div>
              </div>

              <div className="">
                <button
                  type="submit"
                  className="savebtn mt10 w30"
                  onClick={() => handleSubmit()}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateCollection;
