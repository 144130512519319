import AnnouncementCard from "../../../../SharedComponents/AnnouncementCard";
import { useState } from "react";
const EditAnnouncement = ({
  handleCloseEditAnnouncementModal,
  onChangeAnnouncementPagination,
  editRecordData,
  currentPage,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div className="modaltitle">Edit Announcement</div>
      <div>
        <AnnouncementCard
          handleCloseModal={handleCloseEditAnnouncementModal}
          onChangeAnnouncementPagination={onChangeAnnouncementPagination}
          setLoading={setLoading}
          loading={loading}
          editRecordData={editRecordData}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};
export default EditAnnouncement;
