import { Switch, Table } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../../store/action_types";
import SearchBar from "../../../../SharedComponents/SearchBar";

const AllPrizesListing = () => {
  const dispatch = useDispatch();
  const { prizePoolListingAllData } = useSelector((state) => ({
    prizePoolListingAllData:
      state.PrizePoolReducer.prizePoolListingAllData?.result?.prizePool,
  }));

  console.log("prizePoolListingAllData", prizePoolListingAllData);

  const { searchTextPrizes } = useSelector((state) => ({
    searchTextPrizes: state.QuestReducer.searchText,
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch({
      type: types.PrizePoolListingRequest,
      payload: {
        page: 1,
      },
    });
  }, []);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",

      render: (item) => <div>{item}</div>,
    },
    {
      title: "Reward Type",
      dataIndex: "rewardTypes",
      key: "rewardTypes",

      render: (item) => <div>{item}</div>,
    },

    {
      title: "Game Mode",
      dataIndex: "gameMode",
      key: "gameMode",

      render: (item) => (
        <div>
          <div className="typeofclue"> {item}</div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (item, param) => (
        <div>
          <Switch
            disabled={loading}
            defaultChecked={item == "activate" ? true : false}
            onChange={(val) => handlePrizePoolStatus(val, param)}
          />
        </div>
      ),
    },
  ];

  const handlePrizePoolStatus = (val, param) => {
    setLoading(true);
    let data = {
      prizePoolStatus: {
        status: val === true ? "activate" : "de-activate",
      },
      id: param?._id,
      currentPage: currentPage,
      setLoading: setLoading,
    };

    dispatch({
      type: types.PrizePoolUpdateRequest,
      payload: data,
    });
  };

  const handleSearch = (searchText) => {
    dispatch({
      type: types.PrizePoolListingRequest,
      payload: {
        searchText: searchText,
        page: currentPage,
      },
    });
  };

  return (
    <>
      <>
        <div>
          {/* <SearchBar onSearch={handleSearch} /> */}
          <Table
            pagination={false}
            columns={columns}
            dataSource={prizePoolListingAllData}
          />
          <Modal title={false} footer={false} width={1000}></Modal>
        </div>
      </>
    </>
  );
};
export default AllPrizesListing;
