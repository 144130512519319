import {call, put, takeLatest} from "redux-saga/effects";
import bigInt from 'big-integer';
import {types} from "../action_types";
import {FORMPOST, GET, POST, PUT} from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";
import gameBoosts from "../../../Assets/gameBoosts.json";
import {BigNumber, ethers} from "ethers";
import {BASEURL} from "../../Constants";

const token = window.sessionStorage.getItem("token");


function* GetAllBoostListingSaga(action) {
    const {payload} = action;
    const { page, searchText } = payload;
    const actualSearchText = searchText || '';

    try {
        const boostListingAllData_response = yield GET(
            `/game-boost/all?page=${page}&limit=10&name=${actualSearchText}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        let responsedata = {...boostListingAllData_response?.data};

        if (boostListingAllData_response?.status === 200) {
            yield put({
                type: types.BoostListingRequestSuccess,
                payload: {responsedata , actualSearchText},
            });
        } else {
            yield put({type: types.BoostListingRequestFailure});
        }
    } catch (e) {
        CustomToster({
            type: "error",
            message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
        });

        yield put({type: types.BoostListingRequestFailure});
    }
}

function* UpdateBoostSaga({payload}) {
    const {id, updateBoostData, currentPage} = payload;

    try {
        const boostUpdateData_response = yield PUT(
            `admin/game-boost/update/${id}`,
            updateBoostData
        );

        let boostUpdateresponsedata = {
            ...boostUpdateData_response?.data,
        };

        if (boostUpdateData_response?.status === 200) {
            yield put({
                type: types.BoostUpdateRequestSuccess,
                payload: boostUpdateresponsedata,
            });

            yield put({
                type: types.BoostListingRequest,
                payload: currentPage,
            });
            CustomToster({
                type: "success",
                message: "Boost Updated Successfully",
            });
            // window.location.reload();
        } else {
            yield put({
                type: types.BoostListingRequest,
                payload: currentPage,
            });
            yield put({type: types.BoostUpdateRequestFailure});
        }
    } catch (e) {
        CustomToster({
            type: "error",
            message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
        });

        yield put({type: types.BoostUpdateRequestFailure});
        yield put({
            type: types.BoostListingRequest,
            payload: currentPage,
        });
    }
}

let setTranLoading = "";

function* UploadBoastImageSaga({payload, formData, setImageLoading}) {
    console.log("Boast imaage uplaod called");

    try {
        const uploadBoastImage_response = yield FORMPOST(
            "admin/upload/nft-image",
            formData
        );

        let uploadBoastImageresponsedata = {
            ...uploadBoastImage_response?.data,
        };

        if (uploadBoastImage_response?.status === 200) {
            yield put({
                type: types.UploadBoastImageRequestSuccess,
                payload: uploadBoastImageresponsedata,
            });

            CustomToster({
                type: "success",
                message: "Your Image Uploaded Successfully",
            });
            setImageLoading(false);
        } else {
            yield put({type: types.UploadBoastImageRequestFailure});
            setImageLoading(false);
        }
    } catch (e) {
        CustomToster({
            type: "error",
            message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
        });

        yield put({type: types.UploadBoastImageRequestFailure});
        setImageLoading(false);
    }
}

function* UploadBoastNFTMetaDataSaga({payload}) {
    let {setTransactionLoading, dispatch, handleCloseModal} = payload;

    setTranLoading = setTransactionLoading;
    let nftMataData = {
        metadata: {
            name: payload?.values?.name,
            description: payload?.values?.description,
            image: payload?.BoastImageLink
        },
        fileName: payload?.values?.name + ".json",
    };

    try {
        const uploadNFTMetaData_response = yield POST(
            "admin/upload/nft-metadata",
            nftMataData
        );

        let uploadNFTMetaDataresponsedata = {
            ...uploadNFTMetaData_response?.data,
        };

        if (uploadNFTMetaData_response?.status === 200) {
            let data = {
                //  collectionId: payload?.values?.collectionId,
                ipfsHash: uploadNFTMetaDataresponsedata?.result?.ipfsHash,
                metadataLink: uploadNFTMetaDataresponsedata?.result?.metadataLink,
                // contractAddress:payload?.contractAddress,
                payload: payload,
            };

            const response = yield call(
                BoostCreationNFTBloackchainApiCall,
                data,
                dispatch,
                handleCloseModal
            );
        } else {
            setTransactionLoading(false);
            yield put({type: types.UploadNFTMetaDataRequestFailure});
        }
    } catch (e) {
        setTransactionLoading(false);
        CustomToster({
            type: "error",
            message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
        });

        yield put({type: types.UploadNFTMetaDataRequestFailure});
    }
}

let hexatoDigitNumber;
let boostadded = false;
let URI_response;

async function BoostCreationNFTBloackchainApiCall(
    data,
    dispatch,
    handleCloseModal
) {
    // Meta Mask connectivity
    if (window?.ethereum) {
        window.ethereum.request({method: "eth_requestAccounts"});

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        console.log("data?.payload?.contractAddress", data);
        let promise = new Promise((resolve, reject) => {
            const cyrpto_nft = new ethers.Contract(
                data?.payload?.contractAddress,
                gameBoosts,
                signer
            );

            if (data.ipfsHash) {
                // console.log(
                //     "data?.payload?.price, data.ipfsHash",
                //     data?.payload?.values?.price,
                //     data.ipfsHash,
                //
                // );
                console.log('price', (bigInt((data?.payload?.values?.price)* 10 ** 18)));
                cyrpto_nft
                    ?.addGameBoost((bigInt((data?.payload?.values?.price)* 10 ** 18)).toString(10), data.ipfsHash)
                    .then((result) => {
                        result
                            ?.wait()
                            .then((writingSmartContractResponse) => {
                                boostadded = true;
                                // resolve("success");
                            })
                            .catch((error) => {
                                boostadded = false;
                                CustomToster({
                                    type: "error",
                                    message: "Transaction Failed",
                                });
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                        boostadded = false;
                        setTimeout(() => {
                            setTranLoading(false);
                        }, 1000);

                        console.log("err here is", err.message);
                        if (err.message.includes("unknown account")) {
                            CustomToster({
                                type: "error",
                                message: "Please login to metamask to proceed",
                            });
                            // display a message that the user is not logged in
                        } else if (err.message.includes("user rejected transaction")) {
                            CustomToster({
                                type: "error",
                                message: "Transaction Rejected",
                            });
                            // display a message that the user rejected the transaction
                        } else {
                            CustomToster({
                                type: "error",
                                message: "Transaction Failed  ",
                            });
                            // display a generic message that something went wrong
                        }
                    });
            }

            let filter = cyrpto_nft.filters.Added();

            // listen to the Added event using the filter
            cyrpto_nft.on(filter, (tokenId, price, _uri) => {
                // do something with the event parameters

                console.log("A new token was added with tokenId:", tokenId);
                console.log("A new token was added with:price", Number(price));
                console.log("A new token was added with:_uri", _uri);
                hexatoDigitNumber = Number(tokenId);
                if (tokenId && boostadded) {
                    resolve("success");
                }
            });
        });

        promise.then((response) => {
            console.log("here resolved", data);
            if (response) {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${token} `);
                var raw = JSON.stringify({
                    name: data?.payload?.values?.name,
                    gameBoostId: hexatoDigitNumber,
                    description: data?.payload?.values?.description,
                    // nftCollectionId: data?.payload?.values?.collectionId,
                    metadataLink: data?.metadataLink,
                    imageLink: data?.payload?.BoastImageLink,
                    isListed: data?.payload?.values?.actionstatus,
                    price: data?.payload?.values?.price,
                });

                console.log("raw here is", raw);
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };
                fetch(`${BASEURL}admin/game-boost/create`, requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        CustomToster({
                            type: "success",
                            message: "Your Boost Created Successfully",
                        });
                        dispatch({
                            type: types.BoostListingRequest,
                            payload: 1,
                        });

                        // window.location.replace("/boasts");

                        handleCloseModal();
                        setTranLoading(false);
                    })
                    .catch((error) => {
                        setTimeout(() => {
                            setTranLoading(false);
                        }, 1000);
                        CustomToster({
                            type: "error",
                            message: "Error while Saving in Database",
                        });
                    });
            }
        });
    } else {
        setTimeout(() => {
            setTranLoading(false);
        }, 1000);
        CustomToster({
            type: "error",
            message: "PLease install metamask extension!!",
        });
        // alert("install metamask extension!!");
    }
}

export default function* BoostSaga() {
    yield takeLatest(types.BoostListingRequest, GetAllBoostListingSaga);
    yield takeLatest(types.UploadBoastImageRequest, UploadBoastImageSaga);
    // yield takeLatest(types.BoostUpdateRequest, UpdateBoostSaga);
    yield takeLatest(
        types.UploadBoastNFTMetaDataRequest,
        UploadBoastNFTMetaDataSaga
    );
    yield takeLatest(types.BoostUpdateRequest, UpdateBoostSaga);
}
