import React from "react";
import { Card, Avatar, Row, Col } from "antd";

const { Meta } = Card;

const PaymentCard = ({ data1, data2 }) => {
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card
          style={{ width: "300px", textAlign: "left", margin: "0 20px 16px 20px" }}
        >
          <Meta
            avatar={<Avatar src={data1.image} />}
            title={data1.title}
            description={`Total Payment: $${data1.totalPayment}`}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card
          style={{ width: "300px", textAlign: "left", margin: "0 20px 16px 200px" }}
        >
          <Meta
            avatar={<Avatar src={data2.image} />}
            title={data2.title}
            description={`Total Money For Boost: $${data2.totalMoneyForBoost}`}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default PaymentCard;
