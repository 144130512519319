import React, { memo } from "react";
import { Card } from "antd";
import dayjs from "dayjs";
import {
  ClockIcon,
  LocationIcon,
} from "../../../../SharedComponents/icons/icons";
import { msToTime } from "../../../../Utils/constants";

const FamiliesCardlayout = memo(({ data }) => {
  const currentTime = dayjs();
  const daysAgo = dayjs(data?.quest?.endTime)?.diff(currentTime, "day") || 0;

  return (
    <div>
      <Card>
        <div className="p24">
          <div className="d-flex justify-content-between mb-1">
            <div className="d-flex gap5">
              <div
                className="rank"
                style={{
                  color:
                    data?.quest?.state === "active" ? "#F87171" : "#16A34A",
                }}
              >
                {data?.quest?.mode || ""}
              </div>

              <div
                className="rank"
                style={{
                  display: data?.quest?.state === "in-active" ? "none" : "flex",
                }}
              >
                {msToTime(data?.quest?.totalTime) || ""}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="text-center mt8">
            <span className="family-label"> {data?.quest?.name || ""}</span>
          </div>
          <div className="d-flex justify-content-center mt8">
            <div className="duration-box">
              {data?.quest?.state === "active" ? <ClockIcon /> : null}
              <span
                className={`${
                  data?.quest?.state === "in-active"
                    ? "duration-title-ago"
                    : "duration-title"
                }`}
              >
                {Math.abs(daysAgo)} days ago
              </span>
            </div>
          </div>
        </div>
        {data?.players?.map((playerItem) => {
          return (
            <div
              className="d-flex justify-content-between mt16"
              key={playerItem?.user?._id}
            >
              <div>
                <div className="d-flex">
                  <span className="userTitle">
                    {playerItem?.user?.nickName || ""}
                  </span>
                </div>

                <div className="d-flex">
                  <div>
                    <LocationIcon />
                  </div>
                  <div className="location">
                    in {playerItem?.user?.city || ""},{" "}
                    {playerItem?.user?.countryCode || ""}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Card>
    </div>
  );
});

export default FamiliesCardlayout;
