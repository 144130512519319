import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { GET, POST } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";

const multipartHeaders = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

function* GetCompaniesSaga({ payload }) {
  const { page } = payload;
  try {
    const companiesAllData_response = yield GET(
      `admin/allCompanies?page=${page}&limit=${5}&name=${
        payload?.searchText || ""
      }`
    );

    const { docs, currentPage, itemsPerPage, totalDocs, totalPages } =
      companiesAllData_response?.data.result.result;

    const companies = docs;
    const pagination = {
      currentPage,
      itemsPerPage,
      totalDocs,
      totalPages,
    };

    if (companiesAllData_response?.status === 200) {
      yield put({
        type: types.GetCompaniesRequestSuccess,
        payload: { companies, pagination },
      });
    } else {
      yield put({ type: types.GetCompaniesRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.GetCompaniesRequestFailure });
  }
}

function* CreateCompanySaga({ payload }) {
  let { formData, handleCloseModal, page } = payload;

  try {
    const createdCompanyData_response = yield POST(
      "admin/addCompany",
      formData,
      { ...multipartHeaders }
    );

    if (createdCompanyData_response?.status === 200) {
      yield put({
        type: types.CreateCompanyRequestSuccess,
      });

      yield put({
        type: types.GetCompaniesRequest,
        payload: { page },
      });

      CustomToster({
        type: "success",
        message: "Company Created Successfully",
      });
    } else {
      yield put({ type: types.CreateCompanyRequestSuccess });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.CreateCompanyRequestFailure });
  }
}

function* EditCompanySaga({ payload }) {
  let { updatedBody, handleCloseModal, page } = payload;

  try {
    const editCompanyData_response = yield POST(
      "admin/editCompany",
      updatedBody
    );

    if (editCompanyData_response?.status === 200) {
      yield put({
        type: types.EditCompanyRequestSuccess,
      });

      yield put({
        type: types.GetCompaniesRequest,
        payload: { page },
      });

      CustomToster({
        type: "success",
        message: "Company Edited Successfully",
      });
    } else {
      yield put({ type: types.EditCompanyRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.EditCompanyRequestFailure });
  }
}

function* DeleteCompanySaga({ payload }) {
  const { companyId, page } = payload;

  try {
    const deleteCompanyData_response = yield POST(`admin/deleteCompany`, {
      companyId,
    });

    if (deleteCompanyData_response?.status === 200) {
      yield put({
        type: types.DeleteCompanyRequestSuccess,
      });

      yield put({
        type: types.GetCompaniesRequest,
        payload: { page },
      });

      CustomToster({
        type: "success",
        message: "Company Deleted Successfully",
      });
    } else {
      yield put({ type: types.DeleteCompanyRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.DeleteCompanyRequestFailure });
  }
}

function* GetPoolsSaga({ payload }) {
  const { id, page } = payload;
  try {
    const pools_response = yield GET(
      `admin/productpools/${id}?page=${page}&limit=${5}&info=${
        payload?.searchText || ""
      }`
    );

    const { docs, currentPage, itemsPerPage, totalDocs, totalPages } =
      pools_response?.data.result.result;

    const pools = docs;
    const pagination = {
      currentPage,
      itemsPerPage,
      totalDocs,
      totalPages,
    };

    if (pools_response?.status === 200) {
      yield put({
        type: types.GetPoolsRequestSuccess,
        payload: { pools, pagination },
      });
    } else {
      yield put({ type: types.GetPoolsRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.GetPoolsRequestFailure });
  }
}

function* CreatePoolSaga({ payload }) {
  let { formData, handleCloseModal, currentCompanyId, page, file } = payload;

  try {
    const createdPoolData_response = yield POST(
      "admin/addProductPool",
      formData,
      { ...multipartHeaders }
    );

    if (createdPoolData_response?.status === 200) {
      const poolId = createdPoolData_response?.data.result.SavedPool._id;

      if (file && poolId) {
        let formData = new FormData();

        formData.append("poolId", poolId);
        formData.append("file", file);

        try {
          yield POST("admin/addProductFile", formData, {
            ...multipartHeaders,
          });
        } catch (e) {
          CustomToster({
            type: "error",
            message: "Entity Too Large",
          });
          return;
        }
      }

      yield put({
        type: types.CreatePoolRequestSuccess,
      });

      yield put({
        type: types.GetPoolsRequest,
        payload: {
          id: currentCompanyId,
          page,
        },
      });

      CustomToster({
        type: "success",
        message: "Pool Created Successfully",
      });
    } else {
      yield put({ type: types.CreatePoolRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.CreatePoolRequestFailure });
  }
}

function* DeletePoolSaga({ payload }) {
  const { poolId, currentCompanyId, page } = payload;

  try {
    const deletePoolData_response = yield POST(`admin/deleteProductPool`, {
      poolId,
    });

    if (deletePoolData_response?.status === 200) {
      yield put({
        type: types.DeletePoolRequestSuccess,
      });

      yield put({
        type: types.GetPoolsRequest,
        payload: {
          id: currentCompanyId,
          page,
        },
      });

      CustomToster({
        type: "success",
        message: "Pool Deleted Successfully",
      });
    } else {
      yield put({ type: types.DeletePoolRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.DeletePoolRequestFailure });
  }
}

function* EditPoolSaga({ payload }) {
  let { updatedBody, handleCloseModal, currentCompanyId, page, file } = payload;

  try {
    const editPoolData_response = yield POST(
      "admin/editProductPool",
      updatedBody
    );

    if (editPoolData_response?.status === 200) {
      if (file) {
        let formData = new FormData();

        formData.append("poolId", updatedBody.poolId);
        formData.append("file", file);

        try {
          yield POST("admin/addProductFile", formData, {
            ...multipartHeaders,
          });
        } catch (e) {
          CustomToster({
            type: "error",
            message: "Entity Too Large",
          });
          return;
        }
      }

      yield put({
        type: types.EditPoolRequestSuccess,
      });

      yield put({
        type: types.GetPoolsRequest,
        payload: {
          id: currentCompanyId,
          page,
        },
      });

      CustomToster({
        type: "success",
        message: "Pool Edited Successfully",
      });
    } else {
      yield put({ type: types.EditPoolRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.EditPoolRequestFailure });
  }
}

function* GetProductsSaga({ payload: poolId }) {
  try {
    const products_response = yield GET(`admin/products/${poolId}?limit=100`);
    const products = products_response?.data.result.result.docs;

    if (products_response?.status === 200) {
      yield put({
        type: types.GetProductsRequestSuccess,
        payload: { products: products },
      });
    } else {
      yield put({ type: types.GetProductsRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.GetProductsRequestFailure });
  }
}

function* EditProductsSaga({ payload }) {
  const { updatedBodies, handleCloseModal, currentCompanyId, page } = payload;

  try {
    const editProductsData_responses = yield Promise.all(
      updatedBodies.map((body) => POST("admin/editProduct", body))
    );

    const allSuccess = editProductsData_responses.every(
      (response) => response?.status === 200
    );

    if (allSuccess) {
      yield put({
        type: types.EditProductsRequestSuccess,
      });

      yield put({
        type: types.GetPoolsRequest,
        payload: {
          id: currentCompanyId,
          page,
        },
      });

      CustomToster({
        type: "success",
        message: "Products Edited Successfully",
      });
    } else {
      yield put({ type: types.EditProductsRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.EditProductsRequestFailure });
  }
}

export default function* CompaniesManagementSaga() {
  yield takeLatest(types.GetCompaniesRequest, GetCompaniesSaga);
  yield takeLatest(types.CreateCompanyRequest, CreateCompanySaga);
  yield takeLatest(types.EditCompanyRequest, EditCompanySaga);
  yield takeLatest(types.DeleteCompanyRequest, DeleteCompanySaga);

  yield takeLatest(types.GetPoolsRequest, GetPoolsSaga);
  yield takeLatest(types.CreatePoolRequest, CreatePoolSaga);
  yield takeLatest(types.EditPoolRequest, EditPoolSaga);
  yield takeLatest(types.DeletePoolRequest, DeletePoolSaga);

  yield takeLatest(types.GetProductsRequest, GetProductsSaga);
  yield takeLatest(types.EditProductsRequest, EditProductsSaga);
}
