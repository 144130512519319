import { types } from "../action_types";

const INITIAL_STATE = {
  reportsListingAllData: {
    docs: [],
  },
  allReportsLoading: false,
  blockUserLoading: false,
  unblockUserLoading: false,
  searchResults: null,
};

const ReportsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GetAllReportsRequest:
      return { ...state, allReportsLoading: true };
    case types.GetAllReportsRequestSuccess:
      return {
        ...state,
        reportsListingAllData: payload,
        allReportsLoading: false,
      };
    case types.GetAllReportsRequestFailure:
      return { ...state, allReportsLoading: false };

    // Block User
    case types.BlockUserRequest:
      return { ...state, blockUserLoading: true };
    case types.BlockUserRequestSuccess:
      const blockedUserId = payload.userId;
      return {
        ...state,
        blockUserLoading: false,
        reportsListingAllData: {
          ...state.reportsListingAllData,
          docs: state.reportsListingAllData.result.docs.map((report) =>
            report.complainant._id === blockedUserId
              ? {
                ...report,
                complainant: { ...report.complainant, isBlocked: true },
              }
              : report
          ),
        },
      };
    case types.BlockUserRequestFailure:
      return { ...state, blockUserLoading: false };

    // Unblock User
    case types.UnblockUserRequest:
      return { ...state, unblockUserLoading: true };
    case types.UnblockUserRequestSuccess:
      const unblockedUserId = payload.userId;
      return {
        ...state,
        unblockUserLoading: false,
        reportsListingAllData: {
          ...state.reportsListingAllData,
          docs: state.reportsListingAllData.result.docs.map((report) =>
            report.complainant._id === unblockedUserId
              ? {
                ...report,
                complainant: { ...report.complainant, isBlocked: false },
              }
              : report
          ),
        },
      };
    case types.UnblockUserRequestFailure:
      return { ...state, unblockUserLoading: false };

    case types.SEARCH_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };



    //Search By Category
    case types.SEARCH_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        reportsListingAllData: {
          ...state.reportsListingAllData,
          docs: payload.result.docs,
        },
      };

    case types.SEARCH_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResults: payload,
        reportsListingAllData: {
          ...state.reportsListingAllData,
          docs: payload.result.docs,
        },
      };


    case types.SEARCH_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default ReportsReducer;
