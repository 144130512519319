import axios from "axios";
import { BASEURL } from "../Constants";
const token = window.sessionStorage.getItem("token");
export const fetchCitiesOptions = async (
  searchQuery,
  countryNameISO,
  setResponseData
) => {
  console.log("fetching data", {
    searchQuery,
    countryNameISO,
    setResponseData,
  });
  const apiEndpoint = `${BASEURL}/search-city`;
  const headers = {
    "Cache-Control": "no-cache",
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  axios
    .post(
      apiEndpoint,
      {
        city: searchQuery,
        countryCode: countryNameISO,
      },
      { headers }
    )
    .then((response) => {
      console.log("Response:", response);
      setResponseData(response.data);

      //   setSearchCityData(response.data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
