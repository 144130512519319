import {
  Avatar,
  Modal,
  Pagination,
  Table,
  Tooltip,
  Select,
  Button,
} from "antd";
import UserLogo from "../../../../../Assets/Users/user.png";
import { LocationIcon } from "../../../../SharedComponents/icons/icons";
import { useState } from "react";
import UserDetail from "./UserDetail";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { types } from "../../../../store/action_types";

import { BASEURL } from "../../../../Constants";
import { message } from "antd";
import AddOrEditAdmin from "../../../../SharedComponents/AddOrEditAdmin";
import { UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import SearchBarPro from "../../../../SharedComponents/SearchBarPro";

const { Option } = Select;
const token = window.sessionStorage.getItem("token");

const ShowType = {
  ACTIVE: "Active",
  DELETED: "Deleted",
  BLOCKED: "Blocked",
  DltOrBlk: "DeleteOrBlocked",
};

const UserCardData = () => {
  const dispatch = useDispatch();
  const { allUserData, userDetailData, allUserDataLoading } = useSelector(
    (state) => state.UserReducer
  );
  const { searchTextUsers } = useSelector((state) => ({
    searchTextUsers: state.QuestReducer.searchText,
  }));
  const [isOpenAdminModal, setIsOpenAdminModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userDetailModal, setUserDetailModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [restoreUserModal, setRestoreUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showType, setShowType] = useState(ShowType.ACTIVE);

  const handleCloseUserDetail = () => {
    setUserDetailModal(false);
  };

  const isDeleted = showType === ShowType.DELETED 
  const isBlocked = showType === ShowType.BLOCKED 
  const isInactive = showType === ShowType.DltOrBlk

  useEffect(() => {
    dispatch({
      type: types.GetAllUserData,
      payload: {
        page: 1,
        limit: pageLimit,
        searchText: searchText ? searchText : "",
        isBlocked,
        isDeleted,
        isInactive
      },
    });
  }, [showType]);

  const pageChanged = (page) => {
    setCurrentPage(page);
    dispatch({
      type: types.GetAllUserData,
      payload: {
        page,
        limit: pageLimit,
        searchText: searchText ? searchText : "",
        isBlocked,
        isDeleted,
        isInactive
      },
    });
  };

  const handleBlockUser = (isBlock, userId) => {
    dispatch({
      type: types.UpdateUserStatus,
      payload: {
        isBlock,
        userId,
        currentPage,
        pageLimit: pageLimit,
        searchText: searchText ? searchText : "",
        isBlocked,
        isDeleted,
        isInactive
      },
    });
  };

  const handleDeleteUser = (userId) => {
    dispatch({
      type: types.DeleteUser,
      payload: {
        userId,
        currentPage,
        pageLimit: pageLimit,
        searchText: searchText ? searchText : "",
        isBlocked,
        isDeleted,
        isInactive
      },
    });
  };

  const handleRestoreUser = (userId) => {
    dispatch({
      type: types.RestoreUser,
      payload: {
        userId,
        currentPage,
        pageLimit: pageLimit,
        searchText: searchText ? searchText : "",
        isBlocked,
        isDeleted,
        isInactive
      },
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setCurrentPage(1);
    dispatch({
      type: types.GetAllUserData,
      payload: {
        searchText: searchText,
        page: 1,
        limit: pageLimit,
        isBlocked,
        isDeleted,
        isInactive
      },
    });
  };

  const handlePerPageChange = (value) => {
    setPageLimit(value);
    dispatch({
      type: types.GetAllUserData,
      payload: {
        page: 1,
        searchText: searchText ? searchText : searchTextUsers,
        limit: value,
        isBlocked,
        isDeleted,
        isInactive
      },
    });
  };

  const handleConfirmRestore = () => {
    dispatch({
      type: types.RestoreUser,
      payload: {
        userId: selectedUser,
        currentPage,
        pageLimit: pageLimit,
        searchText: searchText ? searchText : "",
        isBlocked,
        isDeleted,
        isInactive
      },
    });
    setRestoreUserModal(false);
  };

  const downloadUsers = () => {
    if (currentPage <= 0) {
      message.error("Invalid page number");
      return;
    }

    const dataToDownload = allUserData?.result?.docs || [];

    if (dataToDownload.length === 0) {
      message.info("No data available to download");
      return;
    }

    const formattedData = dataToDownload.map((item) => ({
      user: item?.nickName ? item?.nickName : "N/A",
      createdAt: item?.createdAt ? item?.createdAt : "N/A",
      "Wallet Address": item?.walletAddress ? item?.walletAddress : "N/A",
      country: item?.countryName ? item?.countryName : "N/A",
      "Country Code": item.countryCode ? item?.countryCode : "N/A",
      city: item?.city ? item?.city : "N/A",
      level: item?.level ? item?.level : "N/A",
      userXP: item?.userXp?.totalXps ? item?.userXp?.totalXps : "N/A",
      monthlyXps: item?.userXp?.monthlyXps ? item?.userXp?.monthlyXps : "N/A",
      weeklyXps: item?.userXp?.weeklyXps ? item?.userXp?.weeklyXps : "N/A",
      todayXps: item?.userXp?.todayXps ? item?.userXp?.todayXps : "N/A",
      email: item?.email ? item?.email : "N/A",
      id: item?.walletAddress ? item?.walletAddress : "N/A",
      currentLocationCountryName: item?.currentLocation?.countryName
        ? item?.currentLocation?.countryName
        : "N/A",
      currentLocationCountryCode: item?.currentLocation?.countryCode
        ? item?.currentLocation?.countryCode
        : "N/A",
      currentLocationCity: item?.currentLocation?.city
        ? item?.currentLocation?.city
        : "N/A",
      currentLocationLatitude: item?.currentLocation?.latitude
        ? item?.currentLocation?.latitude
        : "N/A",
      currentLocationLongitude: item?.currentLocation?.longitude
        ? item?.currentLocation?.longitude
        : "N/A",
      id: item?.deviceId ? item?.deviceId : "N/A",
      deviceId: item?.deviceId ? item?.deviceId : "N/A",
      imageUrl: item?.imageUrl ? item?.imageUrl : "N/A",
      isBlocked: item?.isBlocked ? item?.isBlocked : "N/A",
      isBlockedByAdmin: item?.isBlockedByAdmin ? item?.isBlockedByAdmin : "N/A",
      isDeleted: item?.isDeleted ? item?.isDeleted : "N/A",
      phoneCountryCode: item?.phone?.phoneCountryCode
        ? item?.phone?.phoneCountryCode
        : "N/A",
      phoneCode: item?.phone?.phoneCode ? item?.phone?.phoneCode : "N/A",
      phoneNumber: item?.phone?.phoneNumber ? item?.phone?.phoneNumber : "N/A",
      fullPhoneNumber: item?.phone?.fullPhoneNumber
        ? item?.phone?.fullPhoneNumber
        : "N/A",
      role: item?.role ? item?.role : "N/A",
      socialMediaLinksFaceBook: item?.socialMediaLinks?.faceBook
        ? item?.socialMediaLinks?.faceBook
        : "N/A",
      socialMediaLinksTwitter: item?.socialMediaLinks?.twitter
        ? item?.socialMediaLinks?.twitter
        : "N/A",
      userCompletedTasks: item?.userCompletedTasks
        ? item?.userCompletedTasks
        : "N/A",
      userTotalNfts: item?.userTotalNfts ? item?.userTotalNfts : "N/A",
    }));

    const csvContent = [
      Object.keys(formattedData[0]).join(","),
      ...formattedData.map((row) => Object.values(row).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Users.csv";
    link.click();
  };

  const downloadAllUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASEURL}admin/user/allUsersForPDF`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const data = await response.json();
      const dataToDownload = data?.result || [];
      const formattedData = dataToDownload.map((item) => ({
        user: item?.nickName ? item?.nickName : "N/A",
        createdAt: item?.createdAt ? item?.createdAt : "N/A",
        "Wallet Address": item?.walletAddress ? item?.walletAddress : "N/A",
        country: item?.countryName ? item?.countryName : "N/A",
        "Country Code": item.countryCode ? item?.countryCode : "N/A",
        city: item?.city ? item?.city : "N/A",
        level: item?.level ? item?.level : "N/A",
        userXP: item?.userXp?.totalXps ? item?.userXp?.totalXps : "N/A",
        monthlyXps: item?.userXp?.monthlyXps ? item?.userXp?.monthlyXps : "N/A",
        weeklyXps: item?.userXp?.weeklyXps ? item?.userXp?.weeklyXps : "N/A",
        todayXps: item?.userXp?.todayXps ? item?.userXp?.todayXps : "N/A",
        email: item?.email ? item?.email : "N/A",
        id: item?.walletAddress ? item?.walletAddress : "N/A",
        currentLocationCountryName: item?.currentLocation?.countryName
          ? item?.currentLocation?.countryName
          : "N/A",
        currentLocationCountryCode: item?.currentLocation?.countryCode
          ? item?.currentLocation?.countryCode
          : "N/A",
        currentLocationCity: item?.currentLocation?.city
          ? item?.currentLocation?.city
          : "N/A",
        currentLocationLatitude: item?.currentLocation?.latitude
          ? item?.currentLocation?.latitude
          : "N/A",
        currentLocationLongitude: item?.currentLocation?.longitude
          ? item?.currentLocation?.longitude
          : "N/A",
        id: item?.deviceId ? item?.deviceId : "N/A",
        deviceId: item?.deviceId ? item?.deviceId : "N/A",
        imageUrl: item?.imageUrl ? item?.imageUrl : "N/A",
        isBlocked: item?.isBlocked ? item?.isBlocked : "N/A",
        isBlockedByAdmin: item?.isBlockedByAdmin
          ? item?.isBlockedByAdmin
          : "N/A",
        isDeleted: item?.isDeleted ? item?.isDeleted : "N/A",
        phoneCountryCode: item?.phone?.phoneCountryCode
          ? item?.phone?.phoneCountryCode
          : "N/A",
        phoneCode: item?.phone?.phoneCode ? item?.phone?.phoneCode : "N/A",
        phoneNumber: item?.phone?.phoneNumber
          ? item?.phone?.phoneNumber
          : "N/A",
        fullPhoneNumber: item?.phone?.fullPhoneNumber
          ? item?.phone?.fullPhoneNumber
          : "N/A",
        role: item?.role ? item?.role : "N/A",
        socialMediaLinksFaceBook: item?.socialMediaLinks?.faceBook
          ? item?.socialMediaLinks?.faceBook
          : "N/A",
        socialMediaLinksTwitter: item?.socialMediaLinks?.twitter
          ? item?.socialMediaLinks?.twitter
          : "N/A",
        userCompletedTasks: item?.userCompletedTasks
          ? item?.userCompletedTasks
          : "N/A",
        userTotalNfts: item?.userTotalNfts ? item?.userTotalNfts : "N/A",
      }));
      const csvContent = [
        Object.keys(formattedData[0]).join(","),
        ...formattedData.map((row) => Object.values(row).join(",")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      console.log("<==blob");
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "AllUsers.csv";
      link.click();
    } catch (error) {
      console.error("Error fetching or processing user data:", error);
      message.error("Failed to download user data");
    } finally {
      setLoading(false);
    }
  };

  const poolsColumns = [
    {
      title: "",
      dataIndex: "",
      key: "icon",
      render: (item) => {
        const isLocationDetermined =
          !!item.countryName && !!item.countryCode && !!item.city;
        const isExistCurrentLocation =
          !!item?.currentLocation?.countryName &&
          !!item?.currentLocation?.countryCode &&
          !!item?.currentLocation?.city;

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div>
              <Avatar
                size={44}
                src={item.imageUrl || UserLogo}
                icon={!item.imageUrl && <UserOutlined />}
                style={{ marginRight: 16 }}
              />
            </div>
            <div>
              <strong style={{ marginRight: "10px", width: "200px" }}>
                {item.nickName}
              </strong>
              <div>
                <LocationIcon />{" "}
                <span
                  style={{
                    font: "Montserrat",
                    fontWeight: "400",
                    lineHeight: "14.63px",
                    color: "#78633A",
                  }}
                >
                  {isLocationDetermined
                    ? `${item.countryName}, ${item.countryCode}, ${item.city}`
                    : isExistCurrentLocation
                    ? `${item?.currentLocation?.countryName}, ${item?.currentLocation?.countryCode}, ${item?.currentLocation?.city}`
                    : ""}
                </span>
                <span
                  style={{
                    font: "Montserrat",
                    fontWeight: "400",
                    lineHeight: "14.63px",
                    color: "gray",
                  }}
                >
                  {isExistCurrentLocation && !isLocationDetermined
                    ? " (current location)"
                    : !isExistCurrentLocation && !isLocationDetermined
                    ? "no location data..."
                    : ""}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Wallet",
      dataIndex: "walletAddress",
      key: "walletAddress",
      width: 100,
      render: (item) => (
        <Tooltip title={item && item}>
          {`${item ? `${item.substring(0, 6)}...${item.slice(-4)}` : ""}`}
        </Tooltip>
      ),
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: 50,
      render: (item) => <div style={{ textAlign: "center" }}>{item}</div>,
    },
    {
      title: "User XP",
      dataIndex: "userXp",
      key: "xp",
      width: 100,
      render: (item) => {
        const formattedXP = item?.totalXps ? item?.totalXps.toFixed(2) : 0;
        return <div>{formattedXP}</div>;
      },
    },
    {
      title: "Joined",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (item) => {
        const createdDate = new Date(item);
        const currentDate = new Date();
        const daysSinceCreation = Math.floor(
          (currentDate - createdDate) / (1000 * 60 * 60 * 24)
        );

        const daysAgo =
          daysSinceCreation > 0 ? `${daysSinceCreation} days ago` : "Today";
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>{dayjs(item).format("DD-MM-YYYY")}</div>
            <div>({daysAgo})</div>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "",
      key: "Status",
      width: 150,
      render: (item) => (
        <div
          className={`${
            item.isDeleted
              ? "typeofinactive"
              : item.isBlocked
              ? "typeofblocked"
              : "typeofclueactive"
          }`}
        >
          {" "}
          {item.isDeleted ? "Deleted" : item.isBlocked ? "Blocked" : "Active"}
        </div>
      ),
    },
  ];

  const handleOnRowClick = (item) => {
    return {
      onClick: () => {
        if (item.isDeleted) {
          setSelectedUser(item._id);
          setRestoreUserModal(true);
        } else {
          dispatch({
            type: types.GetUserDetail,
            payload: item?._id,
          });
          setUserDetailModal(true);
        }
      },
      style: { cursor: "pointer" },
    };
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <span className="questheading">Users</span>
        <Select
          value={showType}
          onChange={(e) => {
            setShowType(e);
          }}
          style={{ width: 210 }}
        >
          {[
            { id: ShowType.ACTIVE, value: "Active" },
            { id: ShowType.DltOrBlk, value: "Inactive" },
            { id: ShowType.DELETED, value: "Only Deleted" },
            { id: ShowType.BLOCKED, value: "Only Blocked" },
          ].map((el) => (
            <Option style={{ width: "200px" }} key={el.id} value={el.id}>
              {el.value}
            </Option>
          ))}
        </Select>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SearchBarPro
          searchText={searchText}
          setSearchText={setSearchText}
          onSearch={handleSearch}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="primary"
            style={{ margin: "5px", background: "#78633a" }}
            onClick={() => {
              setIsOpenAdminModal(true);
            }}
          >
            Create new Admin
          </Button>
          <Button
            type="primary"
            style={{ margin: "5px", background: "#78633a", width: "120px" }}
            onClick={downloadUsers}
          >
            Download{" "}
          </Button>
          <Button
            type="primary"
            style={{ background: "#78633a", width: "120px" }}
            onClick={downloadAllUsers}
            loading={loading}
          >
            Download All{" "}
          </Button>
        </div>
      </div>
      <Table
        pagination={false}
        columns={poolsColumns}
        dataSource={allUserData?.result?.docs}
        loading={loading || allUserDataLoading}
        onRow={handleOnRowClick}
      />
      <div className="d-flex justify-content-end mt20" style={{ gap: 30 }}>
        <Pagination
          current={currentPage}
          pageSize={pageLimit}
          total={allUserData?.result?.totalDocs}
          onChange={(page) => pageChanged(page)}
          showSizeChanger={false}
        />
        <Select
          value={pageLimit}
          onChange={handlePerPageChange}
          style={{ width: "80px" }}
        >
          {[10, 20, 30, 40].map((el) => (
            <Option key={el} value={el}>
              {el}
            </Option>
          ))}
        </Select>
      </div>
      <Modal
        title={false}
        footer={false}
        centered={true}
        open={userDetailModal}
        onCancel={handleCloseUserDetail}
        width={800}
      >
        <UserDetail
          userDetailData={userDetailData}
          toggleBlockUser={(isBlock, userId) =>
            handleBlockUser(isBlock, userId)
          }
          deleteUser={(userId) => handleDeleteUser(userId)}
          restoreUser={(userId) => handleRestoreUser(userId)}
        />
      </Modal>
      <Modal
        title="Restore User"
        centered={true}
        visible={restoreUserModal}
        onCancel={() => setRestoreUserModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setRestoreUserModal(false)}>
            Cancel
          </Button>,
          <Button key="restore" type="primary" onClick={handleConfirmRestore}>
            Restore
          </Button>,
        ]}
      >
        <p>Do you want to restore this user?</p>
      </Modal>
      <Modal
        title={false}
        footer={false}
        centered={true}
        open={isOpenAdminModal}
        onCancel={() => {
          setIsOpenAdminModal(false);
        }}
        width={500}
      >
        <AddOrEditAdmin
          handleCloseModal={() => {
            setIsOpenAdminModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default UserCardData;
