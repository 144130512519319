import { DatePicker, Switch } from "antd";
import { Field, Formik } from "formik";
import moment from "moment";
import { types } from "../../../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomTextArea from "../../../../SharedComponents/CustomTextArea";
import { Constants } from "../../../../Constants";
import { CalanderIcon } from "../../../../SharedComponents/icons/icons";
import { useEffect, useRef } from "react";

const UpdateVotes = ({
  editRecordData,
  handleCloseEditVotesModal,
  currentPage,
}) => {
  const formikRef = useRef();
  var minimumDate = new Date();
  const dispatch = useDispatch();
  const { updateVotesloading, editVotesModalOpen } = useSelector((state) => ({
    updateVotesloading: state.VotesReducer.updateVotesloading,
    editVotesModalOpen: state.VotesReducer.editVotesModalOpen,
  }));
  const handleReset = (resetForm) => {
    resetForm();
    handleCloseEditVotesModal();
  };
  useEffect(() => {
    if (!editVotesModalOpen) {
      formikRef.current.resetForm();
    }
  }, [editVotesModalOpen]);
  return (
    <>
      <div className="modaltitle">Edit Vote</div>
      <div>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={{
            votesname: editRecordData?.question,
            startdate: editRecordData?.startTime,
            enddate: editRecordData?.endTime,
            multiselectStatus: editRecordData?.multipleAllowed,

            firstOption: editRecordData?.choices[0],
            secondOption: editRecordData?.choices[1],
            thirdOption: editRecordData?.choices[2],
            fourthOption: editRecordData?.choices[3],
          }}
          onSubmit={(values) => {
            let newStartDate = new Date(values?.startdate);
            let startdateString = moment(newStartDate).toISOString();
            let newendDate = new Date(values?.enddate);
            let enddateString = moment(newendDate).toISOString();
            let data = {
              updateVotesData: {
                question: values.votesname,
                pollId: editRecordData?._id,
                startTime: startdateString,
                endTime: enddateString,
                multipleAllowed: JSON.stringify(values?.multiselectStatus),
                choices: [
                  values?.firstOption,
                  values?.secondOption,
                  values?.thirdOption,
                  values?.fourthOption,
                ],
              },
              currentPage: currentPage,
            };

            dispatch({
              type: types.VotesUpdateRequest,
              payload: data,
            });
          }}
          validationSchema={Constants.VotesUpdateSchema}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            resetForm,
          }) => (
            <div className="add-votes-modal-wrapper mt40">
              <div className=" ">
                <div className=" mb-3 ">
                  <Field name="votesname">
                    {({ meta }) => (
                      <CustomTextArea
                        label={"Title"}
                        required={true}
                        rows={"2"}
                        name={"votesname"}
                        error={meta.error ? meta.error : null}
                        onChange={handleChange("votesname")}
                        placeHolderText={"Enter votes name"}
                        value={values.votesname}
                        maxLength={200}
                        onBlur={handleBlur("votesname")}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-12 col-sm-12 col-md-12 mb-3 ">
                  <div>
                    <Field name="firstOption">
                      {({ meta }) => (
                        <CustomTextArea
                          label={"Options"}
                          onChange={handleChange("firstOption")}
                          required={true}
                          maxLength={50}
                          rows={"2"}
                          name={"firstOption"}
                          error={meta.error ? meta.error : null}
                          placeHolderText={"Enter option1 "}
                          value={values?.firstOption}
                          onBlur={handleBlur("firstOption")}
                        />
                      )}
                    </Field>
                    <div className=" mt10">
                      <Field name="secondOption">
                        {({ meta }) => (
                          <CustomTextArea
                            label={""}
                            maxLength={50}
                            onChange={handleChange("secondOption")}
                            rows={"2"}
                            name={"fOption"}
                            error={meta.error ? meta.error : null}
                            placeHolderText={"Enter option2 "}
                            value={values?.secondOption}
                            onBlur={handleBlur("secondOption")}
                          />
                        )}
                      </Field>
                    </div>
                    <div className=" mt10">
                      <Field name="thirdOption">
                        {({ meta }) => (
                          <>
                            <CustomTextArea
                              label={""}
                              maxLength={50}
                              onChange={handleChange("thirdOption")}
                              rows={"2"}
                              name={"thirdOption"}
                              error={meta.touched ? meta.error : null}
                              placeHolderText={"Enter option3 "}
                              value={values?.thirdOption}
                              onBlur={handleBlur("thirdOption")}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className=" mt10">
                      <Field name="fourthOption">
                        {({ meta }) => (
                          <CustomTextArea
                            label={""}
                            onChange={handleChange("fourthOption")}
                            rows={"2"}
                            maxLength={50}
                            name={"fourthOption"}
                            error={meta.error ? meta.error : null}
                            placeHolderText={"Enter option4 "}
                            value={values?.fourthOption}
                            onBlur={handleBlur("fourthOption")}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className=" mb-3 ">
                  <label className="d-flex">Start Date *</label>
                  <Field name="startdate">
                    {({ meta }) => (
                      <>
                        <DatePicker
                          className="w-100"
                          showTime
                          name="startdate"
                          onChange={(dateString) =>
                            setFieldValue("startdate", dateString)
                          }
                          value={dayjs(values.startdate)}
                          inputReadOnly={true}
                          showNow={false}
                          disabledDate={(current) =>
                            !current || current.isBefore(minimumDate)
                          }
                          allowClear={false}
                          suffixIcon={<CalanderIcon />}
                        />
                        <p className="errorText">
                          {" "}
                          {meta.error ? meta.error : null}
                        </p>
                      </>
                    )}
                  </Field>
                </div>
                <div className=" mb-3 ">
                  <label className="d-flex">End Date *</label>
                  <Field name="enddate">
                    {({ meta }) => (
                      <>
                        <DatePicker
                          className="w-100"
                          inputReadOnly={true}
                          showNow={false}
                          showTime
                          name="enddate"
                          onChange={(dateString) =>
                            setFieldValue("enddate", dateString)
                          }
                          value={dayjs(values.enddate)}
                          disabledDate={(current) =>
                            !current || current.isBefore(minimumDate)
                          }
                          allowClear={false}
                          suffixIcon={<CalanderIcon />}
                        />
                        <p className="errorText">
                          {" "}
                          {meta.error ? meta.error : null}
                        </p>
                      </>
                    )}
                  </Field>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <label className="d-flex">Multi-select</label>
                  </div>
                  <div>
                    <Field name="multiselectStatus">
                      {({ meta }) => (
                        <Switch
                          defaultChecked={values.multiselectStatus}
                          checked={values.multiselectStatus}
                          onChange={(val) =>
                            setFieldValue("multiselectStatus", val)
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <div className="d-flex ">
                <div>
                  <button
                    type="submit"
                    className="addbtn border p1040 bgprimary"
                    onClick={() => handleSubmit()}
                  >
                    {updateVotesloading ? (
                      <div className="whitespinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                      </div>
                    ) : (
                      <>Publish</>
                    )}
                  </button>
                </div>
                <div className="ml9">
                  <button
                    type="submit"
                    className="cancelbtn border p1040 "
                    onClick={() => handleReset(resetForm)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};
export default UpdateVotes;
