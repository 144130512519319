import { types } from "../action_types";

const INITIAL_STATE = {
  allNFTCollectionData:[],
  uploadcollectionimageAllData:[],
  uploadCollectionImageloading: false,
  allNFTCollectionloading:false,
  uploadNFTMetaDataAllData:[],
  uploadNFTMetaDataloading: false,
  searchText: null
};

const CollectionReducer = (state = INITIAL_STATE, { type, payload }) => {

  switch (type) {


 //get All NFT Collection
 case types.GetAllNFTCollectionRequest:
  return { ...state, allNFTCollectionloading: true};
case types.GetAllNFTCollectionRequestSuccess:
  return { ...state, allNFTCollectionData: payload.responsedata, allNFTCollectionloading: false , searchText: payload.actualSearchText || ''};
case types.GetAllNFTCollectionRequestFailure:
  return { ...state, allNFTCollectionloading: false };

    //Upload Collection/NFT Image
    case types.UploadCollectionImageRequest:
      return { ...state, uploadCollectionImageloading: true };
    case types.UploadCollectionImageRequestSuccess:
      return {
        ...state,
        uploadcollectionimageAllData: payload,
        uploadCollectionImageloading: false,
      };
    case types.UploadCollectionImageRequestFailure:
      return { ...state, uploadCollectionImageloading: false };

       //Upload NFT MetaData
    case types.UploadNFTMetaDataRequest:
      return { ...state, uploadNFTMetaDataloading: true };
    case types.UploadNFTMetaDataRequestSuccess:
      return {
        ...state,
        uploadNFTMetaDataAllData: payload,
        uploadNFTMetaDataloading: false,
      };
    case types.UploadNFTMetaDataRequestFailure:
      return { ...state, uploadNFTMetaDataloading: false };


    default:
      return state;
  }
};
export default CollectionReducer;
