import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import MultiGoogleMapComponent from "../../Screens/LandingScreens/Tasks/MultiMap";
import { types } from "../../store/action_types";

const ViewProducts = ({ handleCloseModal, currentPool, page }) => {
  const {
    products: productsState,
    productLoading,
    editProductsLoading,
  } = useSelector((state) => state.CompaniesManagementReducer);
  const dispatch = useDispatch();

  const [products, setProducts] = useState(null);
  const [changedProducts, setChangedProducts] = useState(null);
  const [mapKey, setMapKey] = useState(0);
  const [error, setError] = useState(null);
  const loading = productLoading || editProductsLoading;

  const handleSubmit = () => {
    const updatedBodies = changedProducts?.map((product) => ({
      productId: product._id,
      countryCode: product.countryCode,
      city: product.city,
      longitude: product.position.lng,
      latitude: product.position.lat,
    }));

    dispatch({
      type: types.EditProductsRequest,
      payload: {
        updatedBodies,
        handleCloseModal,
        currentCompanyId: currentPool.company,
        page,
      },
    });
  };

  useEffect(() => {
    const modifiedProducts = productsState?.map((product) => ({
      ...product,
      title: "",
      name: "",
      error: false,
      position: { lat: product.latitude, lng: product.longitude },
    }));
    setProducts(modifiedProducts);
    setChangedProducts(modifiedProducts);
    setMapKey((prevKey) => prevKey + 1);
  }, [currentPool, productsState]);

  useEffect(() => {
    const changed = products?.filter(
      (product) =>
        product.position.lng !== product.longitude ||
        product.position.lat !== product.latitude
    );
    setChangedProducts(changed);
    setError(products?.some((item) => item.error));
  }, [products]);

  return (
    <div>
      <div className="modal-container">
        <div className="modaltitle">View Products</div>
        {error && <div className="error-message">you are out of the zone</div>}
      </div>

      <div className="map-container">
        <MultiGoogleMapComponent
          key={mapKey}
          updateTask={true}
          mapCenter={{
            lat: currentPool.latitude,
            lng: currentPool.longitude,
          }}
          radius={currentPool?.radius}
          products={products}
          setProducts={setProducts}
          error={!!error}
        />
      </div>

      <div className="button-container">
        <button
          type="submit"
          disabled={loading || !!error || !changedProducts?.length}
          className="addbtn border p1060"
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
          {loading && <Spin className="spin" />}
        </button>
        <button
          type="submit"
          disabled={loading}
          className="cancelbtn border p1060"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ViewProducts;
