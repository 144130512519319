import { types } from "../action_types";

const INITIAL_STATE = {
  allUserData: [],
  allUserDataLoading: false,
  userDetailData: {},
  userDetailDataLoading: false,
  userInventory: {},
  searchText: null
};

const UserReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    //get All NFT Collection
    case types.GetAllUserData:
      return { ...state, allUserDataLoading: true };
    case types.GetAllUserDataRequestSuccess:
      return { ...state, allUserData: payload.responsedata, allUserDataLoading: false, searchText: payload.actualSearchText || '' };
    case types.GetAllUserDataRequestFailure:
      return { ...state, allUserDataLoading: false };

    // User Detail
    case types.GetUserDetail:
      return { ...state, userDetailDataLoading: true };
    case types.GetUserInventory:
      return {
        ...state,
        userInventory: payload
      };
    case types.GetUserDetailRequestSuccess:
      return {
        ...state,
        userDetailData: payload,
        userDetailDataLoading: false,
      };
    case types.GetUserDetailRequestFailure:
      return { ...state, userDetailDataLoading: false };

    // //Upload Collection/NFT Image
    // case types.UploadCollectionImageRequest:
    //   return { ...state, uploadCollectionImageloading: true };
    // case types.UploadCollectionImageRequestSuccess:
    //   return {
    //     ...state,
    //     uploadcollectionimageAllData: payload,
    //     uploadCollectionImageloading: false,
    //   };
    // case types.UploadCollectionImageRequestFailure:
    //   return { ...state, uploadCollectionImageloading: false };

    //    //Upload NFT MetaData
    // case types.UploadNFTMetaDataRequest:
    //   return { ...state, uploadNFTMetaDataloading: true };
    // case types.UploadNFTMetaDataRequestSuccess:
    //   return {
    //     ...state,
    //     uploadNFTMetaDataAllData: payload,
    //     uploadNFTMetaDataloading: false,
    //   };
    // case types.UploadNFTMetaDataRequestFailure:
    //   return { ...state, uploadNFTMetaDataloading: false };

    default:
      return state;
  }
};
export default UserReducer;
