import { Pagination, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { types } from "../../../../store/action_types";
import AddVotes from "../AddVotes";
import UpdateVotes from "../EditVotes";
import ViewVotes from "../ViewVotes";
import { EyeFilled } from "@ant-design/icons";
import {
  DeleteIcon,
  EditIcon,
  PlusIcon,
} from "../../../../SharedComponents/icons/icons";
import SearchBar from '../../../../SharedComponents/SearchBar'

const { confirm } = Modal;

const VotesListing = () => {
  const dispatch = useDispatch();

  const [editRecordData, setEditRecordData] = useState();
  const [render, setRender] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch({
      type: types.VotesListingRequest,
      payload: 1,
    });
  }, []);  

  let currentDate = new Date();
  let startDateComparison;
  let endDateComparison;

  const {
    votesListingAllData,
    createVotesModalOpen,
    editVotesModalOpen,
    viewVotesModalOpen,
    viewVoteloading,
  } = useSelector((state) => ({
    votesListingAllData: state.VotesReducer.votesListingAllData?.result?.polls,
    createVotesModalOpen: state.VotesReducer.createVotesModalOpen,
    editVotesModalOpen: state.VotesReducer.editVotesModalOpen,
    viewVotesModalOpen: state.VotesReducer.viewVotesModalOpen,
    viewVoteloading: state.VotesReducer.viewVoteloading,
  }));

  const columns = [
    {
      title: "№",
      dataIndex: "_id",
      key: "_id",
      width: "10%",

      render: (value, item, index) => (
        <>
          <div className="table-column-sr-no">
            {currentPage > 1 && currentPage - 1}
            {index + 1}
          </div>
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "question",
      key: "question",
      width: "30%",
      render: (item) => <div className="table-column-body-data">{item}</div>,
    },
    {
      title: "Voting start date/time",
      dataIndex: "startTime",
      key: "startTime",
      width: "20%",
      render: (startTime) => (
        <div className=" w-100">
          {" "}
          {moment(startTime).format("DD-MM-YYYY")}
          <br />
          <br />
          {new Date(startTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </div>
      ),
    },
    {
      title: "Voting End date/time",
      dataIndex: "endTime",
      key: "endTime",
      width: "20%",
      render: (endTime, item) => (
        <div className=" w-100">
          {moment(endTime).format("DD-MM-YYYY")}
          <br />
          {new Date(endTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      width: "20%",
      key: "x",
      render: (item, value) => (
        ((startDateComparison = new Date(item?.startTime)),
        (endDateComparison = new Date(item?.endTime))),
        (
          <>
            <div className="d-flex ">
              {startDateComparison.getTime() > currentDate.getTime() ? (
                <>
                  <div
                    onClick={() => handleEditRecord(item)}
                    className="ml10 cursor"
                  >
                    {" "}
                    <EditIcon />
                  </div>
                </>
              ) : (
                <div
                  onClick={() => handleViewRecord(item)}
                  className="ml10 cursor"
                >
                  <EyeFilled
                    style={{
                      fontSize: "18px",
                      color: "#78633A",
                      paddingTop: "2px",
                    }}
                  />
                </div>
              )}

              {!(
                startDateComparison.getTime() <= currentDate.getTime() &&
                endDateComparison.getTime() > currentDate.getTime()
              ) && (
                <div
                  onClick={() => handlDeleteRecord(item)}
                  className="ml10 cursor"
                >
                  {" "}
                  <DeleteIcon />
                </div>
              )}
            </div>
          </>
        )
      ),
    },
  ];

  const handleEditRecord = (item) => {
    setEditRecordData(item);
    dispatch({
      type: types.EditVotesOpenModalRequest,
    });
  };

  const handleViewRecord = (item) => {
    dispatch({
      type: types.VoteViewRequest,
      payload: item,
    });
  };
  const handlDeleteRecord = (item) => {
    confirm({
      title: "Delete Vote",
      content: "Are you sure to delete this Vote?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        let data = {
          id: item._id,
          currentPage: currentPage,
        };
        dispatch({
          type: types.VoteDeleteRequest,
          payload: data,
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onChangeVotesPagination = (page) => {
    setCurrentPage(page);
    dispatch({
      type: types.VotesListingRequest,
      payload: page,
    });
  };

  const openCreateVotesModal = () => {
    dispatch({
      type: types.CreateVotesOpenModalRequest,
    });
  };
  const handleCloseAddVotesModal = () => {
    dispatch({
      type: types.CreateVotesCloseModalRequest,
    });
  };
  const handleCloseViewVotesModal = () => {
    dispatch({
      type: types.ViewVotesCloseModalRequest,
    });
  };
  const handleCloseEditVotesModal = () => {
    setEditRecordData("");
    dispatch({
      type: types.EditVotesCloseModalRequest,
    });
    setRender(!render);
  };

  const handleSearch = (searchText) => {
    console.log('searchText...', searchText);
    dispatch({
      type: types.VotesListingRequest,
      payload: {
        searchText: searchText,
        currentPage: currentPage,
      },
    });
  };
  

  return (
    <>
      {viewVoteloading ? (
        <div className="loader">
          <Spin tip="Loading..." style={{ color: "#78633A" }}></Spin>
        </div>
      ) : (
        <>
          <div className="d-flex  justify-content-between align-items-center mb-3">
      <SearchBar onSearch={handleSearch} />
            <div className="votes-heading">Votes</div>
            <div>
              <div
                className="addbtn votes-add-btn"
                onClick={openCreateVotesModal}
              >
                {" "}
                Add Votes <PlusIcon />{" "}
              </div>
            </div>
          </div>
          <div className="test1">
            <Table
              pagination={false}
              columns={columns}
              dataSource={votesListingAllData?.docs}
            />

            <div
              className="d-flex justify-content-end mt20"
              style={{
                position: "sticky",
                bottom: "2%",
                right: "2%",
                zIndex: "100",
              }}
            >
              <Pagination
                current={currentPage}
                pageSize={10}
                total={votesListingAllData?.totalDocs}
                showSizeChanger={false}
                onChange={(page) => onChangeVotesPagination(page)}
              />
            </div>
          </div>
        </>
      )}
      {/************************   Add Votes Modal ***************** */}
      <div className="d-flex justify-content-center">
        <Modal
          title={false}
          footer={false}
          open={createVotesModalOpen}
          onCancel={handleCloseAddVotesModal}
          closable={false}
          width={1200}
          className="ml18"
        >
          <AddVotes handleCloseAddVotesModal={handleCloseAddVotesModal} />
        </Modal>
      </div>

      {/************************   Edit Announcement Modal ***************** */}

      <Modal
        title={false}
        footer={false}
        closable={false}
        open={editVotesModalOpen}
        maskClosable={false}
        onCancel={handleCloseEditVotesModal}
        width={1200}
        className="ml18"
      >
        <UpdateVotes
          handleCloseEditVotesModal={handleCloseEditVotesModal}
          editRecordData={editRecordData}
          currentPage={currentPage}
        />
      </Modal>

      {/************************   View Votes Modal ***************** */}
      <div className="d-flex justify-content-center">
        <Modal
          title={false}
          footer={false}
          open={viewVotesModalOpen}
          onCancel={handleCloseViewVotesModal}
          width={1200}
          className="ml18"
        >
          <ViewVotes handleCloseViewVotesModal={handleCloseViewVotesModal} />
        </Modal>
      </div>
    </>
  );
};
export default VotesListing;
