import { Card, Checkbox, Modal, Select, Switch, message } from "antd";
import { useEffect, useState } from "react";
import { types } from "../../../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import Description from "./description/description";
import { gameModeData } from "../../../../Utils/constants";

let allNFTCollectionDataStored;
const PrizePool = () => {
  const dispatch = useDispatch();

  const { allNFTCollectionData } = useSelector((state) => ({
    allNFTCollectionData:
      state.CollectionReducer?.allNFTCollectionData?.result?.nftCollection,
  }));
  const [status, setStatus] = useState(true);
  const [type, setType] = useState("nft");
  const [title, setTitle] = useState();
  const [activeListing, setActiveListing] = useState({});
  const [selectedCardArray, setSelectedCardArray] = useState([]);
  const [render, setrender] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const [modalData, setModalData] = useState({});
  const [modalDataDesription, setModalDataDesription] = useState("");
  const [gameMode, setGameMode] = useState("");
  const [data, setData] = useState();
  const [selectionType, setSelectionType] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const isFormValid = () => {
    return !!title && !!gameMode && selectedCardArray.length > 0;
  };

  useEffect(() => {
    setFormSubmitted(false);
  }, [title, gameMode, selectedCardArray]);

  const handleCloseModal = () => {
    setOpenDetailModal(false);
  };

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  useEffect(() => {
    allNFTCollectionDataStored = null;
    dispatch({
      type: types.GetAllNFTCollectionRequest,
      payload: 1,
      number: 10,
    });
  }, []);

  useEffect(() => {
    if (allNFTCollectionData && !allNFTCollectionDataStored) {
      dispatch({
        type: types.GetAllNFTCollectionRequest,
        payload: 1,
        number: allNFTCollectionData?.totalDocs,
      });
      allNFTCollectionDataStored = allNFTCollectionData;
      return;
    }
    if (allNFTCollectionData && allNFTCollectionDataStored) {
      const updatedData = {
        ...allNFTCollectionData,
        docs: allNFTCollectionData.docs.filter(
          (item) => item.status === "activate"
        ),
      };

      setData(updatedData);
    }
  }, [allNFTCollectionData]);

  let tempArray = [];
  let tempObj = {};
  let length = 0;
  let selectedUniqueData;

  const handleSelectCard = (params, index) => {
    let newObj = activeListing;
    tempArray = selectedCardArray;
    Object.keys(activeListing).forEach(function (key) {
      length += newObj[key]?.length;
    });

    if (newObj[selectionType + "ActiveArray"]) {
      if (newObj[selectionType + "ActiveArray"].includes(index)) {
        Object.keys(newObj).forEach(function (key) {
          if (key.includes(selectionType)) {
            let paramobj = newObj[key].filter((value) => value !== index);
            tempObj[key] = paramobj;

            selectedUniqueData = tempArray.filter(
              (value) => value?._id !== params?._id
            );

            setSelectedCardArray(selectedUniqueData);
          } else {
            tempObj[key] = newObj[key];
          }
        });
        setActiveListing(tempObj);
        setrender(!render);
      } else {
        newObj[selectionType + "ActiveArray"].push(index);
        tempArray?.push(params);
        setSelectedCardArray(tempArray);
        setActiveListing(newObj);
        setrender(!render);
      }
    } else {
      newObj[selectionType + "ActiveArray"] = [index];
      tempArray.push(params);
      setSelectedCardArray(tempArray);
      setActiveListing(newObj);
      setrender(!render);
    }
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      message.error("Please fill all required fields");
      return;
    }

    // if (!title) {
    //   message.error("Please enter a title");
    //   return;
    // }
    // if (selectedCardArray.length === 0) {
    //   message.error("Please select at least one card");
    //   return;
    // }

    let tempNFTSelectedIDS = [];
    if (selectedCardArray?.length > 0) {
      selectedCardArray?.forEach((element) => {
        tempNFTSelectedIDS.push(element?._id);
      });
    }
    let data;
    if (type === "nft") {
      data = {
        title: title,
        rewardTypes: type,
        status: status === true ? "activate" : "de-activate",
        nfts: tempNFTSelectedIDS,
        gameMode: gameMode,
      };
    }
    dispatch({
      type: types.PrizePoolCreationRequest,
      payload: data,
    });
    window.location.replace("/prize-pool-list");
    setFormSubmitted(true);
    message.success("Prize pool created successfully");
  };

  const readMoreClicked = (item, description) => {
    setModalData(item);
    setModalDataDesription(description);
    setOpenDetailModal(true);
  };

  useEffect(() => {
    console.log("data updated", data);
  }, [data]);
  return (
    <>
      <div className="">
        <div className="">
          <div className="prize-pool-wrapper">
            <div>
              <label>Title</label>
              <input
                type="text"
                className="form-control h32 "
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div>
              <label className="d-flex">Game Mode</label>
              <Select
                onChange={(e) => setGameMode(e)}
                placeholder="Select any one"
                options={gameModeData}
                className="w-100"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    "&:hover": { borderColor: "#E5E7EB" }, // border style on hover
                    border: "1px solid #E5E7EB", // default border color
                    // height: "47px",
                    borderRadius: "0.5rem ",
                    boxShadow: "none", // no box-shadow
                  }),
                }}
              />
            </div>

            <Modal
              title={modalData?.companyName}
              open={openDetailModal}
              onOk={handleCloseModal}
              onCancel={handleCloseModal}
            >
              {modalData?.status === "de-activate" ? (
                <div>
                  <div className="  mt16">
                    <div className="d-flex justify-content-center">
                      <img
                        src={modalData?.imageLink}
                        className="border-radius taskavatarimage"
                      />
                    </div>
                    <div className="">
                      <div className="center mt10">
                        <span className="userTitle ">
                          {modalData?.companyName}
                        </span>
                        <div className="d-flex justify-content-center">
                          <div className="prizepoll-inactive-status mt5">
                            {modalData?.status === "de-activate"
                              ? "In Active"
                              : "Active"}
                          </div>
                        </div>
                      </div>

                      <div className="mt10">
                        <div className="location">{modalDataDesription}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="  mt16">
                    <div className="d-flex justify-content-center">
                      <img
                        src={modalData?.imageLink}
                        className="border-radius taskavatarimage"
                      />
                    </div>
                    <div className="">
                      <div className="center mt10">
                        <span className="userTitle ">
                          {modalData?.companyName}
                        </span>
                        <div className="d-flex justify-content-center">
                          <div className="prizepoll-active-status mt5">
                            {modalData?.status === "de-activate"
                              ? "In Active"
                              : "Active"}
                          </div>
                        </div>
                      </div>

                      <div className="mt10">
                        <div>{modalDataDesription}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Modal>

            {(type === "ch-coins" || type === "both") && (
              <>
                <div>
                  <label>Minimum Coins</label>
                  <input type="text" className="form-control h32 " />
                </div>

                <div>
                  <label>Maximum Coins</label>
                  <input type="text" className="form-control h32 " />
                </div>
              </>
            )}

            <div>
              <label>Status</label>
              <div>
                <Switch
                  defaultChecked={status}
                  onChange={(val) => setStatus(val)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="parimarybtn-submit"
              style={{
                width: "230px",
                height: "50px",
              }}
              onClick={() => handleSubmit()}
              // disabled={!isFormValid() || formSubmitted}
              // onClick={() => {
              //   handleSubmit();
              //   setFormSubmitted(true);
              // }}
            >
              <>Submit </>
            </button>
          </div>

          {type !== "ch-coins" && (
            <div className="grid-wrapper">
              {data?.docs.map((item, index) => (
                <div className="mb-3" key={index + (currentPage - 1) * 10}>
                  <Card
                    style={{
                      padding: "0px",
                      // backgroundColor: "red",
                      height: "210px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {item?.status === "de-activate" ? (
                        <Checkbox disabled></Checkbox>
                      ) : (
                        <Checkbox
                          onClick={() =>
                            handleSelectCard(
                              item,
                              index + (currentPage - 1) * 10
                            )
                          }
                        ></Checkbox>
                      )}

                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <img
                              src={item?.imageLink}
                              className="border-radius taskavatarimage"
                            />
                          </div>
                          <div className="">
                            <div className="center mt10">
                              <span className="userTitle ">
                                {item?.companyName}
                              </span>
                              <div className="d-flex justify-content-center">
                                <div
                                  className={`${
                                    item?.status === "de-activate"
                                      ? "prizepoll-inactive-status"
                                      : "prizepoll-active-status"
                                  } mt5`}
                                >
                                  {item?.status === "de-activate"
                                    ? "In Active"
                                    : "Active"}
                                </div>
                              </div>
                            </div>
                            <Description
                              item={item}
                              readmoreClicked={readMoreClicked}
                              key={item.metadataLink}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default PrizePool;
