import React from "react";
import { BrowserRouter } from "react-router-dom";
import MainComponent from "./Components/Routes";
import ScrollToTop from "./Components/Utils/ScrollToTop";
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <MainComponent />
    </BrowserRouter>
  );
}

export default App;
