import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

let remoreLength = 17;
const Description = ({ item, readmoreClicked, descriptionLength }) => {
  const [description, setDescription] = useState("");
  if (descriptionLength) {
    remoreLength = descriptionLength;
  }
  const metaDataFetch = async (link) => {
    const response = await axios.get(link);

    setDescription(response.data.description);
  };
  useEffect(() => {
    console.log("itemmm here ", item);
    metaDataFetch(item.metadataLink);
  }, [item]);
  const textDisplay = (text) => {
    if (text) {
      if (text.length > remoreLength) {
        text = text.slice(0, remoreLength) + "...";
      }
    }
    return text;
  };

  return (
    <div className="mt10">
      <div className="location">{textDisplay(description)}</div>
      {description?.length > remoreLength && !descriptionLength ? (
        <div
          className="read-more"
          onClick={() => {
            readmoreClicked(item, description);
          }}
        >
          Read more
        </div>
      ) : null}
    </div>
  );
};

export default Description;
