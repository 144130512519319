import React, {useEffect, useRef, useState} from "react";
import {DatePicker, Switch, Spin} from "antd";
import {Upload, Button} from "antd";
import {PlusOutlined, EditOutlined} from "@ant-design/icons";
import {ErrorMessage, Field, Formik} from "formik";
import moment from "moment";
import CustomTextInput from "../CustomTextInput";
import {Constants} from "../../Constants";
import {types} from "../../store/action_types";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import CustomToster from "../CustomToaster";
import {allowedExtensions, getExtension, timeToOpenAfterPreviousArrayData} from "../../Utils/constants";
import Select from "react-select";
import countriesCities from "../../../Assets/CountriesandCities/countries_cities.json";

let loading = false;
let boastStyle = {
    display: "flex",
    width: "100px",
    height: "100px",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    border: "1px solid #16161A1A",
    borderRadius: "16px",
};
let boastImageLink = {
    width: "100px",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
};

const AddBoast = ({handleCloseModal}) => {
    const boost_enum = [
        { label: "MAGNETIC", value: "MAGNETIC" },
        { label: "HIDE_ME", value: "HIDE_ME" },
        { label: "RANDOM", value: "RANDOM" },
        { label: "SECRET", value: "SECRET" },
        { label: "GUIDED_JOURNEY", value: "GUIDED_JOURNEY" },
        { label: "SQUAD_SPRINT", value: "SQUAD_SPRINT" },
        { label: "TEAM+1", value: "TEAM+1" },
    ]
    const {BoastImageLink, uploadBoastImageloading} = useSelector((state) => ({
        BoastImageLink:
        state?.BoostReducer?.uploadBoastimageAllData?.result?.imgLink,
        uploadBoastImageloading: state?.BoostReducer?.uploadBoastImageloading,
    }));

    const [imageLoading, setImageLoading] = useState(false);
    const [status, setStatus] = useState(true);
    const [transactionLoading, setTransactionLoading] = useState(false);
    const formikRef = useRef();
    const dispatch = useDispatch();
    const [boostName, setBoostName] = useState(null);
    useEffect(() => {
        if (BoastImageLink) {
            formikRef.current.setFieldValue("image", BoastImageLink);
        }
    }, [BoastImageLink]);
    const ImageLoading = () => {
        return (
            <div style={boastStyle}>
                <Spin style={{color: "#78633A"}}></Spin>
            </div>
        );
    };

    const IconButton = (iconName) => {
        return (
            <Button
                icon={
                    iconName === "EditOutlined" ? (
                        <EditOutlined style={{verticalAlign: "middle"}}/>
                    ) : (
                        <PlusOutlined style={{verticalAlign: "middle"}}/>
                    )
                }
                shape="circle"
                style={{
                    backgroundColor:
                        iconName === "PlusOutlined" ? "black" : "transparent",
                    color: iconName === "PlusOutlined" ? "white" : "black",
                    marginTop: iconName === "PlusOutlined" ? "0px" : "11px",
                }}
            ></Button>
        );
    };

    const imageChange = (e) => {
        console.log("eeee: ", e.fileList[0]);
        // let files = e.target.files;
        if (!e?.fileList[0]) {
            return;
        }

        let formData = new FormData();
        if (e.fileList[0]?.size > 3 * 1024 * 1024) {
            CustomToster({
                type: "error",
                message: "Selected image exceeds the maximum allowed file size of 3MB",
            });
            e.fileList = null;
            return;
        }
        const foundItem = allowedExtensions.find(
            (item) => item === getExtension(e.fileList[0]?.name)
        );
        if (!foundItem) {
            CustomToster({
                type: "error",
                message: "please upload only image file",
            });
            return;
        }
        // dispatch({
        //   type: types.UploadCollectionImageRequest,
        // });
        setImageLoading(true);
        formData.append("file", e.fileList[0]?.originFileObj);
        let fileReader = new FileReader();
        fileReader?.readAsDataURL(e?.fileList[0]?.originFileObj);

        fileReader.onload = (event) => {
            dispatch({
                type: types.UploadBoastImageRequest,
                payload: {
                    uploadCollectionImageData: {
                        fileBase64: event.target.result.split(",")[1],
                        fileName: e?.fileList[0]?.name,
                    },
                },
                setImageLoading,
                formData: formData,
            });
            // setFileList(event.target.result);
        };
    };

    // if (transactionLoading) {
    //   return (
    //     <div className="loader">
    //       <Spin tip="Creating Boost..." style={{ color: "#78633A" }}></Spin>
    //     </div>
    //   );
    // }

    return (
        <div>
            <div className="modaltitle">Add Boost</div>
            <Formik
                key="my-form"
                initialValues={{
                    image: "",
                    name: "",
                    price: "",
                    nftId: "",
                    description: "",
                    actionstatus: status,
                }}
                enableReinitialize={false}
                innerRef={formikRef}
                validationSchema={Constants.AddBoostSchema}
                onSubmit={(values) => {
                    values.actionstatus = status;
                    console.log("values here", values);
                    let contractAddress =
                        process.env.REACT_APP_GAME_BOOSTS_CONTRACT_ADDRESS;
                    setTransactionLoading(true);
                    dispatch({
                        type: types.UploadBoastNFTMetaDataRequest,
                        payload: {
                            values: {
                              ...values,
                              name: boostName.value
                            },
                            BoastImageLink,
                            contractAddress,
                            setTransactionLoading,
                            dispatch,
                            handleCloseModal,
                        },
                    });
                }}
            >
                {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      values,
                      resetForm,
                  }) =>
                    transactionLoading ? (
                        <div className="loader" style={{height: "470px"}}>
                            <Spin tip="Creating Boost..." style={{color: "#78633A"}}></Spin>
                        </div>
                    ) : (
                        <div className=" mt40">
                            <div>
                                {imageLoading ? (
                                    ImageLoading()
                                ) : BoastImageLink ? (
                                    <div style={boastImageLink}>
                                        <img
                                            src={BoastImageLink}
                                            alt="avatar"
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                            }}
                                        />
                                        <Upload
                                            style={{marginTop: "21px"}}
                                            accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .tiff, .psd, .svg"
                                            maxCount={1}
                                            onChange={imageChange}
                                            showUploadList={false}
                                            // showUploadList={false}
                                        >
                                            {IconButton("EditOutlined")}
                                        </Upload>
                                    </div>
                                ) : (
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .tiff, .psd, .svg"
                                        maxCount={1}
                                        onChange={imageChange}
                                        showUploadList={false}
                                        // showUploadList={false}
                                    >
                                        {imageLoading ? ImageLoading() : IconButton("PlusOutlined")}
                                    </Upload>
                                )}
                                <ErrorMessage name="image">
                                    {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                </ErrorMessage>
                            </div>
                            <div className="add-announcement-modal-wrapper mt20">
                                <div className=" ">
                                    <div className=" mb-3 ">
                                        <label>Name </label>
                                        <Select
                                            value={
                                            boostName
                                            }
                                            style={{ width: "100%" }}
                                            placeholder="boosts"
                                            onChange={(e) => {
                                                console.log('e', e);
                                                setBoostName(e);
                                            }}
                                            options={boost_enum}
                                        >
                                            {boost_enum?.map((option) => (
                                                <Select.Option
                                                    key={option?.label}
                                                    value={option?.value}
                                                >
                                                    {option?.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        {/*<label className="d-flex">Boost *</label>*/}
                                        {/*<Field name="countryofquest">*/}
                                        {/*    {({meta}) => (*/}
                                        {/*        <>*/}
                                        {/*            <Select*/}
                                        {/*                onChange={(e) => handleChange("name")(e)}*/}
                                        {/*                placeholder="Select any one"*/}
                                        {/*                value={values.name}*/}
                                        {/*                options={boost_enum}*/}
                                        {/*                styles={{*/}
                                        {/*                    control: (base, state) => ({*/}
                                        {/*                        ...base,*/}
                                        {/*                        "&:hover": {borderColor: "#E5E7EB"}, // border style on hover*/}
                                        {/*                        border: "1px solid #E5E7EB", // default border color*/}
                                        {/*                        borderRadius: "0.5rem ",*/}
                                        {/*                        boxShadow: "none", // no box-shadow*/}
                                        {/*                    }),*/}
                                        {/*                }}*/}
                                        {/*            />*/}
                                        {/*            <p className="errorText">{meta.error}</p>*/}
                                        {/*        </>*/}
                                        {/*    )}*/}
                                        {/*</Field>*/}
                                        {/*<Field name="name">*/}
                                        {/*  {({ meta }) => (*/}
                                        {/*    <CustomTextInput*/}
                                        {/*      label={"Name"}*/}
                                        {/*      type="text"*/}
                                        {/*      error={meta.touched ? meta.error : null}*/}
                                        {/*      onChange={(e) => {*/}
                                        {/*        if (e.target.value.length <= 60) {*/}
                                        {/*          handleChange("name")(e);*/}
                                        {/*        }*/}
                                        {/*      }}*/}
                                        {/*      placeHolderText={"Magnet"}*/}
                                        {/*      value={values.name}*/}
                                        {/*      onBlur={handleBlur("name")}*/}
                                        {/*      showEyeIcon={false}*/}
                                        {/*      disable={loading}*/}
                                        {/*    />*/}
                                        {/*  )}*/}
                                        {/*</Field>*/}
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 mb-3 ">
                                        <label className="d-flex">Description</label>
                                        <Field name="description">
                                            {({meta}) => (
                                                <>
                          <textarea
                              disabled={loading}
                              onError={meta.touched ? meta.error : null}
                              class="form-control w-100 textarea h134"
                              rows="7"
                              onChange={(e) => {
                                  if (e.target.value.length <= 180) {
                                      handleChange("description")(e);
                                  }
                              }}
                              id="description"
                              name="description"
                              value={values?.description}
                          ></textarea>
                                                    <ErrorMessage name="description">
                                                        {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                                    </ErrorMessage>
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <div>
                                    <div className="row col-12 ">
                                        <div className="col-md-12">
                                            <Field name="price">
                                                {({meta}) => (
                                                    <CustomTextInput
                                                        label={"Price"}
                                                        type="number"
                                                        error={meta.touched ? meta.error : null}
                                                        onChange={(e) => {
                                                            console.log("e.target.value", e.target.value);
                                                            if (e.target.value.length <= 6) {
                                                                handleChange("price")(e);
                                                            }
                                                        }}
                                                        placeHolderText={"500"}
                                                        value={values.price}
                                                        onBlur={handleBlur("price")}
                                                        showEyeIcon={false}
                                                        disable={loading}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12  d-flex justify-content-between">
                                        <div>
                                            <label className="d-flex">Actions</label>
                                            <div className="action">Publish/UnPublish</div>
                                        </div>

                                        <div>
                                            <Field name="actionstatus">
                                                {({meta}) => (
                                                    <Switch
                                                        disabled={loading}
                                                        defaultChecked={values.actionstatus}
                                                        onChange={(val) => {
                                                            setStatus(val);
                                                            // setFieldValue("actionstatus", val);
                                                            // handleChange("actionstatus")(val);
                                                            // setFieldValue("actionstatus", val)
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex ">
                                    <div>
                                        <button
                                            type="submit"
                                            className="addbtn border p1060 bgprimary"
                                            onClick={() => {
                                                console.log("here");
                                                handleSubmit();
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                    <div className="ml10">
                                        <button
                                            type="submit"
                                            className="cancelbtn border p1060 "
                                            onClick={() => {
                                                handleCloseModal();
                                                resetForm();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};

export default AddBoast;
