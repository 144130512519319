import { Field, Formik } from "formik";
import CustomTextInput from "../../../../SharedComponents/CustomTextInput";
import Select from "react-select";
import countriesCities from "../../../../../Assets/CountriesandCities/countries_cities.json";
import { useEffect, useState } from "react";
import EditQuestCard from "./EditQuestCard";
import { types } from "../../../../store/action_types";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Switch, InputNumber } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { Constants } from "../../../../Constants";
import { fetchCitiesOptions } from "../../../../Utils/citiesListUpdate";
import { gameModeData } from "../../../../Utils/constants";

const EditQuest = ({
    open,
    editRecordData,
    currentPage,
    prizePoolListingAllData,
}) => {
    const { prizePoolListingByGameModeAllData } = useSelector((state) => ({
        prizePoolListingByGameModeAllData:
            state.PrizePoolReducer.prizePoolListingByGameModeAllData?.result
                ?.prizePool,
    }));
    const [questPrizePool, setQuestPrizePool] = useState(
        editRecordData?.reward?.prizePool
    );
    const [questTaskData, setQuestTaskData] = useState(editRecordData?.taskArray);
    // const [questTaskData, setQuestTaskData] = useState([]);
    const dispatch = useDispatch();
    var minimumDate = new Date();

    const [cityList, setCityList] = useState([]);
    const [disableRegion, setDisableRegion] = useState(false);
    const [cityQuery, setCityQuery] = useState("");
    // const [gameMode, setDisableRegion] = useState(false);
    const [countryIndex, setCountryIndex] = useState(null);
    const [cityIndex, setCityIndex] = useState(0);
    const [poolIndex, setPoolIndex] = useState(0);
    const [cityName, setCityName] = useState(editRecordData?.city);
    const [countryName, setCountryName] = useState(editRecordData?.countryName);
    const [gameMode, setGameMode] = useState(editRecordData?.mode);
    const [searchCityData, setSearchCityData] = useState([]);
    const FetchUpdatedTaskArray = (page) => {
        let data = {
            countryCode:
                countryName?.iso2 || countryName?.iso3 || editRecordData?.countryCode,
            city: cityName?.label || editRecordData?.city,
            pageNum: page || 1,
        };
        dispatch({
            type: types.GetAllTaskWithCountryandCityRequest,
            payload: data,
        });
    };
    // const onChangeQuestTaskPagination = (page) => {
    //   // setCurrentPage(page);
    //   FetchUpdatedTaskArray();
    // };
    useEffect(() => {
        if (countryName && cityList) {
            const filteredData = cityList.filter(
                (item) => item.countryName == countryName.label
            );
            setCityList(filteredData);
        }
    }, [countryName]);
    const setResponseData = (data) => {
        setSearchCityData(data);
    };
    useEffect(() => {
        fetchCitiesOptions(
            cityQuery !== "" ? cityQuery : editRecordData?.city,
            countryName.iso2 ? countryName.iso2 : editRecordData?.countryCode,
            setResponseData
        );
        FetchUpdatedTaskArray();
        fetchPrizePoolByGameMode(editRecordData?.mode);
        if (editRecordData?.countryName) {
            const countryIndex = countriesCities.findIndex(
                (obj) => obj.label === editRecordData?.countryName
            );
            if (
                prizePoolListingByGameModeAllData &&
                editRecordData?.reward?.prizePool
            ) {
                let idTofind = editRecordData?.reward?.prizePool?._id;

                const prizePoolIndex = prizePoolListingByGameModeAllData.findIndex(
                    (obj) => obj._id === idTofind
                );
                if (prizePoolIndex >= 0) {
                    setPoolIndex(prizePoolIndex);
                }
            }
            // setCityList(citislists);
            setCountryIndex(countryIndex);
        }
    }, [editRecordData]);
    useEffect(() => {
        let cityNameValue = cityName ?? editRecordData?.city;
        if (cityList) {
            const cityIndex = cityList.findIndex(
                (obj) => obj.label === cityNameValue
            );
            setCityIndex(cityIndex);
        }
    }, [cityList]);

    useEffect(() => {
        if (
            prizePoolListingByGameModeAllData &&
            editRecordData?.reward?.prizePool
        ) {
            let idTofind = editRecordData?.reward?.prizePool?._id;

            const prizePoolIndex = prizePoolListingByGameModeAllData.findIndex(
                (obj) => obj._id === idTofind
            );
            if (prizePoolIndex && poolIndex >= 0) {
                setPoolIndex(prizePoolIndex);
            }
        }
    }, [prizePoolListingByGameModeAllData]);

    const handlePrizePoolChange = (id, list) => {
        const prizePoolIndex = list.findIndex((obj) => obj._id === id);
        setPoolIndex(prizePoolIndex);
    };
    const hanldeCountriesList = (e) => {
        setCountryIndex(null);
        setCityName("");

        setCountryName(e);
        setCityList(e.cities);
    };
    const SearchCity = (city) => {
        setCityQuery(city);
        // console.log("city I got", city);

        // fetchCities(city);
    };

    useEffect(() => {
        const fetchCities = setTimeout(() => {
            if (cityQuery) {
                fetchCitiesOptions(
                    cityQuery,
                    countryName.iso2 ? countryName.iso2 : editRecordData?.countryCode,
                    setResponseData
                );
            }
        }, 500);
        return () => clearTimeout(fetchCities);
    }, [cityQuery]);

    const hanldeCityList = (e) => {
        setCityIndex(null);
        setCityName(e);
        let data = {
            countryCode:
                countryName?.iso2 || countryName?.iso3 || editRecordData?.countryCode,
            city: e?.label,
            pageNum: 1,
        };
        dispatch({
            type: types.GetAllTaskWithCountryandCityRequest,
            payload: data,
        });
    };

    const fetchPrizePoolByGameMode = (e) => {
        setGameMode(e);

        let data = {
            gameMode: e,
            pageNum: 1,
        };

        dispatch({
            type: types.PrizePoolListingByGameModeRequest,
            payload: data,
        });
    };
    useEffect(() => {
        if (searchCityData?.predictions) {
            const newArray = searchCityData?.predictions.map((item) => {
                let cityDetail = item.description.split(", ");
                return {
                    label: item.structured_formatting.main_text,
                    countryName: cityDetail[cityDetail?.length - 1],
                };
            });
            setCityList(newArray);
        }
    }, [searchCityData]);
    const handleGameMode = (e) => {
        fetchPrizePoolByGameMode(e);
    };
    const handleQuestTaskData = (data) => {
        let newArr = data.filter(
            (item) => item !== null && Object.keys(item).length > 0
        );
        setQuestTaskData(newArr);
    };

    console.log('editRecordData...', editRecordData)

    let newdata = [];
    let task;
    let taskTime;
    let prizePool;
    return (
        <>
            <Formik
                initialValues={{
                    nameofquest: editRecordData?.name,
                    chCoin: editRecordData?.reward?.chCoin,
                    actionstatus: editRecordData?.status == "activate" ? true : false,
                    startDate: moment(editRecordData?.startTime),
                    ticketFee: editRecordData?.ticketFee || 0,
                }}
                enableReinitialize
                onSubmit={(values) => {
                    let newStartDate = new Date(values?.startDate);
                    let startdateString = moment(newStartDate).toISOString();

                    questTaskData.forEach((item) => {
                        task = item?.task?._id;
                        taskTime = item?.taskTime;
                        prizePool = item?.prizePool?._id;

                        newdata.push({
                            task,
                            taskTime,
                            prizePool,
                        });
                    });
                    let data = {
                        updateQuestData: {
                            questId: editRecordData?._id,
                            taskArray: newdata,

                            name: values.nameofquest,
                            mode: gameMode,
                            status: values?.actionstatus == true ? "activate" : "de-activate",

                            countryCode:
                                countryName?.iso2 ||
                                countryName?.iso3 ||
                                editRecordData?.countryCode,
                            city: cityName?.label || cityName,
                            startTime: startdateString,
                            reward: {
                                prizePool: questPrizePool?._id || questPrizePool,
                            },
                        },
                        currentPage: currentPage,
                    };
                    if (gameMode === 'regular' || gameMode === 'grand') {
                        data.updateQuestData.reward.chCoin = values.chCoin;
                    } else {
                        data.updateQuestData.reward.prizePool = questPrizePool?._id || questPrizePool;
                    }

                    dispatch({
                        type: types.QuestUpdateRequest,
                        payload: data,
                    });
                }}
                validationSchema={Constants.QuestSchema}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    values,
                }) => (
                    <>
                        <div className="create-quest-wrapper">
                            <div className="form-field-wrapper">
                                <label className="d-flex">Status*</label>

                                <Field name="actionstatus">
                                    {({ meta }) => (
                                        <Switch
                                            defaultChecked={values.actionstatus}
                                            onChange={(val) => setFieldValue("actionstatus", val)}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div>
                                <Field name="nameofquest">
                                    {({ meta }) => (
                                        <CustomTextInput
                                            label={"Name of Quest"}
                                            type="text"
                                            error={meta.touched ? meta.error : null}
                                            onChange={handleChange("nameofquest")}
                                            placeHolderText={"USA 321"}
                                            value={values.nameofquest}
                                            onBlur={handleBlur("nameofquest")}
                                            showEyeIcon={false}
                                            required={true}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div>
                                <label className="d-flex">Country * </label>
                                <Field name="countryofquest">
                                    {({ meta }) => (
                                        <>
                                            <Select
                                                onChange={(e) => hanldeCountriesList(e)}
                                                placeholder="Select any one"
                                                isDisabled={disableRegion}
                                                value={
                                                    countryIndex
                                                        ? countriesCities[countryIndex]
                                                        : countryName
                                                }
                                                options={countriesCities}
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        "&:hover": { borderColor: "#E5E7EB" },
                                                        border: "1px solid #E5E7EB",
                                                        borderRadius: "0.5rem ",
                                                        boxShadow: "none",
                                                    }),
                                                }}
                                            />
                                            <p className="errorText">{meta.error}</p>
                                        </>
                                    )}
                                </Field>
                            </div>

                            <div>
                                <label className="d-flex">City * </label>
                                <Field name="cityofquest">
                                    {({ meta }) => (
                                        <>
                                            <Select
                                                onChange={(e) => hanldeCityList(e)}
                                                placeholder="Search City"
                                                value={
                                                    cityIndex !== null ? cityList[cityIndex] : cityName
                                                }
                                                options={cityList}
                                                onInputChange={(e) => {
                                                    SearchCity(e);
                                                }}
                                                isDisabled={disableRegion}
                                                styles={{
                                                    option: (base, state) => ({
                                                        ...base,
                                                        "&:hover": { backgroundColor: "#B2D4FF" },
                                                        color: "black",
                                                        cursor: "pointer",
                                                        backgroundColor: "white",
                                                    }),
                                                    control: (base, state) => ({
                                                        ...base,
                                                        "&:hover": { borderColor: "#E5E7EB" },
                                                        border: "1px solid #E5E7EB",
                                                        borderRadius: "0.5rem ",
                                                        boxShadow: "none",
                                                    }),
                                                }}
                                            />
                                            <p className="errorText">{meta.error}</p>
                                        </>
                                    )}
                                </Field>
                            </div>
                            <div>
                                <label className="d-flex">Game Mode *</label>
                                <Field name="modeofquest">
                                    {({ meta }) => (
                                        <>
                                            <Select
                                                onChange={(e) => {
                                                    console.log("setting in null");
                                                    setPoolIndex(-1);
                                                    handleGameMode(e?.value);
                                                    setQuestPrizePool("");
                                                    setGameMode(e?.value);
                                                }}
                                                placeholder="Select any one"
                                                value={
                                                    gameModeData[
                                                    gameModeData.findIndex(
                                                        (item) => item.value === gameMode
                                                    )
                                                    ]
                                                }
                                                isDisabled={true}
                                                options={gameModeData}
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        "&:hover": { borderColor: "#E5E7EB" },
                                                        border: "1px solid #E5E7EB",
                                                        borderRadius: "0.5rem ",
                                                        boxShadow: "none",
                                                    }),
                                                }}
                                            />
                                            <p className="errorText">{meta.error}</p>
                                        </>
                                    )}
                                </Field>
                            </div>
                            {
                                (gameMode === 'free-to-play') &&
                                <div>
                                    <label className="d-flex">Prize Pool *</label>
                                    <Field name="questprizepool">
                                        {({ meta }) => (
                                            <>
                                                <Select
                                                    value={
                                                        poolIndex >= 0 && prizePoolListingByGameModeAllData
                                                            ? prizePoolListingByGameModeAllData[poolIndex]
                                                            : ""
                                                    }
                                                    isDisabled={true}
                                                    onChange={(e) => {
                                                        handlePrizePoolChange(
                                                            e?._id,
                                                            prizePoolListingByGameModeAllData
                                                        );
                                                        setQuestPrizePool(e?._id);
                                                    }}
                                                    placeholder="Select any one"
                                                    options={prizePoolListingByGameModeAllData}
                                                    getOptionLabel={(option) => option.title}
                                                    getOptionValue={(option) => option._id}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            "&:hover": { borderColor: "#E5E7EB" },
                                                            border: "1px solid #E5E7EB",
                                                            borderRadius: "0.5rem ",
                                                            boxShadow: "none",
                                                        }),
                                                    }}
                                                />
                                                <p className="errorText">{meta.error}</p>
                                            </>
                                        )}
                                    </Field>
                                </div>
                            }
                            {
                                (gameMode === 'grand' || gameMode === 'regular') &&
                                <Field name="chCoin">
                                    {({ meta }) => (
                                        <CustomTextInput
                                            label={"Ch coin amount"}
                                            type="text"
                                            error={meta.touched ? meta.error : null}
                                            disable={disableRegion}
                                            onChange={(e) => {
                                                console.log("e.target.length", e.target.value);
                                                if (e.target.value.length <= 25)
                                                    handleChange("chCoin")(e);
                                            }}
                                            placeHolderText={"amount"}
                                            value={values.chCoin}
                                            onBlur={handleBlur("chCoin")}
                                            showEyeIcon={false}
                                            required={true}
                                        />
                                    )}
                                </Field>
                            }
                            <div className=" mb-3 ">
                                <label className="d-flex">Quest Start Date</label>
                                <Field name="startDate">
                                    {({ meta }) => (
                                        <>
                                            <DatePicker
                                                // disabled={true}
                                                showTime
                                                showToday={false}
                                                className="w-100"
                                                name="startDate"
                                                onChange={(dateString) =>
                                                    setFieldValue("startDate", dateString)
                                                }
                                                // defaultValue={values.startDate}
                                                value={dayjs(values.startDate)}
                                                // onChange={(date, dateString) => console.log(dateString)}
                                                inputReadOnly={true}
                                                clearIcon={false}
                                                showNow={false}
                                                disabledDate={(current) =>
                                                    !current || current.isBefore(minimumDate)
                                                }
                                            />
                                            <p className="errorText">{meta.error}</p>
                                        </>
                                    )}
                                </Field>
                            </div>
                            <div className=" mb-3 ">
                                <label className="d-flex">Ticket Fee</label>
                                <Field name="ticketFee">
                                    {({ meta }) => (
                                        <>
                                            <InputNumber
                                                min={0}
                                                value={values.ticketFee}
                                                onChange={(value) => setFieldValue("ticketFee", value)}
                                                disabled
                                                style={{ width: '100%' }}
                                            />
                                            <p className="errorText">{meta.error}</p>
                                        </>
                                    )}
                                </Field>
                            </div>
                            <div className="d-flex justify-content-end  align-items-end mb-3 ">
                                <div>
                                    <div className="addbtn" onClick={() => handleSubmit()}>
                                        {" "}
                                        Publish
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row col-12">
                            <div className="col-md-12">
                                <EditQuestCard
                                    open={open}
                                    FetchUpdatedTaskArray={FetchUpdatedTaskArray}
                                    editRecordData={editRecordData}
                                    prizePoolListingAllData={prizePoolListingByGameModeAllData}
                                    handleQuestTaskData={handleQuestTaskData}
                                    setDisableRegion={setDisableRegion}
                                />
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </>
    );
};

export default EditQuest;
