import { Pagination, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../../store/action_types";
import SearchBar from '../../../../SharedComponents/SearchBar'

const NFTCollectionListing = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: types.GetAllNFTCollectionRequest,
      payload: {
        page: 1
      },
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const { allNFTCollectionData, editTaskModalOpen } = useSelector((state) => ({
    allNFTCollectionData: state.CollectionReducer?.allNFTCollectionData,
    // editTaskModalOpen: state.TaskReducer.editTaskModalOpen,
  }));

  
  const { searchTextCollections } = useSelector(
    (state) => ({
      searchTextCollections: state.QuestReducer.searchText
    })
  );

  const columns = [
    {
      title: "NFT Collection ID ",
      dataIndex: "nftCollectionId",
      key: "nftCollectionId",

      render: (item) => (
        <>
          <div>{item}</div>
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "companyName",
      key: "companyName",

      render: (item) => (
        <div>
          {/* <img src={TaskLogo} className="taskavatarimage" />*/} {item}
        </div>
      ),
    },
    {
      title: "Image",
      dataIndex: "imageLink",
      key: "imageLink",

      render: (item) => <img src={item} className="taskavatarimage" />,
      // render: (item) =>   <img src={item} className="taskavatarimage" />,
    },

    // {
    //   title: "Meta Link",
    //   dataIndex: "metadataLink",
    //   key: "metadataLink",

    //   render: (item) => (
    //     // <div  >{item}</div>
    //     <div className="typeofclue"> {item}</div>
    //   ),
    // },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (item) => (
        <div>
          {console.log("statu item", item)}
          <div
            className={`${
              item === "activate" ? "typeofclueactive" : "typeofinactive"
            }`}
          >
            {" "}
            {item === "activate" ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "",

    //   key: "x",
    //   render: (item) => (
    //     <div className="d-flex ">
    //       <div className="cursor-nodrop">
    //         {/* <div className="cursor" onClick={() => handlDeleteRecord(item)} > */}{" "}
    //         <DeleteIcon />
    //       </div>

    //       <div className="ml10 cursor-nodrop">
    //         {/* <div className="ml10 cursor" onClick={() => handleUpdateTask(item)}> */}{" "}
    //         <EditIcon />
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const onChangeCollectionPagination = (page) => {
    setCurrentPage(page);
    dispatch({
      type: types.GetAllNFTCollectionRequest,
      payload: {
        searchText: searchText ? searchText :searchTextCollections,
        page,
      },
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    dispatch({
      type: types.GetAllNFTCollectionRequest,
      payload: {
        searchText: searchText,
        page: currentPage,
      },
    });
  };

  return (
    <>
      <>
        <div>
      <SearchBar onSearch={handleSearch} />
          <Table
            pagination={false}
            columns={columns}
            showSizeChanger={false}
            dataSource={allNFTCollectionData?.result?.nftCollection?.docs}
          />

          <div className="d-flex justify-content-end mt20">
            <Pagination
              current={currentPage}
              pageSize={10}
              showSizeChanger={false}
              total={allNFTCollectionData?.result?.nftCollection?.totalDocs}
              onChange={(page) => onChangeCollectionPagination(page)}
            />
          </div>
        </div>
      </>
    </>
  );
};
export default NFTCollectionListing;
