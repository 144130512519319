const types = {
  // *******************  Login ***************************

  SignInRequest: "SignInRequest",
  SignInRequestSuccess: "SignInRequestSuccess",
  SignInRequestFailure: "SignInRequestFailure",

  // *******************  OTP ***************************

  //  OTP Request
  OtpRequest: "OtpRequest",
  OtpRequestSuccess: "OtpRequestSuccess",
  OtpRequestFailure: "OtpRequestFailure",

  // *******************  Forget Password ***************************

  //  Email For For OTP  Request (CreateNEwpassowrd) for email sending
  EmailForOtpCreateNewPasswordRequest: "EmailForOtpCreateNewPasswordRequest",
  EmailForOtpCreateNewPasswordRequestSuccess:
    "EmailForOtpCreateNewPasswordRequestSuccess",
  EmailForOtpCreateNewPasswordRequestFailure:
    "EmailForOtpCreateNewPasswordRequestFailure",

  // *******************  Create New Passowrd ***************************

  //  Email For For Create New Passowrd  Request (CreateNEwpassowrd screen)
  CreateNewPasswordRequest: "CreateNewPasswordRequest",
  CreateNewPasswordRequestSuccess: "CreateNewPasswordRequestSuccess",
  CreateNewPasswordRequestFailure: "CreateNewPasswordRequestFailure",

  // *******************  Boast ***************************

  //Boost Listing
  BoostListingRequest: "BoostListingRequest",
  BoostListingRequestSuccess: "BoostListingRequestSuccess",
  BoostListingRequestFailure: "BoostListingRequestFailure",

  // Update Boost
  BoostUpdateRequest: "BoostUpdateRequest",
  BoostUpdateRequestSuccess: "BoostUpdateRequestSuccess",
  BoostUpdateRequestFailure: "BoostUpdateRequestFailure",

  // Upload Collection Image
  UploadBoastImageRequest: "UploadBoastImageRequest",
  UploadBoastImageRequestSuccess: "UploadBoastImageRequestSuccess",
  UploadBoastImageRequestFailure: "UploadBoastImageRequestFailure",
  UploadBoastImageReset: "UploadBoastImageReset",

  // Upload Boast NFT MetaData
  UploadBoastNFTMetaDataRequest: "UploadBoastNFTMetaDataRequest",
  UploadNFTMetaDataRequestSuccess: "UploadNFTMetaDataRequestSuccess",
  UploadNFTMetaDataRequestFailure: "UploadNFTMetaDataRequestFailure",

  // *******************  Announcement ***************************

  //Announcement Listing
  AnnouncementListingRequest: "AnnouncementListingRequest",
  AnnouncementListingRequestSuccess: "AnnouncementListingRequestSuccess",
  AnnouncementListingRequestFailure: "AnnouncementListingRequestFailure",

  // create Announcement
  AnnouncementCreationRequest: "AnnouncementCreationRequest",
  AnnouncementCreationRequestSuccess: "AnnouncementCreationRequestSuccess",
  AnnouncementCreationRequestFailure: "AnnouncementCreationRequestFailure",
  // Update Announcement
  AnnouncementUpdateRequest: "AnnouncementUpdateRequest",
  AnnouncementUpdateRequestSuccess: "AnnouncementUpdateRequestSuccess",
  AnnouncementUpdateRequestFailure: "AnnouncementUpdateRequestFailure",

  // Delete Announcement
  AnnouncementDeleteRequest: "AnnouncementDeleteRequest",
  AnnouncementDeleteRequestSuccess: "AnnouncementDeleteRequestSuccess",
  AnnouncementDeleteRequestFailure: "AnnouncementDeleteRequestFailure",

  //  Create Announcement Modal Open and close
  CreateAnnouncementOpenModalRequest: "CreateAnnouncementOpenModalRequest",
  CreateAnnouncementCloseModalRequest: "CreateAnnouncementCloseModalRequest",

  //  Edit Announcement Modal Open and close
  EditAnnouncementOpenModalRequest: "EditAnnouncementOpenModalRequest",
  EditAnnouncementCloseModalRequest: "EditAnnouncementCloseModalRequest",

  // *********************** Task Request *********************************
  // Get All Task
  GetAllTaskRequest: "GetAllTaskRequest",
  GetAllTaskRequestSuccess: "GetAllTaskRequestSuccess",
  GetAllTaskRequestFailure: "GetAllTaskRequestFailure",

  // Get All Task with country and city
  GetAllTaskWithCountryandCityRequest: "GetAllTaskWithCountryandCityRequest",
  GetAllTaskWithCountryandCityRequestSuccess:
    "GetAllTaskWithCountryandCityRequestSuccess",
  GetAllTaskWithCountryandCityRequestFailure:
    "GetAllTaskWithCountryandCityRequestFailure",
  // Create Task
  CreateTaskRequest: "CreateTaskRequest",
  CreateTaskRequestSuccess: "CreateTaskRequestSuccess",
  CreateTaskRequestFailure: "CreateTaskRequestFailure",

  // Delete Task
  TaskDeleteRequest: "TaskDeleteRequest",
  TaskDeleteRequestSuccess: "TaskDeleteRequestSuccess",
  TaskDeleteRequestFailure: "TaskDeleteRequestFailure",

  // Update Task
  TaskUpdateRequest: "TaskUpdateRequest",
  TaskUpdateRequestSuccess: "TaskUpdateRequestSuccess",
  TaskUpdateRequestFailure: "TaskUpdateRequestFailure",

  //  Edit Task Modal Open and close
  EditTaskOpenModalRequest: "EditTaskOpenModalRequest",
  EditTaskCloseModalRequest: "EditTaskCloseModalRequest",

  //  Add Task Modal Open and close
  AddTaskOpenModalRequest: "AddTaskOpenModalRequest",
  AddTaskCloseModalRequest: "AddTaskCloseModalRequest",

  // *******************  Quest  ***************************

  //Quest Listing
  QuestListingRequest: "QuestListingRequest",
  QuestListingRequestSuccess: "QuestListingRequestSuccess",
  QuestListingRequestFailure: "QuestListingRequestFailure",

  //Quest Detail By ID
  QuestDetailByIdRequest: "QuestDetailByIdRequest",
  QuestDetailByIdRequestSuccess: "QuestDetailByIdRequestSuccess",
  QuestDetailByIdRequestFailure: "QuestDetailByIdRequestFailure",

  // create Quest
  QuestCreationRequest: "QuestCreationRequest",
  QuestCreationBlockchain: "QuestCreationBlockchain",
  QuestCreationRequestSuccess: "QuestCreationRequestSuccess",
  QuestCreationRequestFailure: "QuestCreationRequestFailure",

  // Delete Quest
  QuestDeleteRequest: "QuestDeleteRequest",
  QuestDeleteRequestSuccess: "QuestDeleteRequestSuccess",
  QuestDeleteRequestFailure: "QuestDeleteRequestFailure",

  //  Edit Quest Modal Open and close
  EditQuestOpenModalRequest: "EditQuestOpenModalRequest",
  EditQuestCloseModalRequest: "EditQuestCloseModalRequest",

  // Update Quest
  QuestUpdateRequest: "QuestUpdateRequest",
  QuestUpdateRequestSuccess: "QuestUpdateRequestSuccess",
  QuestUpdateRequestFailure: "QuestUpdateRequestFailure",

  // *******************  NFT Collections  ***************************

  // Upload Collection Image
  UploadCollectionImageRequest: "UploadCollectionImageRequest",
  UploadCollectionImageRequestSuccess: "UploadCollectionImageRequestSuccess",
  UploadCollectionImageRequestFailure: "UploadCollectionImageRequestFailure",

  // Upload NFT MetaData
  UploadNFTMetaDataRequest: "UploadNFTMetaDataRequest",
  // UploadNFTMetaDataRequestSuccess:'UploadNFTMetaDataRequestSuccess',
  // UploadNFTMetaDataRequestFailure:'UploadNFTMetaDataRequestFailure',

  // Get All Task
  GetAllNFTCollectionRequest: "GetAllNFTCollectionRequest",
  GetAllNFTCollectionRequestSuccess: "GetAllNFTCollectionRequestSuccess",
  GetAllNFTCollectionRequestFailure: "GetAllNFTCollectionRequestFailure",

  // *******************  Prize Pool  ***************************

  // create Prize Pool
  PrizePoolCreationRequest: "PrizePoolCreationRequest",
  PrizePoolCreationRequestSuccess: "PrizePoolCreationRequestSuccess",
  PrizePoolCreationRequestFailure: "PrizePoolCreationRequestFailure",

  //Prize pool Listing
  PrizePoolListingRequest: "PrizePoolListingRequest",
  PrizePoolListingRequestSuccess: "PrizePoolListingRequestSuccess",
  PrizePoolListingRequestFailure: "PrizePoolListingRequestFailure",

  //Prize pool Listing by Game mode
  PrizePoolListingByGameModeRequest: "PrizePoolListingByGameModeRequest",
  PrizePoolListingByGameModeRequestSuccess:
    "PrizePoolListingByGameModeRequestSuccess",
  PrizePoolListingByGameModeRequestFailure:
    "PrizePoolListingByGameModeRequestFailure",

  // Update PrizePool
  PrizePoolUpdateRequest: "PrizePoolUpdateRequest",
  PrizePoolUpdateRequestSuccess: "PrizePoolUpdateRequestSuccess",
  PrizePoolUpdateRequestFailure: "PrizePoolUpdateRequestFailure",

  //***********************Family****************************
  // Get All family
  GetAllFamilyData: "GetAllFamilyData",
  GetAllFamilyDataRequestSuccess: "GetAllFamilyDataRequestSuccess",
  GetAllFamilyDataRequestFailure: "GetAllFamilyDataRequestFailure",

  //***********************Users****************************
  // Get All Users
  GetAllUserData: "GetAllUserData",
  GetAllUserDataRequestSuccess: "GetAllUserDataRequestSuccess",
  GetAllUserDataRequestFailure: "GetAllUserDataRequestFailure",

  // Get Users Detail
  GetUserDetail: "GetUserDetail",
  GetUserInventory: "GetUserInventory",
  UpdateUserStatus: "UpdateUserStatus",
  DeleteUser: "DeleteUser",
  RestoreUser: "RestoreUser",
  GetUserDetailRequestSuccess: "GetUserDetailRequestSuccess",
  GetUserDetailRequestFailure: "GetUserDetailRequestFailure",

  // *******************  Votes ***************************

  //Votes Listing
  VotesListingRequest: "VotesListingRequest",
  VotesListingRequestSuccess: "VotesListingRequestSuccess",
  VotesListingRequestFailure: "VotesListingRequestFailure",

  // create Votes
  VotesCreationRequest: "VotesCreationRequest",
  VotesCreationRequestSuccess: "VotesCreationRequestSuccess",
  VotesCreationRequestFailure: "VotesCreationRequestFailure",
  // Update Votes
  VotesUpdateRequest: "VotesUpdateRequest",
  VotesUpdateRequestSuccess: "VotesUpdateRequestSuccess",
  VotesUpdateRequestFailure: "VotesUpdateRequestFailure",

  // Delete Vote
  VoteDeleteRequest: "VoteDeleteRequest",
  VoteDeleteRequestSuccess: "VoteDeleteRequestSuccess",
  VoteDeleteRequestFailure: "VoteDeleteRequestFailure",

  // View Vote
  VoteViewRequest: "VoteViewRequest",
  VoteViewRequestSuccess: "VoteViewRequestSuccess",
  VoteViewRequestFailure: "VoteViewRequestFailure",
  VoteViewGraphData: "VoteViewGraphData",

  //  View Votes Modal Open and close
  ViewVotesOpenModalRequest: "ViewVotesOpenModalRequest",
  ViewVotesCloseModalRequest: "ViewVotesCloseModalRequest",

  //  Edit Votes Modal Open and close
  EditVotesOpenModalRequest: "EditVotesOpenModalRequest",
  EditVotesCloseModalRequest: "EditVotesCloseModalRequest",

  //  Create Votes Modal Open and close
  CreateVotesOpenModalRequest: "CreateVotesOpenModalRequest",
  CreateVotesCloseModalRequest: "CreateVotesCloseModalRequest",

  // ******************* Wallets ***************************

  // Get All Wallets
  GetAllWalletsRequest: "GetAllWalletsRequest",
  GetAllWalletsSuccess: "GetAllWalletsSuccess",
  GetAllWalletsFailure: "GetAllWalletsFailure",

  // All Wallets Loading
  SetAllWalletsLoading: "SetAllWalletsLoading",
  ResetAllWalletsLoading: "ResetAllWalletsLoading",

  // Wallet Details
  GetWalletDetailsRequest: "GetWalletDetailsRequest",
  GetWalletDetailsSuccess: "WlletDetailsSuccess",
  GetWalletDetailsFailure: "GetWalletDetailsFailure",

  // Wallet Details Loading
  SetWalletDetailsLoading: "SetWalletDetailsLoading",
  ReSetWalletDetailsLoading: "ReSetWalletDetailsLoading",

  // Wallet Stats Card

  GetStatsRequest: "GetStatsRequest",
  GetStatsSuccess: "GetStatsSuccess",
  GetStatsFailure: "GetStatsFailure",

  // *******************  Companies management  ***************************

  // Get all Companies
  GetCompaniesRequest: "GetCompaniesRequest",
  GetCompaniesRequestSuccess: "GetCompaniesRequestSuccess",
  GetCompaniesRequestFailure: "GetCompaniesRequestFailure",

  // Create Company
  CreateCompanyRequest: "CreateCompanyRequest",
  CreateCompanyRequestSuccess: "CreateCompanyRequestSuccess",
  CreateCompanyRequestFailure: "CreateCompanyRequestFailure",

  // Edit Company
  EditCompanyRequest: "EditCompanyRequest",
  EditCompanyRequestSuccess: "EditCompanyRequestSuccess",
  EditCompanyRequestFailure: "EditCompanyRequestFailure",

  // Delete Company
  DeleteCompanyRequest: "DeleteCompanyRequest",
  DeleteCompanyRequestSuccess: "DeleteCompanyRequestSuccess",
  DeleteCompanyRequestFailure: "DeleteCompanyRequestFailure",

  // Get all pools
  GetPoolsRequest: "GetPoolsRequest",
  GetPoolsRequestSuccess: "GetPoolsRequestSuccess",
  GetPoolsRequestFailure: "GetPoolsRequestFailure",
  ClearPoolsArray: "ClearPoolsArray",

  // Create Pool
  CreatePoolRequest: "CreatePoolRequest",
  CreatePoolRequestSuccess: "CreatePoolRequestSuccess",
  CreatePoolRequestFailure: "CreatePoolRequestFailure",

  // Edit Pool
  EditPoolRequest: "EditPoolRequest",
  EditPoolRequestSuccess: "EditPoolRequestSuccess",
  EditPoolRequestFailure: "EditPoolRequestFailure",

  // Delete Pool
  DeletePoolRequest: "DeletePoolRequest",
  DeletePoolRequestSuccess: "DeletePoolRequestSuccess",
  DeletePoolRequestFailure: "DeletePoolRequestFailure",

  // Get products
  GetProductsRequest: "GetProductsRequest",
  GetProductsRequestSuccess: "GetProductsRequestSuccess",
  GetProductsRequestFailure: "GetProductsRequestFailure",
  ClearProductArray: "ClearProductArray",

  // Edit products
  EditProductsRequest: "EditProductsRequest",
  EditProductsRequestSuccess: "EditProductsRequestSuccess",
  EditProductsRequestFailure: "EditProductsRequestFailure",

  // ******************* Reports ***************************
  GetAllReportsRequest: "GetAllReportsRequest",
  GetAllReportsRequestSuccess: "GetAllReportsRequestSuccess",
  GetAllReportsRequestFailure: "GetAllReportsRequestFailure",

  // ******************* Admins ***************************
  CreateAdminRequest: "CreateAdminRequest",
  CreateAdminRequestSuccess: "CreateAdminRequestSuccess",
  CreateAdminRequestFailure: "CreateAdminRequestFailure",

  // ******************* Search By Category ***************************
  SEARCH_REPORTS_REQUEST: "SEARCH_REPORTS_REQUEST",
  SEARCH_REPORTS_SUCCESS: "SEARCH_REPORTS_SUCCESS",
  SEARCH_REPORTS_FAILURE: "SEARCH_REPORTS_FAILURE",

  // ******************* Block User ***************************
  BlockUserRequest: "BlockUserRequest",
  BlockUserRequestSuccess: "BlockUserRequestSuccess",
  BlockUserRequestFailure: "BlockUserRequestFailure",

  // ******************* Unblock User ***************************
  UnblockUserRequest: "UnblockUserRequest",
  UnblockUserRequestSuccess: "UnblockUserRequestSuccess",
  UnblockUserRequestFailure: "UnblockUserRequestFailure",

  LOADER: "LOADER",
  //Logout
  DESTROY_SESSION: "DESTROY_SESSION",
};
export { types };
