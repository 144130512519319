import { put, takeLatest, call } from "redux-saga/effects";
import { types } from "../action_types";
import { GET, POST } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";

const token = window.sessionStorage.getItem("token");
let responseData = {};

function* GetAllReportsListingSaga({ payload }) {
  try {
    const reportsListingAllData_response = yield GET(
      `admin/report/searchBy?&page=${payload}&limit=10`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    responseData = { ...reportsListingAllData_response?.data };

    if (reportsListingAllData_response?.status === 200) {
      yield put({
        type: types.GetAllReportsRequestSuccess,
        payload: responseData,
      });
    } else {
      yield put({ type: types.GetAllReportsRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.GetAllReportsRequestFailure });
  }
}

function* BlockUserSaga({ payload }) {
  const { userId, currentPage } = payload;

  try {
    const blockUserResponse = yield POST(`admin/user/block/${userId}`, {});

    if (blockUserResponse?.status === 200) {
      yield put({
        type: types.BlockUserRequestSuccess,
        payload: { userId },
      });
      CustomToster({
        type: "success",
        message: "User blocked successfully",
      });
      yield put({
        type: types.GetAllReportsRequest,
        payload: currentPage,
      });
    } else {
      yield put({ type: types.BlockUserRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.BlockUserRequestFailure });
  }
}

function* UnblockUserSaga({ payload }) {
  const { userId, currentPage } = payload;

  try {
    const unblockUserResponse = yield POST(`admin/user/unblock/${userId}`, {});

    if (unblockUserResponse?.status === 200) {
      yield put({
        type: types.UnblockUserRequestSuccess,
        payload: { userId },
      });
      CustomToster({
        type: "success",
        message: "User unblocked successfully",
      });
      yield put({
        type: types.GetAllReportsRequest,
        payload: currentPage,
      });
    } else {
      yield put({ type: types.UnblockUserRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.UnblockUserRequestFailure });
  }
}

function* SearchReportsSaga({ payload }) {
  try {
    let searchReportsResponse = {};
    if (payload.selectedOption === "name") {
      searchReportsResponse = yield GET(
        `admin/report/searchBy?name=${payload.searchText}&page=${payload.currentPage}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (
      payload.selectedOption === "user-profile" ||
      payload.selectedOption === "content"
    ) {
      searchReportsResponse = yield GET(
        `admin/report/searchBy?category=${payload.selectedOption}&page=${payload.currentPage}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    responseData = { ...searchReportsResponse?.data };

    if (searchReportsResponse?.status === 200) {
      yield put({
        type: types.SEARCH_REPORTS_SUCCESS,
        payload: responseData,
      });
      yield put({
        type: types.GetAllReportsRequestSuccess,
        payload: responseData,
      });
    } else {
      yield put({ type: types.SEARCH_REPORTS_FAILURE });
    }
  } catch (e) {
    console.error("SearchReportsSaga error:", e);
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.SEARCH_REPORTS_FAILURE });
  }
}

export default function* ReportsSaga() {
  yield takeLatest(types.GetAllReportsRequest, GetAllReportsListingSaga);
  yield takeLatest(types.BlockUserRequest, BlockUserSaga);
  yield takeLatest(types.UnblockUserRequest, UnblockUserSaga);
  yield takeLatest(types.SEARCH_REPORTS_REQUEST, SearchReportsSaga);
}
