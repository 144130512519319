import { Row, Col, Card, Spin, Switch, Avatar } from "antd";
import UserLogo from "../../../../../../Assets/Users/user.png";
import { LocationIcon } from "../../../../../SharedComponents/icons/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";

const UserDetail = ({ toggleBlockUser, deleteUser, restoreUser, rank }) => {
  const { userDetailData, userDetailDataLoading, userInventory } = useSelector(
    (state) => ({
      userDetailData: state.UserReducer.userDetailData,
      userInventory: state.UserReducer.userInventory,
      userDetailDataLoading: state.UserReducer.userDetailDataLoading,
    })
  );

  const [isBlocked, setIsBlocked] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  useEffect(() => {
    setIsBlocked(userDetailData.result?.userDetails?.isBlocked);
    setIsDeleted(userDetailData.result?.userDetails?.isDeleted);
  }, [userDetailData]);

  if (userDetailDataLoading) {
    return (
      <div className="loader-user">
        <Spin tip="Loading user..." style={{ color: "#78633A" }}></Spin>
      </div>
    );
  }

  const formattedScore =
    userDetailData.result?.userDetails?.userXp?.totalXps.toFixed(2);

  const isLocationDetermined =
    !!userDetailData?.result?.userDetails?.countryName &&
    !!userDetailData?.result?.userDetails?.countryCode &&
    !!userDetailData?.result?.userDetails?.city;
  const isExistCurrentLocation =
    !!userDetailData?.result?.userDetails?.currentLocation?.countryName &&
    !!userDetailData?.result?.userDetails?.currentLocation?.countryCode &&
    !!userDetailData?.result?.userDetails?.currentLocation?.city;

  return (
    <div>
      <Card bordered={false}>
        <div className="p24">
          <div className="d-flex row-gap-1 justify-content-between mb-1">
            <Col gutter={18}>
              <Row span={6} style={{ marginBottom: "8px" }}>
                <div className="rank">
                  {formattedScore > 0
                    ? `Rank#${userDetailData?.result?.rank}`
                    : "Not Ranked"}
                </div>
              </Row>
              <Row span={8} style={{ marginBottom: "8px" }}>
                <div className="task">
                  Level {userDetailData.result?.userDetails?.level}
                </div>
              </Row>
              <Row span={8} style={{ marginBottom: "8px" }}>
                <div className="teamsection mb-1">
                  {userInventory?.boosts?.map((boost) => (
                    <div key={boost?.gameBoost?._id}>
                      {boost?.gameBoost?.name || ""} - {boost.quantity || ""} |
                    </div>
                  ))}
                </div>
              </Row>
              <Row span={8} style={{ marginBottom: "8px" }}>
                <div className="task">
                  Score - <span className="score-value">{formattedScore}</span>
                </div>
              </Row>
            </Col>
            <div
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                padding: "16px",
              }}
            >
              <div className="d-flex flex-column justify-content-end align-items-end">
                <div className="mb-2">
                  <small>
                    Joined:{" "}
                    {new Date(
                      userDetailData.result?.userDetails?.createdAt
                    ).toLocaleDateString()}
                  </small>
                </div>
                <div className={"block-container d-flex"}>
                  <h3 className={"block-user-heading"}>
                    {/* {isBlocked ? "Blocked" : "Verified"} */}
                    {isBlocked ? "Blocked" : ""}
                  </h3>
                  <Switch
                    checked={isBlocked}
                    onChange={(e) => {
                      toggleBlockUser(
                        e,
                        userDetailData.result?.userDetails?._id
                      );
                      setIsBlocked(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-md-8 mt16">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              size={44}
              src={userDetailData?.result?.userDetails?.imageUrl || UserLogo}
              icon={
                !userDetailData?.result?.userDetails?.imageUrl && (
                  <UserOutlined />
                )
              }
              style={{ marginRight: 16 }}
            />
            <div className="col-md-9">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="userTitle">
                    {userDetailData?.result?.userDetails?.fullName ??
                      userDetailData?.result?.userDetails?.nickName}
                  </span>
                </div>
                {/* <div style={{ background: isBlocked ? "#F87171" : "#DEF7EC", color: isBlocked ? "#ffffff" : "#03543F" }} className="userStatus">{isBlocked ? "Blocked" : "Verified"}</div> */}
              </div>
              <div>
                <LocationIcon />{" "}
                <span
                  style={{
                    font: "Montserrat",
                    fontWeight: "400",
                    lineHeight: "14.63px",
                    color: "#78633A",
                  }}
                >
                  {isLocationDetermined
                    ? `${userDetailData?.result?.userDetails?.countryName}, ${userDetailData?.result?.userDetails?.countryCode}, ${userDetailData?.result?.userDetails?.city}`
                    : isExistCurrentLocation
                    ? `${userDetailData?.result?.userDetails?.currentLocation?.countryName}, ${userDetailData?.result?.userDetails?.currentLocation?.countryCode}, ${userDetailData?.result?.userDetails?.currentLocation?.city}`
                    : ""}
                </span>
                <span
                  style={{
                    font: "Montserrat",
                    fontWeight: "400",
                    lineHeight: "14.63px",
                    color: "gray",
                  }}
                >
                  {isExistCurrentLocation && !isLocationDetermined
                    ? " (current location)"
                    : !isExistCurrentLocation && !isLocationDetermined
                    ? "no location data..."
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-md-12 mt20">
          <div className="col-md-6 listheaing">Friends:</div>
          <div className="col-md-6 listvalue">
            {userDetailData?.result?.friends || "no"}
          </div>
          <div className="col-md-6 listheaing">Games joined:</div>
          <div className="col-md-6 listvalue">
            {userDetailData?.result?.gameJoined || "no"}
          </div>
          <div className="col-md-6 listheaing">Family member:</div>
          <div className="col-md-6 listvalue">
            {userDetailData?.result?.familyMember ? "yes" : "no"}
          </div>
          <div className="col-md-6 listheaing">Country:</div>
          <div className="col-md-6 listvalue">
            {userDetailData?.result?.userDetails?.currentLocation
              ?.countryName || "no"}
          </div>
          <div className="col-md-6 listheaing">City:</div>
          <div className="col-md-6 listvalue">
            {userDetailData?.result?.userDetails?.city ||
              userDetailData?.result?.userDetails?.currentLocation?.city ||
              "no"}
          </div>
        </div>
        <div className="row col-md-12 d-flex justify-content-end mt8">
          {isDeleted ? (
            <button
              className="addbtn border bg-primary savecancelbtnlowtwidth cursor-nodrop"
              onClick={() => {
                restoreUser(userDetailData?.result?.userDetails?._id);
                setIsDeleted(false);
              }}
            >
              Restore
            </button>
          ) : (
            <button
              className="addbtn border bg-danger savecancelbtnlowtwidth cursor-nodrop"
              onClick={() => {
                deleteUser(userDetailData?.result?.userDetails?._id);
                setIsDeleted(true);
              }}
            >
              Delete
            </button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default UserDetail;
