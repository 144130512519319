import { Spin } from "antd";
import axios from "axios";
import {
  Map,
  Marker,
  GoogleApiWrapper,
  Circle,
} from "google-maps-react-18-support";
import React, { useState, useEffect } from "react";

const MultiGoogleMapComponent = ({
  google,
  radius,
  currentPool,
  mapCenter,
  products,
  setProducts,
  error,
}) => {
  const [marker] = useState({
    title: "",
    name: "",
    position: mapCenter,
    icon: {
      path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
      fillColor: "red",
      fillOpacity: 1,
      strokeColor: "transparent",
      strokeWeight: 1,
      scale: 0.5,
    },
  });
  const [diameter, setDiameter] = useState(null);
  const [zoom, setZoom] = useState(null);
  const [loading, setLoading] = useState(true);

  const getCoordinateDetails = async (lat, long) => {
    try {
      const {
        data: { results },
      } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyAQPJobaSibKj0ukXQag1aRYn9Kpck9MKw&language=en`
      );

      const { address_components } = results[0];

      let countryFilterResult = address_components.find((x) =>
        x.types.includes("country")
      );

      let cityFilterResult = address_components.find(
        (x) =>
          x.types.includes("locality") ||
          x.types.includes("administrative_area_level_2") ||
          x.types.includes("administrative_area_level_1")
      );

      let data = {
        country: countryFilterResult ? countryFilterResult.long_name : "",
        countryCode: countryFilterResult ? countryFilterResult.short_name : "",
        city: cityFilterResult ? cityFilterResult.long_name : "",
        longitude: long,
        latitude: lat,
      };

      return data;
    } catch (error) {
      console.error("Error fetching geolocation data:", error);
    }
  };

  function getOptimalZoom(diameter) {
    const zoomLevels = [
      { limit: 1500, zoom: 14 },
      { limit: 3000, zoom: 13 },
      { limit: 6000, zoom: 12 },
      { limit: 12000, zoom: 11 },
      { limit: 24000, zoom: 10 },
      { limit: 48000, zoom: 9 },
      { limit: 96000, zoom: 8 },
      { limit: 192000, zoom: 7 },
      { limit: 384000, zoom: 6 },
      { limit: 768000, zoom: 5 },
      { limit: 1536000, zoom: 4 },
      { limit: 3072000, zoom: 3 },
      { limit: 6144000, zoom: 2 },
      { limit: 12228000, zoom: 1 },
    ];

    for (let i = 0; i < zoomLevels.length; i++) {
      if (diameter <= zoomLevels[i].limit) {
        return zoomLevels[i].zoom;
      }
    }

    return 0;
  }

  const handleMarkerDragEnd = async (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    // Calculate the distance between the new position and the circle's center
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(lat, lng),
      new google.maps.LatLng(mapCenter.lat, mapCenter.lng)
    );

    if (distance <= radius * 1000) {
      const { countryCode, city } = await getCoordinateDetails(lat, lng);

      const updatedMarkers = products.map((marker, i) => {
        if (i === index) {
          return {
            ...marker,
            city: city,
            error: false,
            countryCode: countryCode,
            position: { lat, lng },
          };
        }
        return marker;
      });

      setProducts(updatedMarkers);
    } else {
      // If the marker is outside the circle, reset it to the previous position
      const updatedMarkers = products.map((marker, i) => {
        if (i === index) {
          return {
            ...marker,
            error: true,
            position: marker.position, // Reset to the previous position
          };
        }
        return marker;
      });
      setProducts(updatedMarkers);
    }
  };

  useEffect(() => {
    const diamentr = radius * 1000;
    const optimalZoom = getOptimalZoom(diamentr);
    setDiameter(diamentr);
    setZoom(optimalZoom);
  }, [radius, currentPool]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            width: 830,
            height: 500,
            background: "lightgray",
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <Map
        google={google}
        style={{ width: 830, height: 500 }}
        zoom={zoom}
        initialCenter={mapCenter}
      >
        <Marker
          key={"center-marker"}
          title={marker?.title}
          name={marker?.name}
          position={marker?.position}
          icon={marker?.icon}
        />

        {products &&
          products.map((marker, index) => (
            <Marker
              key={index + marker.position.lat + marker.position.lng}
              title={marker.title}
              name={marker.name}
              position={marker.position}
              draggable={true}
              onDragend={(t, map, coord) => handleMarkerDragEnd(coord, index)}
            />
          ))}

        <Circle
          center={mapCenter}
          radius={diameter}
          strokeColor={error ? "red" : "#00752b"}
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor={error ? "red" : "#00752b"}
          fillOpacity={0.35}
        />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAQPJobaSibKj0ukXQag1aRYn9Kpck9MKw",
})(MultiGoogleMapComponent);
