import { types } from "../action_types";

const INITIAL_STATE = {
  prizePoolCreationData:[],
  createPrizePoolloading:false,
  prizePoolListingAllData:[],
  prizePoolListingByGameModeAllData:[],
  allPrizesPoolloading:false,
  allPrizesPoolByGameModeloading:false,
  updatePrizePoolloading:false,
  prizePoolUpdateData:[]
 
};

const PrizePoolReducer = (state = INITIAL_STATE, { type, payload }) => {

  switch (type) {

//Prizepool listing
case types.PrizePoolListingRequest:
    return { ...state, allPrizesPoolloading: true };
  case types.PrizePoolListingRequestSuccess:
    return {
      ...state,
      prizePoolListingAllData: payload.responsedata,
      allPrizesPoolloading: false,
    };
  case types.PrizePoolListingRequestFailure:
    return { ...state, allPrizesPoolloading: false };

    //Prizepool By Game Mode listing
case types.PrizePoolListingByGameModeRequest:
  return { ...state, allPrizesPoolByGameModeloading: true };
case types.PrizePoolListingByGameModeRequestSuccess:
  return {
    ...state,
    prizePoolListingByGameModeAllData: payload,
    allPrizesPoolByGameModeloading: false,
  };
case types.PrizePoolListingByGameModeRequestFailure:
  return { ...state, allPrizesPoolByGameModeloading: false };

   // Update Prizepool
   case types.PrizePoolUpdateRequest:
    return { ...state, updatePrizePoolloading: true };
  case types.PrizePoolUpdateRequestSuccess:
    return {
      ...state,
      prizePoolUpdateData: payload,
      updatePrizePoolloading: false,
     
    };
  case types.PrizePoolUpdateRequestFailure:
    return { ...state, updatePrizePoolloading: false };

       // Create Prize Pool
    case types.PrizePoolCreationRequest:
        return { ...state, createPrizePoolloading: true };
      case types.PrizePoolCreationRequestSuccess:
        return {
          ...state,
          prizePoolCreationData: payload,
          createPrizePoolloading: false,
         
        };
      case types.PrizePoolCreationRequestFailure:
        return { ...state, createPrizePoolloading: false };

 

    default:
      return state;
  }
};
export default PrizePoolReducer;
