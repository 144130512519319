import { types } from "../action_types";

const INITIAL_STATE = {
  allFamilyData: [],
  allFamilyDataLoading: false,
};

const FamilyDataReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    //get All NFT Collection
    case types.GetAllFamilyData:
      return { ...state, allFamilyDataLoading: true };
    case types.GetAllFamilyDataRequestSuccess:
      return { ...state, allFamilyData: payload, allFamilyDataLoading: false };
    case types.GetAllFamilyDataRequestFailure:
      return { ...state, allFamilyDataLoading: false };

    // //Upload Collection/NFT Image
    // case types.UploadCollectionImageRequest:
    //   return { ...state, uploadCollectionImageloading: true };
    // case types.UploadCollectionImageRequestSuccess:
    //   return {
    //     ...state,
    //     uploadcollectionimageAllData: payload,
    //     uploadCollectionImageloading: false,
    //   };
    // case types.UploadCollectionImageRequestFailure:
    //   return { ...state, uploadCollectionImageloading: false };

    //    //Upload NFT MetaData
    // case types.UploadNFTMetaDataRequest:
    //   return { ...state, uploadNFTMetaDataloading: true };
    // case types.UploadNFTMetaDataRequestSuccess:
    //   return {
    //     ...state,
    //     uploadNFTMetaDataAllData: payload,
    //     uploadNFTMetaDataloading: false,
    //   };
    // case types.UploadNFTMetaDataRequestFailure:
    //   return { ...state, uploadNFTMetaDataloading: false };

    default:
      return state;
  }
};
export default FamilyDataReducer;
