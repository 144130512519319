import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { DELETE, GET, POST, PUT } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";
const token = window.sessionStorage.getItem("token");

function* GetAllFamilyDataSaga(page) {
  const pageNum = page?.payload?.page || ''
  try {
    const familyAllData_response = yield GET(
      `admin/agreement/families/all?page=${pageNum}&limit=10`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responsedata = { ...familyAllData_response?.data };

    if (familyAllData_response?.status === 200) {
      yield put({
        type: types.GetAllFamilyDataRequestSuccess,
        payload: responsedata,
      });
    } else {
      yield put({ type: types.GetAllFamilyDataRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.GetAllFamilyDataRequestFailure });
  }
}

export default function* FamilySaga() {
  yield takeLatest(types.GetAllFamilyData, GetAllFamilyDataSaga);
}
