import React, { useRef, useEffect } from "react";
import { Spin } from "antd";
import { Field, Formik } from "formik";
import { Constants } from "../../Constants";
import { types } from "../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import CustomTextInput from "../CustomTextInput";

const AddOrEditAdmin = ({ handleCloseModal }) => {
  const { createAdminLoading } = useSelector((state) => state.AdminReducer);
  const loading = createAdminLoading;

  const formikRef = useRef();
  const dispatch = useDispatch();

  const handleResetForm = () => {
    formikRef.current.resetForm({
      values: {
        firstName: "",
        secondName: "",
        password: "",
        email: "",
      },
    });
  };

  return (
    <div>
      <div className="modaltitle">Create Admin</div>
      <Formik
        key="my-form"
        initialValues={{
          firstName: "",
          secondName: "",
          password: "",
          email: "",
        }}
        enableReinitialize={false}
        innerRef={formikRef}
        validationSchema={Constants.AddAdminSchema}
        onSubmit={(values, { resetForm }) => {
          const updatedBody = {
            fullName: `${values.firstName} ${values.secondName}`,
            password: values.password,
            email: values.email,
          };

          dispatch({
            type: types.CreateAdminRequest,
            payload: {
              updatedBody,
              handleCloseModal: () => {
                handleCloseModal();
                resetForm();
              },
            },
          });
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <div className="formContainer">
            <div className="formHeader">
              <div className="formFields">
                <Field name="firstName">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"First Name"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 20)
                          handleChange("firstName")(e);
                      }}
                      placeHolderText={"Enter first name..."}
                      value={values.firstName}
                      onBlur={handleBlur("firstName")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                      AutoComplete={'off'}
                    />
                  )}
                </Field>
                <Field name="secondName">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Second Name"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 20)
                          handleChange("secondName")(e);
                      }}
                      placeHolderText={"Enter second name..."}
                      value={values.secondName}
                      onBlur={handleBlur("secondName")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                      AutoComplete={'off'}
                    />
                  )}
                </Field>
                <Field name="email">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Email"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 40)
                          handleChange("email")(e);
                      }}
                      placeHolderText={"Enter email..."}
                      value={values.email}
                      onBlur={handleBlur("email")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                      AutoComplete={'off'}
                    />
                  )}
                </Field>
                <Field name="password">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Password"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 30)
                          handleChange("password")(e);
                      }}
                      placeHolderText={"Enter password..."}
                      value={values.password}
                      onBlur={handleBlur("password")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                      AutoComplete={'off'}
                    />
                  )}
                </Field>
              </div>
            </div>

            <div className="formFooter">
              <button
                type="submit"
                disabled={loading}
                className="addbtn border p1060"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && <Spin className="spin" />}
                Create
              </button>
              <button
                type="submit"
                disabled={loading}
                className="cancelbtn border p1060"
                onClick={() => {
                  handleResetForm();
                  handleCloseModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddOrEditAdmin;
