import dayjs from "dayjs";

export const genereteUniqueName = (info, city, code) => {
  const currentDate = new Date();

  const formatString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join("");
  };

  const formattedInfo = formatString(info);
  const formattedCity = formatString(city);
  const formattedDate = dayjs(currentDate).format("MM-DD-YY");
  const formattedTime = dayjs(currentDate).format("HH:mm");

  return `${formattedInfo}_${formattedCity}_${code}_${formattedDate}:${formattedTime}`;
};

export const getSimpleInfo = (input) => {
  let substring = input.split("_")[0];
  let result = substring.replace(/([A-Z])/g, " $1").trim();
  return result;
};
