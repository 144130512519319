import {put, takeLatest} from "redux-saga/effects";
import {types} from "../action_types";
import {DELETE, GET, POST, PUT} from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";

const token = window.sessionStorage.getItem("token");

function* GetAllVotesListingSaga(action) {
    const {payload} = action
  const { pageNum, searchText } = payload;

  const actualSearchText = searchText || '';
    try {
        const votesListingAllData_response = yield GET(
            `admin/poll/all?page=${pageNum}&limit=10&name=${actualSearchText}`,
            // `admin/votes/all?page=${pageNum?.payload}&limit=${pageNum?.payload * 10} `,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        let responsedata = {...votesListingAllData_response?.data};

        if (votesListingAllData_response?.status === 200) {
            yield put({
                type: types.VotesListingRequestSuccess,
                payload: responsedata,
            });
        } else {
            yield put({type: types.VotesListingRequestFailure});
        }
    } catch (e) {
        CustomToster({
            type: "error",
            message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
        });

        yield put({type: types.VotesListingRequestFailure});
    }
}

function* CreateVotesSaga({payload}) {
    const {createVotesData} = payload;

    try {
        const votesCreationData_response = yield POST(
            "admin/poll/create",
            createVotesData
        );

        let votesCreationresponsedata = {
            ...votesCreationData_response?.data,
        };

        if (votesCreationData_response?.status === 200) {
            yield put({
                type: types.VotesCreationRequestSuccess,
                payload: votesCreationresponsedata,
            });

            yield put({
                type: types.VotesListingRequest,
            });
            CustomToster({
                type: "success",
                message: votesCreationresponsedata?.result?.message,
            });
            window.location.reload();
        } else {
            yield put({type: types.VotesCreationRequestFailure});
        }
    } catch (e) {
        CustomToster({
            type: "error",
            message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
        });

        yield put({type: types.VotesCreationRequestFailure});
    }
}

function* UpdateVotesSaga({payload}) {
    const {updateVotesData, currentPage} = payload;

    try {
        const votesUpdateData_response = yield PUT(
            "admin/poll/update",
            updateVotesData
        );

        let votesUpdateresponsedata = {
            ...votesUpdateData_response?.data,
        };

        if (votesUpdateData_response?.status === 200) {
            yield put({
                type: types.VotesUpdateRequestSuccess,
                payload: votesUpdateresponsedata,
            });
            yield put({
                type: types.VotesListingRequest,
                payload: currentPage,
            });
            CustomToster({
                type: "success",
                message: "Your Record Updated Successfully",
            });
        } else {
            yield put({type: types.VotesUpdateRequestFailure});
        }
    } catch (e) {
        CustomToster({
            type: "error",
            message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
        });

        yield put({type: types.VotesUpdateRequestFailure});
    }
}

function* DeleteVoteSaga({payload}) {
    const {id, currentPage} = payload;

    try {
        const voteDeleteData_response = yield DELETE(`admin/poll/${id}`);

        let voteDeleteresponsedata = {
            ...voteDeleteData_response?.data,
        };

        if (voteDeleteData_response?.status === 200) {
            yield put({
                type: types.VoteDeleteRequestSuccess,
                payload: voteDeleteresponsedata,
            });
            yield put({
                type: types.VotesListingRequest,
                payload: currentPage,
            });
            CustomToster({
                type: "success",
                message: "Your Record Deleted Successfully",
            });
        } else {
            yield put({type: types.VoteDeleteRequestFailure});
        }
    } catch (e) {
        CustomToster({
            type: "error",
            message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
        });

        yield put({type: types.VoteDeleteRequestFailure});
    }
}

function* GetViewVotesDataSaga(data) {
    const {payload} = data;

    try {
        const votesViewAllData_response = yield GET(
            `admin/vote/view-results/${payload?._id}`,
            // `admin/votes/all?page=${pageNum?.payload}&limit=${pageNum?.payload * 10} `,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        let responsedata = {...votesViewAllData_response?.data};
        const data = responsedata.result.pollResults;
        let dataArr = data.choices.map(each => each.totalVotes);
        // dataArr = [...dataArr, data.peopleDidNotParticipate];

        if (votesViewAllData_response?.status === 200) {
            yield put({
                type: types.VoteViewRequestSuccess,
                payload: responsedata,
            });
            yield put({
                type: types.VoteViewGraphData,
                payload: dataArr,
            });
            yield put({
                type: types.ViewVotesOpenModalRequest,
            });
        } else {
            yield put({type: types.VoteViewRequestFailure});
        }
    } catch (e) {
        CustomToster({
            type: "error",
            message:
                e?.response?.data?.result?.details?.MESSAGE ||
                e?.response?.data?.result?.MESSAGE ||
                e?.message,
        });

        yield put({type: types.VoteViewRequestFailure});
    }
}

export default function* VotesSaga() {
    yield takeLatest(types.VotesListingRequest, GetAllVotesListingSaga);
    yield takeLatest(types.VotesCreationRequest, CreateVotesSaga);
    yield takeLatest(types.VotesUpdateRequest, UpdateVotesSaga);
    yield takeLatest(types.VoteDeleteRequest, DeleteVoteSaga);
    yield takeLatest(types.VoteViewRequest, GetViewVotesDataSaga);
}
