import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const SearchBar = ({ onSearch, width = 896 }) => {
  const [searchText, setSearchText] = useState("");

  const handleInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    onSearch(text);
  };

  return (
    <div className="search-bar" style={styles.searchBar}>
      <Input
        placeholder="Search..."
        value={searchText}
        onChange={handleInputChange}
        style={{ ...styles.input, width }}
        prefix={<SearchOutlined />}
      />
    </div>
  );
};

const styles = {
  searchBar: {
    marginRight: "7px",
    marginLeft: "7px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  input: {
    height: "49px",
    borderRadius: "10px",
    fontSize: "17px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
};

export default SearchBar;
