import React, { memo, useState } from "react";
const CustomTextArea = memo(
  ({
    value,
    label,
    placeHolderText,
    onChange,
    maxLength,
    error,
    required,
    customStyle,
    name,
    row,
  }) => {
    const [show, setShow] = useState(false);
    function handleShow() {
      setShow(!show);
    }
    return (
      <div>
        <div>
          <label className="d-flex">
            {label} {required && "*"}
          </label>

          <textarea
            className={`${
              customStyle === true
                ? "form-control w-100 textarea textarea-option"
                : "form-control w-100 textarea p10"
            }`}
            rows={row}
            onChange={onChange}
            maxLength={maxLength}
            name={name}
            placeholder={placeHolderText ? placeHolderText : "Fill Field"}
            value={value}
          ></textarea>
        </div>

        <div>
          <p className="errorText">{error}</p>
        </div>
      </div>
    );
  }
);

export default CustomTextArea;
