import { all, fork } from "redux-saga/effects";
import AuthSaga from "./AuthSaga";
import GlobalSaga from "./GlobalSaga";
import AnnouncementSaga from "./AnnouncementSaga";
import TaskSaga from "./TaskSaga";
import QuestSaga from "./QuestSaga";
import CollectionSaga from "./CollectionSaga";
import PrizePoolSaga from "./PrizePoolSaga";
import VotesSaga from "./VotesSaga";
import FamilySaga from "./FamilySaga";
import UserSaga from "./UserSaga";
import BoostSaga from "./BoostSaga";
import GameWalletsSaga from "./GameWalletsSaga";
import ReportsSaga from "./ReportsSaga";
import CompaniesManagementSaga from "./CompaniesManagementSaga";
import AdminSaga from "./AdminSaga";

export function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(GlobalSaga),
    fork(AnnouncementSaga),
    fork(TaskSaga),
    fork(QuestSaga),
    fork(CollectionSaga),
    fork(PrizePoolSaga),
    fork(VotesSaga),
    fork(BoostSaga),
    fork(FamilySaga),
    fork(UserSaga),
    fork(GameWalletsSaga),
    fork(ReportsSaga),
    fork(CompaniesManagementSaga),
    fork(AdminSaga),
  ]);
}
