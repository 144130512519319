import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { GET, POST, PUT } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";

const token = window.sessionStorage.getItem("token");

function* GetAllPrizePoolListingSaga(action) {
  console.log("action", action);
  const { payload } = action;
  const { page, searchText } = payload;

  const actualSearchText = searchText || "";

  try {
    const prizePoolListingAllData_response = yield GET(
      // `admin/quest/all?page=${page}&limit=10`,
      `admin/prize-pool/all?page=${page}&limit=10&name=${actualSearchText}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responsedata = { ...prizePoolListingAllData_response?.data };

    console.log(
      "prizePoolListingAllData_response",
      prizePoolListingAllData_response
    );

    if (prizePoolListingAllData_response?.status === 200) {
      yield put({
        type: types.PrizePoolListingRequestSuccess,
        payload: { responsedata, actualSearchText },
      });
    } else {
      yield put({ type: types.PrizePoolListingRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.PrizePoolListingRequestFailure });
  }
}

function* GetPrizePoolByGameModeSaga(data) {
  try {
    const prizePoolListingByGameModeAllData_response = yield GET(
      `admin/prize-pool/all?gameMode=${data?.payload?.gameMode}`,
      // `admin/prize-pool/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let responsedata = { ...prizePoolListingByGameModeAllData_response?.data };

    if (prizePoolListingByGameModeAllData_response?.status === 200) {
      yield put({
        type: types.PrizePoolListingByGameModeRequestSuccess,
        payload: responsedata,
      });
    } else {
      yield put({ type: types.PrizePoolListingByGameModeRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.PrizePoolListingByGameModeRequestFailure });
  }
}

function* UpdatePrizePoolSaga({ payload }) {
  const { currentPage, id, status, prizePoolStatus, setLoading } = payload;

  try {
    const prizePoolUpdateData_response = yield PUT(
      `admin/prize-pool/update/${id}`,
      prizePoolStatus
    );

    let prizePoolUpdateresponsedata = {
      ...prizePoolUpdateData_response?.data,
    };

    if (prizePoolUpdateData_response?.status === 200) {
      yield put({
        type: types.PrizePoolUpdateRequestSuccess,
        payload: prizePoolUpdateresponsedata,
      });
      yield put({
        type: types.PrizePoolListingRequest,
        payload: currentPage,
      });

      CustomToster({
        type: "success",
        message: "Your Record Updated Successfully",
      });
      setLoading(false);
    } else {
      setLoading(false);
      yield put({ type: types.PrizePoolUpdateRequestFailure });
    }
  } catch (e) {
    setLoading(false);
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });

    yield put({ type: types.PrizePoolUpdateRequestFailure });
  }
}

function* CreatePrizePoolSaga({ payload }) {
  try {
    const prizePoolData_response = yield POST(
      "admin/prize-pool/create",
      payload
    );

    let prizePoolresponsedata = {
      ...prizePoolData_response?.data,
    };

    if (prizePoolData_response?.status === 200) {
      yield put({
        type: types.PrizePoolCreationRequestSuccess,
        payload: prizePoolresponsedata,
      });

      yield put({
        type: types.PrizePoolListingRequest,
      });
      CustomToster({
        type: "success",
        message: "Prize Pool Created Successfully",
      });
      window.location.replace("/prize-pool-list");
    } else {
      yield put({ type: types.PrizePoolCreationRequestFailure });
    }
  } catch (e) {
    CustomToster({ type: "error", message: e.details });

    yield put({ type: types.PrizePoolCreationRequestFailure });
  }
}

export default function* PrizePoolSaga() {
  yield takeLatest(types.PrizePoolCreationRequest, CreatePrizePoolSaga);

  yield takeLatest(types.PrizePoolListingRequest, GetAllPrizePoolListingSaga);
  yield takeLatest(
    types.PrizePoolListingByGameModeRequest,
    GetPrizePoolByGameModeSaga
  );

  yield takeLatest(types.PrizePoolUpdateRequest, UpdatePrizePoolSaga);
}
