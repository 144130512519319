import { useState } from "react";
import AnnouncementCard from "../../../../SharedComponents/AnnouncementCard";

const AddAnnouncement = ({
  handleCloseAddAnnouncementModal,
  onChangeAnnouncementPagination,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="modaltitle">Add Announcement</div>
      <div>
        <AnnouncementCard
          handleCloseModal={handleCloseAddAnnouncementModal}
          onChangeAnnouncementPagination={onChangeAnnouncementPagination}
          setLoading={setLoading}
          loading={loading}
        />
      </div>
    </>
  );
};
export default AddAnnouncement;
