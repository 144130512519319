import {ErrorMessage, Field, Formik} from "formik";
import React, {useEffect, useRef, useState} from "react";
import {Constants} from "../../Constants/index";
import {types} from "../../store/action_types";
import CustomTextInput from "../CustomTextInput";
import ClueAndImageSelect from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Switch} from "antd";
import GoogleMapComponent from "../../Screens/LandingScreens/Tasks/Map";
import RepresentationOfTaskData from "../../../Assets/ClueAndImage/clueandimage.json";
import CustomToster from "../CustomToaster";
import GoogleSelect from "../GoogleSelect";

let createtask = false

const TaskForm = ({
                      editTaskData,
                      currentPage,
                  }) => {
    const {editTaskModalOpen} = useSelector((state) => ({
        updateTaskloading: state.TaskReducer?.updateTaskloading,
        editTaskModalOpen: state.TaskReducer.editTaskModalOpen,
    }));
    const [cityAndCountryData, setCityAndCountryData] = useState({
        latitude: null,
        longitude: null,
        country: "",
        countryCode: "",
        city: "",
      });
    const [errorSimilarTitle, setErrorSimilarTitle] = useState(false);
    const [latitude, setLatitude] = useState(cityAndCountryData.latitude || "");
    const [longitude, setLongitude] = useState(
      cityAndCountryData.longitude || ""
    );
    const [clearSignal, setClearSignal] = useState(false);

    const handleClear = () => {
      setClearSignal(true);
    };

    const getCityCountryVal = (data, setFieldValue) => {
        const { city, country, latitude, longitude } = data;
        setFieldValue("selectedCity", city);
        setFieldValue("selectedCountry", country);
        setCityAndCountryData(data);
      };

    const handleChangeLocationInGoogleSelect = (latitude, longitude) => {
        setCityAndCountryData((prevState) => ({
        ...prevState,
        latitude,
        longitude,
    }));
  };

    const handleLatitudeChange = (e) => {
        handleClear();
        const { value } = e.target;
        setLatitude(value);
        setCityAndCountryData((prev) => ({
          ...prev,
          latitude: value,
        }));
      };
    
      const handleLongitudeChange = (e) => {
        handleClear();
        const { value } = e.target;
        setLongitude(value);
        setCityAndCountryData((prev) => ({
          ...prev,
          longitude: value,
        }));
      };

    const dispatch = useDispatch();
    const formikRef = useRef();
    const history = useNavigate();

    useEffect(() => {
        formikRef.current.resetForm();
    }, [editTaskModalOpen]);
    useEffect(() => {
        formikRef.current.resetForm();
    }, []);

    const getTaskIndex = (targetValue, options) => {
        return options.findIndex((obj) => obj.value === targetValue);
    };

    const handleRrepresentationofTaskChange = (e, options) => {
        formikRef.current.setFieldValue(
            "RepresentatationOfTaskIndex",
            getTaskIndex(e.value, options)
        );
    };
    const handleTypeofTaskChange = (e, options) => {
        formikRef.current.setFieldValue(
            "TaskTypeIndex",
            getTaskIndex(e.value, options)
        );
    };


  useEffect(() => {
    formikRef.current.setFieldValue("latitude", cityAndCountryData?.latitude);
    formikRef.current.setFieldValue("longitude", cityAndCountryData?.longitude);
    formikRef.current.setFieldValue("countryCode", cityAndCountryData?.country);
    formikRef.current.setFieldValue("city", cityAndCountryData?.city);
  }, [cityAndCountryData, formikRef.current]);

  useEffect( () => {
    if (editTaskData?.location) {
      setCityAndCountryData(editTaskData?.location)
    }
  }, [])

  useEffect(() => {
    if (errorSimilarTitle) {
      createtask = true;
    }
  }, [errorSimilarTitle]);

    return (
        <Formik
            initialValues={{
                title: editTaskData?.name ?? "",
                status: editTaskData?.status
                    ? editTaskData?.status === "activate"
                        ? true
                        : false
                    : "",
                hint: editTaskData?.hintText ?? "",
                secondHintText: editTaskData?.secondHintText ?? "",
                xp: editTaskData?.xp ?? "",
                RepresentatationOfTaskIndex: editTaskData
                    ? getTaskIndex(
                        editTaskData.representationOfTask,
                        RepresentationOfTaskData
                    )
                    : -1,
                TaskTypeIndex: editTaskData
                    ? getTaskIndex(
                        editTaskData?.type,
                        RepresentationOfTaskData[
                            getTaskIndex(
                                editTaskData.representationOfTask,
                                RepresentationOfTaskData
                            )
                            ]?.typeoftask
                    )
                    : -1,

                countryCode: cityAndCountryData?.country,
                city: cityAndCountryData?.city,
                latitude: cityAndCountryData?.latitude,
                longitude: cityAndCountryData?.longitude,
            }}
            innerRef={formikRef}
            validationSchema={Constants.TaskSchema}
            onSubmit={(values) => {
                let dataObject = {
                    countryCode: cityAndCountryData.countryCode,
                    city: values.city,
                    latitude: values.latitude,
                    longitude: values.longitude,
                    type: RepresentationOfTaskData[values.RepresentatationOfTaskIndex]
                        ?.typeoftask[values.TaskTypeIndex].value,
                    representationOfTask:
                    RepresentationOfTaskData[values.RepresentatationOfTaskIndex].value,
                    xp: values?.xp,
                    name: values?.title,
                    status: values?.status === true ? "activate" : "de-activate",
                    hintText: values?.hint,
                    secondHintText: values?.secondHintText,
                };
                if (editTaskData) {
                    if (values?.title !== editTaskData?.name) {
                        createtask = true;
                    }
                    if (errorSimilarTitle) {
                        CustomToster({
                            type: "error",
                            message: "please change title to submit",
                        });
                    }
                    let data = {
                        createTaskData: dataObject,
                        history: history,
                        setErrorSimilarTitle: setErrorSimilarTitle,
                    };

                    if (!createtask) {
                        data = {
                            updateTaskData: {
                                ...data.createTaskData,
                                taskId: editTaskData?._id,
                            },
                            currentPage: currentPage,
                            setErrorSimilarTitle: setErrorSimilarTitle,
                        };
                        dispatch({
                            type: types.TaskUpdateRequest,
                            payload: data,
                        });
                    } else {
                        dispatch({
                            type: types.CreateTaskRequest,
                            payload: data,
                        });
                    }
                } else {
                    let data = {
                        createTaskData: dataObject,
                        history: history,
                        setErrorSimilarTitle: setErrorSimilarTitle,
                    };
                    dispatch({
                        type: types.CreateTaskRequest,
                        payload: data,
                    });
                }
            }}
        >
            {({handleChange, handleBlur, handleSubmit, setFieldValue, values}) => (
                <div className="city-detail-grid-wrapper">
                    <div>
                        <div className="">
                            <Field name="title">
                                {({meta}) => (
                                    <>
                                        <CustomTextInput
                                            label={"Title"}
                                            type="text"
                                            error={meta.touched ? meta.error : null}
                                            onChange={(e) => {
                                                setErrorSimilarTitle(false);
                                                handleChange("title")(e);
                                            }}
                                            placeHolderText={"Enter Title"}
                                            value={values.title}
                                            onBlur={handleBlur("title")}
                                            showEyeIcon={false}
                                        />
                                        {errorSimilarTitle ? (
                                            <p style={{color: "red", marginTop: "-13px"}}>
                                                please change title
                                            </p>
                                        ) : null}
                                    </>
                                )}
                            </Field>
                        </div>
                        <div className="mt10">
                            <span>Coordinates</span>
                            <div
                                style={{width: "fit-content", height: "220px"}}
                                className="mt10"
                            >
                                <GoogleMapComponent
                                    getCityCountryVal={(data) =>
                                        getCityCountryVal(data, setFieldValue)
                                    }
                                    updateTask={true}
                                    longUpdate={cityAndCountryData.longitude}
                                    latUpdate={cityAndCountryData.latitude}
                                    width={465}
                                    height={210}
                                    handleClear={handleClear}
                                />
                            </div>
                        </div>
                        <GoogleSelect
                            callback={handleChangeLocationInGoogleSelect}
                            clearSignal={clearSignal}
                            resetClearSignal={() => setClearSignal(false)}
                         />
                        <div className="row col-12 mt10">
                            <div className="col-md-6">
                                <div>
                                    <span>Latitude</span>
                                </div>
                                <input
                                    type="number"
                                    className="form-control mt10"
                                    defaultValue={parseFloat(values.latitude)}
                                    value={parseFloat(values.latitude)}
                                    onChange={handleLatitudeChange}
                                />
                                <ErrorMessage name="latitude">
                                    {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                </ErrorMessage>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <span>Longitude</span>
                                </div>
                                <input
                                    type="number"
                                    className="form-control mt10"
                                    defaultValue={values.longitude}
                                    value={values.longitude}
                                    onChange={handleLongitudeChange}
                                />
                                <ErrorMessage name="longitude">
                                    {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                </ErrorMessage>
                            </div>
                        </div>

                        <div className="col col-12 mt10">
                            <div
                                className="row-md-6 d-flex flex-row text-center"
                                style={{
                                    justifyContent: "space-between",
                                    textAlign: "center",
                                }}
                            >
                                <div className="Centered-Con">
                                    <h6>Country*</h6>
                                </div>
                                <div className="Task-Input-Column">
                                    <input
                                        type="text"
                                        className="form-control mt10"
                                        value={values.countryCode}
                                        disabled
                                    />
                                    <ErrorMessage name="countryCode">
                                        {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div
                                className="row-md-6 d-flex flex-row text-center"
                                style={{
                                    justifyContent: "space-between",
                                    textAlign: "center",
                                }}
                            >
                                <div className="Centered-Con">
                                    <h6>City*</h6>
                                </div>
                                <div className="Task-Input-Column">
                                    <input
                                        type="text"
                                        className="form-control mt10"
                                        value={values.city}
                                        disabled
                                    />
                                    <ErrorMessage name="city">
                                        {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                    </ErrorMessage>
                                </div>
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="savebtn mt10 p1060"
                            onClick={() => handleSubmit()}
                        >
                            <>Save</>
                        </button>
                    </div>
                    <div>
                        <div>
                            <Field name="xp">
                                {({meta}) => (
                                    <CustomTextInput
                                        label={"XP"}
                                        type="number"
                                        error={meta.touched ? meta.error : null}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            if (
                                                /^\d{0,9}$/.test(newValue) &&
                                                (newValue === "" || parseInt(newValue) > 0)
                                            ) {
                                                handleChange("xp")(e);
                                                // Call handleChange to update the field value
                                            }
                                        }}
                                        placeHolderText={"100"}
                                        value={values.xp}
                                        onBlur={handleBlur("xp")}
                                        showEyeIcon={false}
                                    />
                                )}
                            </Field>
                        </div>

                        <div className="row col-12 mt10">
                            <div className="col-md-6">
                                <div>
                                    <span>Representation Of Tasks</span>
                                </div>

                                <ClueAndImageSelect
                                    onChange={(e) =>
                                        handleRrepresentationofTaskChange(
                                            e,
                                            RepresentationOfTaskData
                                        )
                                    }
                                    placeholder="Representation Of Tasks"
                                    id="clue"
                                    className="mt10"
                                    options={RepresentationOfTaskData}
                                    value={
                                        values.RepresentatationOfTaskIndex === -1
                                            ? null
                                            : RepresentationOfTaskData[
                                                values.RepresentatationOfTaskIndex
                                                ]
                                    }
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            "&:hover": {borderColor: "#E5E7EB"}, // border style on hover
                                            border: "1px solid #E5E7EB", // default border color
                                            height: "47px",
                                            borderRadius: "0.5rem ",
                                            boxShadow: "none", // no box-shadow
                                        }),
                                    }}
                                />
                                <ErrorMessage name="RepresentatationOfTaskIndex">
                                    {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                </ErrorMessage>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <span>Type of Task</span>
                                </div>

                                <ClueAndImageSelect
                                    onChange={(e) =>
                                        handleTypeofTaskChange(
                                            e,
                                            values.RepresentatationOfTaskIndex === -1
                                                ? []
                                                : RepresentationOfTaskData[
                                                    values.RepresentatationOfTaskIndex
                                                    ]?.typeoftask
                                        )
                                    }
                                    placeholder="Type of Task"
                                    className="mt10"
                                    id="clue"
                                    options={
                                        values.RepresentatationOfTaskIndex === -1
                                            ? []
                                            : RepresentationOfTaskData[
                                                values.RepresentatationOfTaskIndex
                                                ]?.typeoftask
                                    }
                                    // here we have list of selected type of task on the basis of represntation of task
                                    value={
                                        values.TaskTypeIndex === -1 ||
                                        values.RepresentatationOfTaskIndex === -1
                                            ? null
                                            : RepresentationOfTaskData[
                                                values.RepresentatationOfTaskIndex
                                                ]?.typeoftask[values.TaskTypeIndex]
                                    }
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            "&:hover": {borderColor: "#E5E7EB"}, // border style on hover
                                            border: "1px solid #E5E7EB", // default border color
                                            height: "47px",
                                            borderRadius: "0.5rem ",
                                            boxShadow: "none", // no box-shadow
                                        }),
                                    }}
                                />
                                <ErrorMessage name="TaskTypeIndex">
                                    {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                </ErrorMessage>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 d-flex justify-content-between mt25">
                            <div>
                                <label className="d-flex">Actions</label>
                                <div className="action">Active/Deactivate</div>
                            </div>

                            <div>
                                <Field name="status">
                                    {({meta}) => (
                                        <Switch
                                            defaultChecked={
                                                editTaskData
                                                    ? values?.status
                                                    : values?.status === "activate"
                                                        ? true
                                                        : false
                                            }
                                            onChange={(val) => setFieldValue("status", val)}
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>
                        <div className="mt20">
                            <div>
                                <span>Hint Text</span>
                            </div>

                            <textarea
                                class="form-control w-100 textarea mt10"
                                rows="7"
                                onChange={handleChange("hint")}
                                placeholder="Welcome to Digital Creative"
                                id="hint"
                                name="hint"
                                value={values?.hint}
                            ></textarea>
                            <ErrorMessage name="hint">
                                {(msg) => <div style={{color: "red"}}>{msg}</div>}
                            </ErrorMessage>
                        </div>

                        <div className="mt10">
                            <Field name="Second hint text">
                                {({meta}) => (
                                    <>
                                        <CustomTextInput
                                            label={"Second Hint"}
                                            type="text"
                                            error={meta.touched ? meta.error : null}
                                            onChange={(e) => {
                                                handleChange("secondHintText")(e);
                                            }}
                                            placeHolderText={"Enter second hint"}
                                            value={values.secondHintText}
                                            onBlur={handleBlur("secondHintText")}
                                            showEyeIcon={false}
                                        />
                                        {errorSimilarTitle ? (
                                            <p style={{color: "red", marginTop: "-13px"}}>
                                                please change title
                                            </p>
                                        ) : null}
                                    </>
                                )}
                            </Field>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default TaskForm;
