import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { POST } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";
import { useNavigate } from "react-router-dom";

function* OTPSaga({ payload }) {
  const { otpStateData, history } = payload;

  try {
    const otp_response = yield POST("admin/verify-login", otpStateData);
    let responsedata = { ...otp_response?.data };
    if (otp_response?.status === 200) {
      yield put({
        type: types.OtpRequestSuccess,
        payload: responsedata,
      });

      window.sessionStorage.setItem("token", responsedata?.result.authToken);
      window.sessionStorage.setItem("showSidebar", "true");
      // yield put({ type: types.AnnouncementListingRequest });
      // CustomToster({ type: "success", message: responsedata?.result?.message });
      history("/all-quest");
      // history("/dashboard");
      window.location.reload();
    } else {
      yield put({ type: types.OtpRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.OtpRequestFailure });
  }
}

export default function* GlobalSaga() {
  yield takeLatest(types.OtpRequest, OTPSaga);
}
