import { types } from "../action_types";

const INITIAL_STATE = {
  // otpdata:null,
  announcementListingAllData: [],
  allAnnouncementloading: false,
  createAnnouncementloading: false,
  createAnnouncementModalOpen: false,
  deleteAnnouncementModalOpen: false,
  editAnnouncementModalOpen: false,
  announcementCreationData: "",
};

const AnnouncementReducer = (state = INITIAL_STATE, { type, payload }) => {

  switch (type) {
    //Announcement listing
    case types.AnnouncementListingRequest:
      return { ...state, allAnnouncementloading: true };
    case types.AnnouncementListingRequestSuccess:
      return {
        ...state,
        announcementListingAllData: payload,
        allAnnouncementloading: false,
      };
    case types.AnnouncementListingRequestFailure:
      return { ...state, allAnnouncementloading: false };

    // Create Announcement
    case types.AnnouncementCreationRequest:
      return { ...state, createAnnouncementloading: true };
    case types.AnnouncementCreationRequestSuccess:
      return {
        ...state,
        announcementCreationData: payload,
        createAnnouncementloading: false,
        createAnnouncementModalOpen:false
      };
    case types.AnnouncementCreationRequestFailure:
      return { ...state, createAnnouncementloading: false , createAnnouncementModalOpen:true};

      ;
      // Update Announcement
    case types.AnnouncementUpdateRequest:
        return { ...state, updateAnnouncementloading: true };
      case types.AnnouncementUpdateRequestSuccess:
        return {
          ...state,
          announcementUpdateData: payload,
          updateAnnouncementloading: false,
          editAnnouncementModalOpen:false,
        };
      case types.AnnouncementUpdateRequestFailure:
        return { ...state, updateAnnouncementloading: false,editAnnouncementModalOpen:true };

        // Delete Announcement
    case types.AnnouncementDeleteRequest:
        return { ...state, DeleteAnnouncementloading: true };
      case types.AnnouncementDeleteRequestSuccess:
        return {
          ...state,
          announcementDeleteData: payload,
          DeleteAnnouncementloading: false,
        };
      case types.AnnouncementDeleteRequestFailure:
        return { ...state, DeleteAnnouncementloading: false };


         // Create Announcement Open Modal
    case types.CreateAnnouncementOpenModalRequest:
      return { ...state, createAnnouncementModalOpen: true };
   
         // Create Announcement Close Modal
      case types.CreateAnnouncementCloseModalRequest:
        return { ...state, createAnnouncementModalOpen: false };
      
             // Edit Announcement Open Modal
    case types.EditAnnouncementOpenModalRequest:
      return { ...state, editAnnouncementModalOpen: true };
   
         // Edit Announcement Close Modal
      case types.EditAnnouncementCloseModalRequest:
        return { ...state, editAnnouncementModalOpen: false };

    default:
      return state;
  }
};
export default AnnouncementReducer;
