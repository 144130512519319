import CustomTextInput from "../../../SharedComponents/CustomTextInput";
import { Field, Formik } from "formik";
import { types } from "../../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoLogo from "../../../../Assets/Logo/logo.png";
import { useRef } from "react";
import { useEffect } from "react";

const CreateNewPassword = (props) => {
  const passwordRef = useRef(null);
  const confirmpasswordRef = useRef(null);
  const otp = useRef(null);
  const dispatch = useDispatch();
  const { createNewPassordloading, forgetPasswordUserEmail } = useSelector(
    (state) => ({
      createNewPassordloading: state.AuthReducer.createNewPassordloading,
      forgetPasswordUserEmail: state.AuthReducer.forgetPasswordUserEmail,
    })
  );
  const history = useNavigate();
  useEffect(() => {
    otp.current.focus();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center h100vh">
        <div className="text-center">
          <div className=" ">
            <img src={CryptoLogo} />
          </div>
          <div className="pb40 mt16">
            <p className="Bold newpassheading text-center">
              Please enter a new password
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <Formik
              initialValues={{
                otp: "",
                password: "",
                cpassword: "",
              }}
              onSubmit={(values) => {
                // onPressSignin(values)
                let data = {
                  forgetUserPasowrdData: {
                    forgotPasswordNonce: values.otp,
                    password: values.password,
                    email: forgetPasswordUserEmail,
                  },
                  history: history,
                };
                dispatch({
                  type: types.CreateNewPasswordRequest,
                  payload: data,
                });
              }}
              // validationSchema={Constants.LoginSchema}
            >
              {({ handleChange, handleBlur, handleSubmit, values }) => (
                <div>
                  <div className="row d-flex justify-content-center mt20">
                    <div className="col-8 col-sm-8 col-md-8 ">
                      <Field name="otp">
                        {({ field, meta }) => (
                          <CustomTextInput
                            label={"Enter OTP"}
                            Auto={false}
                            AutoComplete={true}
                            type="text"
                            error={meta.touched ? meta.error : null}
                            onChange={handleChange("otp")}
                            placeHolderText={"123456"}
                            value={values.otp}
                            onBlur={handleBlur("otp")}
                            showEyeIcon={false}
                            autoFocus={true}
                            innerRef={(el) => {
                              field.ref = el;
                              otp.current = el;
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                passwordRef.current.focus();
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-center mt10">
                    <div className="col-8 col-sm-8 col-md-8">
                      <Field name="password">
                        {({ field, meta }) => (
                          <CustomTextInput
                            label={"Password"}
                            Auto={false}
                            AutoComplete={true}
                            type="password"
                            error={meta.touched ? meta.error : null}
                            onChange={handleChange("password")}
                            placeHolderText={"********************"}
                            value={values.password}
                            onBlur={handleBlur("password")}
                            showEyeIcon={true}
                            innerRef={(el) => {
                              field.ref = el;
                              passwordRef.current = el;
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                confirmpasswordRef.current.focus();
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center mt10">
                    <div className="col-8 col-sm-8 col-md-8">
                      <Field name="cpassword">
                        {({ field, meta }) => (
                          <CustomTextInput
                            label={"Confirm Password"}
                            Auto={false}
                            AutoComplete={true}
                            type="password"
                            error={meta.touched ? meta.error : null}
                            onChange={handleChange("cpassword")}
                            placeHolderText={"********************"}
                            value={values.cpassword}
                            onBlur={handleBlur("cpassword")}
                            showEyeIcon={true}
                            innerRef={(el) => {
                              field.ref = el;
                              confirmpasswordRef.current = el;
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                console.log("enter pressed");
                                e.preventDefault(); // Prevent form submission
                                // Trigger form submission
                                handleSubmit();
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="col-8 col-sm-8 col-md-8  d-flex justify-content-center">
                      <button
                        type="submit"
                        className="parimarybtn"
                        onClick={() => handleSubmit()}
                      >
                        {createNewPassordloading ? (
                          <div className="whitespinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                          </div>
                        ) : (
                          <>Edit </>
                        )}
                      </button>
                    </div>
                    <div className="mt8">
                      <NavLink className="text-decoration-none  " to="/">
                        <span className=" fs16 ligh-primary">
                          Back to the login page
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewPassword;
