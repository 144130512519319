import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { POST } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";

function* CreateAdminSaga({ payload }) {
  let { updatedBody, handleCloseModal } = payload;

  try {
    const createdAdmin_response = yield POST("admin/signup", updatedBody);

    if (createdAdmin_response?.status === 200) {
      yield put({
        type: types.CreateAdminRequestSuccess,
      });

      CustomToster({
        type: "success",
        message: "Admin Created Successfully",
      });
    } else {
      yield put({ type: types.CreateAdminRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.CreateAdminRequestFailure });
  }
}

export default function* CompaniesManagementSaga() {
  yield takeLatest(types.CreateAdminRequest, CreateAdminSaga);
}
